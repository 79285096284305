/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { showNotification } from '../../App';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { client } from '../..';
import { FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { Field } from 'react-final-form';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { mainTheme } from '../../Theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    label: {
        fontSize: 11,
        color: theme.palette.primary.main
    },
    radioIcon: {
        color: mainTheme.BrandColors.TemplatePrimary
    },
    radioGroup: {
        flexDirection: 'row'
    }
  })
);

interface OutcomeRadioProps {
    name: string;
    label: string;
    hasNameGuid: boolean;
    canCreateMatter: boolean | undefined;
    onRadioButtonChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

interface OutcomeRadioState {
    isLoading: boolean;
    options: IAutoCompleteItem[];
}

export const OutcomeRadio: React.FC<OutcomeRadioProps> = props => {

    const classes = useStyles();

    const [state, setState] = useState<OutcomeRadioState>({
        isLoading: false,
        options: [],
    });

    useEffect(() => {   
        fetchData();
    // tslint:disable-next-line: align
    }, []);

    useEffect(() => {   
        fetchData();
    // tslint:disable-next-line: align
    }, [props.hasNameGuid]);

    const fetchData = () => {

        setState({
            ...state,
            isLoading: true
        });

        retrieveEnquiryOutcomeData(
            false, 
            // tslint:disable-next-line: no-console
            (data) => onRetrieveSource(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onRetrieveSource = (data: EnquiryOutcomeData) => {
        // Filter out
        let outcomeList = data.enquiry.outcomes;

        // We are showing 'Proceeding' in place of New Client & New Matter as well
        outcomeList = outcomeList.filter( (source: Outcome) => source.description !== 'New Client' && source.description !== 'New Matter');
        // if (props.hasNameGuid === true) {
        //     if (props.canCreateMatter) {
        //         outcomeList = outcomeList.filter( (source: Outcome) => source.description !== 'Proceeding');
        //     } else {
        //         outcomeList = outcomeList.filter( (source: Outcome) => source.description !== 'New Client' && source.description !== 'New Matter');
        //     }            
        // } else {
        //     outcomeList = outcomeList.filter( (source: Outcome) => source.description !== 'Proceeding');
        // }
        
        setState({
            ...state,
            options: outcomeList.map( (source: Outcome) => ({value: source.guidId, label: source.description })),
            isLoading: false
        });
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (props.onRadioButtonChange) {
            props.onRadioButtonChange(event, checked);
        }
    };

    // tslint:disable-next-line: no-any
    const RadioComp = (compProps: any) => {
        const { input } = compProps;

        return (
            <Radio
                {...input}
                checkedIcon={<RadioButtonCheckedIcon className={classes.radioIcon} />}
                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleOnChange(event, checked)}
            />
        );
    };

    return (
        <FormControl component="fieldset" className={classes.root}>
            <FormLabel component="legend" className={classes.label}>{props.label}</FormLabel>
            <RadioGroup aria-label="gender" name="gender1" className={classes.radioGroup}>
                {state.options.map((option: IAutoCompleteItem) => (
                    <FormControlLabel 
                        key={option.value}
                        control={
                            <Field 
                                name={props.name} 
                                component={RadioComp} 
                                type="radio" 
                            />
                        }
                        value={option.label}
                        label={option.label} 
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export function retrieveEnquiryOutcomeData(
    refreshData: boolean,
    onSuccess: (data: EnquiryOutcomeData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void  {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: EnquiryOutcomeDataQuery,
            variables: {},
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export interface EnquiryOutcomeData {
    loading?: boolean;
    networkStatus?: number;
    enquiry: Enquiry;
}

export interface Enquiry {
    outcomes: Outcome[];
}

export interface Outcome {
    guidId:      string;
    description: string;
}

const EnquiryOutcomeDataQuery = gql`
    query enquiryOutcomes {
        enquiry{
            outcomes{
                guidId,
                description
            }
        }
    }
`;