import { WatchQueryFetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { DocumentFile } from '../../types/DocumentFile';

export const useCreateEnquiryMutation = (fetchPolicy?: WatchQueryFetchPolicy) => {

    const [create, result] = useMutation<CreateEnquiryMutationData, CreateEnquiryMutationParams>(CreateEnquiryMutation);

    return {...result, create};
};

export interface CreateEnquiryInput {
    guid?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    gender?: string;
    initialEnquiryDate?: Date;
    phone?: string;
    mobile?: string;
    source?: string;
    office?: string;
    sourceNotes?: string;
    organisation?: string;
    positionAtOrganisation?: string;
    natureOfEnquiry?: string;
    areaOfLaw?: string;
    internalNotes?: string;
    files?: DocumentFile[];
    createdDate?: Date;
    dateAssigned?: Date;
    assignedTo?: string;
    followUpDueDate?: Date;
    assignedBy?: string;
    followUpAction?: string;
    followUpActionSystemName?: string;
    followUpDate?: Date;
    followUpNotes?: string;
    outcome?: string;
    outcomeDate?: Date;
    outcomeNotes?: string;
    reason?: string;
    reasonGuid?: string;
    sendNotification?: boolean;
    isKeyOpportunity?: boolean;
    reminderOffset?: string;
    updateFollowUpDueForInternalNote?: boolean;
    referredToFirmName?: string;
    referredToFirmGuid?: string;
    notProceedingNotes?: string;
    referrerGuid?: string;
    nameGuid?: string;
    organisationGuid?: string;
    isUpdateContacts?: boolean;
    isSendEnquiryResponse?: boolean;
    isMarketingConsent?: boolean;
    isTermsAndConditionsApproved?: boolean;
    isPrivacyStatementProvided?: boolean;
    isConflictCheckPerformed?: boolean;
    isIdentityCheckPerformed?: boolean;
    isNoConflict?: boolean;
    estimatedFees?: number;
    isEstimatedFeesIncludesTax?: boolean;
    estimatedDisbursements?: number;
    isEstimatedDisbursementsIncludesTax?: boolean;
    enquiryQuality?: number;
    likelihoodToProceed?: number;
    dOB?: Date;
    clientFirstName?: string;
    clientLastName?: string;
    addressName?: string;
    floor?: string;
    number?: string;
    street?: string;
    suburb?: string;
    state?: string;
    postcode?: string;
    country?: string;
    pOBoxType?: string;
    pOBox?: string;
    postalSuburb?: string;
    postalState?: string;
    postalPostCode?: string;
    postalCountry?: string;
    clientAddressStreet2?: string;
    clientRelationshipToEnquirer?: string;
    enquiryNotes?: string;
    otherPartyFirstName?: string;
    otherPartyLastName?: string;
    otherPartyNotes?: string;
    otherPartyOrganisation?: string;
    otherPartyEmail?: string;
    otherPartyPhone?: string;
    otherPartyMobile?: string;
    otherPartyDOB?: Date;
    otherPartyAddressName?: string;
    otherPartyFloor?: string;
    otherPartyNumber?: string;
    otherPartyStreet?: string;
    otherPartyStreet2?: string;
    otherPartySuburb?: string;
    otherPartyState?: string;
    otherPartyPostcode?: string;
    otherPartyCountry?: string;
    otherPartyPOBoxType?: string;
    otherPartyPOBox?: string;
    otherPartyPostalSuburb?: string;
    otherPartyPostalState?: string;
    otherPartyPostalPostCode?: string;
    otherPartyPostalCountry?: string;
    isImport?: boolean;
    isRiskAssessed?: boolean;
    riskLevel?: string;
    riskNotes?: string;
    otherPartyGuid?: string;
    otherPartyOrganisationGuid?: string;
    secondClientFirstName?: string;
    secondClientLastName?: string;
    secondClientGuid?: string;
    secondClientRelationshipToEnquirer?: string;
    secondClientDOB?: Date;
    secondClientGender?: string;
    secondClientIsMarketingConsent?: boolean;
    secondClientIsTermsAndConditionsApproved?: boolean;
    secondClientIsPrivacyStatementProvided?: boolean;
    secondClientIsConflictCheckPerformed?: boolean;
    secondClientIsIdentityCheckPerformed?: boolean;
    secondClientIsNoConflict?: boolean;
    secondClientAddressName?: string;
    secondClientFloor?: string;
    secondClientNumber?: string;
    secondClientStreet1?: string;
    secondClientStreet2?: string;
    secondClientSuburb?: string;
    secondClientState?: string;
    secondClientPostCode?: string;
    secondClientCountry?: string;
    secondClientPOBoxType?: string;
    secondClientPOBox?: string;
    secondClientPostalSuburb?: string;
    secondClientPostalState?: string;
    secondClientPostalPostCode?: string;
    secondClientPostalCountry?: string;
    isNameFromDataSource?: boolean;
    isOrganisationFromDataSource?: boolean;
    isOtherSideFromDataSource?: boolean;
    isSecondClientFromDataSource?: boolean;
}

interface CreateEnquiryMutationParams {
    input?: CreateEnquiryInput;
}

export interface CreateEnquiryMutationData {
    createEnquiry: CreateEnquiry;
}

export interface CreateEnquiry {
    status: boolean;
    error:  string;
}

const CreateEnquiryMutation = gql`
    mutation CreateEnquiry($input: CreateEnquiryInput!) {
        createEnquiry(input: $input) {
            status
            error
        }
    }
`;