import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Tooltip } from '@material-ui/core';
import { showNotification } from '../../App';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { client } from '../..';
import { FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { Field } from 'react-final-form';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { mainTheme } from '../../Theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    label: {
        fontSize: 11,
        color: theme.palette.primary.main
    },
    radioIcon: {
        color: mainTheme.BrandColors.TemplatePrimary
    },
    radioGroup: {
        flexDirection: 'row'
    }
  })
);

interface ActionTypeRadioProps {
    name: string;
    label: string;
    onRadioButtonChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, value: string, systemAction: string, url: string | null) => void;
    value?: string;
}

interface ActionTypeRadioState {
    isLoading: boolean;
    // tslint:disable-next-line: no-any
    options: any[];
}

export const ActionTypeRadio: React.FC<ActionTypeRadioProps> = props => {

    const classes = useStyles();

    const [state, setState] = useState<ActionTypeRadioState>({
        isLoading: false,
        options: [],
    });

    useEffect(() => {   
        fetchData();
    // tslint:disable-next-line: align
    }, []);

    const fetchData = () => {

        setState({
            ...state,
            isLoading: true
        });

        retrieveEnquirySourceData(
            false, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onRetrieveSource(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onRetrieveSource = (data: LeadActionData) => {
        setState({
            ...state,
            options: data.lookup.actionTypes.map( (source: ActionTypes) => ({
                ...source,
                value: source.guidId, 
                label: source.description,
                secondaryValue: source.systemName,
                tertiaryValue: source.systemAction,
                url: source.url
            })),
            isLoading: false
        });
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        // tslint:disable-next-line: no-any
        var autoCompleteItem = state.options.filter((x: any) => x.label === event.currentTarget.value);

        let systemName = '';
        let systemAction = '';
        let url: string | null = null;

        if (autoCompleteItem && autoCompleteItem.length > 0) {
            systemName = autoCompleteItem[0].secondaryValue ? autoCompleteItem[0].secondaryValue.toString() : '';
            systemAction = autoCompleteItem[0].tertiaryValue ? autoCompleteItem[0].tertiaryValue.toString() : '';
            url = autoCompleteItem[0].url ? autoCompleteItem[0].url.toString() : null;
        }

        if (props.onRadioButtonChange) {
            props.onRadioButtonChange(event, checked, systemName, systemAction, url);
        }
    };

    const LightTooltip = withStyles((theme: Theme) => ({
        arrow: {
            color: theme.palette.common.white,
            '&::before': {
                // backgroundColor: 'blue',
                boxShadow: theme.shadows[1],
            },
            top: '-3px !important',
            height: '1em !important',
            width: '2em !important',
        },
        tooltip: {
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: theme.shadows[1],
          fontSize: 12,
          padding: 8
        },
      }))(Tooltip);

    // tslint:disable-next-line: no-any
    const RadioComp = (option: any) => (compProps: any) => {

        const { input } = compProps;        
        return (
            <LightTooltip 
                title={option.notes ? option.notes : null} 
                arrow={true}
                open={option.notes ? undefined : false}
            >
                <Radio
                    {...input}
                    checked={option.description === props.value}
                    checkedIcon={<RadioButtonCheckedIcon className={classes.radioIcon} />}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => 
                        handleOnChange(event, checked)}
                />
            </LightTooltip>
        );
    };

    return (
        <FormControl component="fieldset" className={classes.root}>
            <FormLabel component="legend" className={classes.label}>{props.label}</FormLabel>
            <RadioGroup aria-label="gender" name="gender1" className={classes.radioGroup}>
                {// tslint:disable-next-line: no-any
                state.options.map((option: IAutoCompleteItem | any) => (
                    <FormControlLabel 
                        key={option.value}
                        control={
                            <Field 
                                name={props.name} 
                                component={RadioComp(option)} 
                                type="radio"
                            />
                        }
                        value={option.label}
                        label={option.label} 
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export function retrieveEnquirySourceData(
    refreshData: boolean,
    onSuccess: (data: LeadActionData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void  {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: LeadActionDataQuery,
            variables: {},
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export interface LeadActionData {
    loading?: boolean;
    networkStatus?: number;
    lookup: Lookup;
}

export interface Lookup {
    actionTypes: [ActionTypes];
}

export interface ActionTypes {
    guidId: string;
    description: string;
    systemAction: string;
    systemName: string;
    sequence: Number;
    notes: string;
    url: string | null;
}

const LeadActionDataQuery = gql`
    query lookupActionTypes{
        lookup {
            actionTypes {
                guidId,
                description,
                systemAction,
                systemName,
                sequence,
                notes
                url
            }
        }
    }
`;
