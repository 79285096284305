import React, { useState, useEffect } from 'react';
import { updatedDiff } from 'deep-object-diff';
import {
    makeStyles,
    Theme,
    createStyles,
    Grid,
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
    TextField,
    Button,
    CircularProgress,
    Drawer,
    Tooltip,
    Tabs,
    Tab,
    // Divider,
    withStyles,
    Checkbox,
    FormControlLabel,
    Chip,
    IconButton,
    Popper,
    PopperProps,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Card,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker/DatePicker';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { mainTheme } from '../../Theme';
import {
    UpdateEnquiryMutation,
    CreateEnquiryMutation,
    EnquiryDetailsQueryParams,
    retrieveEnquiryDetailsData,
    FollowUpEnquiryMutation,
    fetchTenantData,
    TenantData,
    fetchHomeCountry,
    OptionData,
    CompleteCreateClientMutation,
    CreateMeetingBookingTaskMutation,
    AppUser,
    fetchAppUserData,
    AppUserData,
    EmailTemplateCountQueryParams,
    retrieveEmailTemplateCount,
    EmailTemplateData,
    EnquiryDefaultConfig,
    retrieveGeneralInfoData,
    GeneralInfoDataParams,
    GeneralInfoData,
    Financials,
    LinkEnquiryMutation,
    // fetchTenantTimezoneData,
    UpdateEnquiryQualityMutation,
    UpdateEnquiryQualityParams,
    UpdateEnquiryLikelihoodMutation,
    UpdateLikelihoodParams,
    // EnquirySummaryQueryParams,
    // retrieveEnquirySummaryData,
} from '../EnquirySummaryRepository';
import {
    Form,
    Field,
    FormSpy,
    FieldInputProps,
    AnyObject,
} from 'react-final-form';
import { FormApi, SubmissionErrors, ValidationErrors } from 'final-form';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import UserSelector from '../../components/UserSelector';
import { IDownShiftItem } from 'mui-downshift';
import { client } from '../..';
import { showNotification } from '../../App';
import { RvLoader } from '../../components/Loader';
// import { EnquirySummaryContext } from '../EnquirySummaryContext';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { DropzoneArea } from 'material-ui-dropzone';
import { EnquiryDetail, EnquiryData, Actions } from '../models/Enquiry';
import { GenderSelector } from '../selectors/GenderSelector';
import { AreaOfLawSelector, IAreaOfLawAutoCompleteItem } from '../selectors/AreaOfLawSelector';
// import { ActionTypeSelector } from '../selectors/ActionType';
import { ReasonSelector } from '../selectors/ReasonSelector';
// import { OutcomeSelector } from '../selectors/OutcomeSelector';
import { DocumentFile } from '../../types/DocumentFile';
import { SourceSelector } from '../selectors/SourceSelector';
import { KeyboardDateTimePicker } from '@material-ui/pickers/DateTimePicker/DateTimePicker';
import { ActionTypeRadio } from '../radio/ActionTypeRadio';
import { OutcomeRadio } from '../radio/OutcomeRadio';
import { OfficeSelector } from '../../MailingList/selectors/OfficeSelector';
import { HistoryActivity, HistoryActivityWidth } from '../common/HistoryActivity';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import { TextFieldWithMention } from '../../components/TextFieldWithMention';
import { MentionItem } from 'react-mentions';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { CreateClientDialog } from '../dialogs/CreateClientDialog';
import { UserFeature, UserFeatureEnum } from '../../types/UserFeature';
import { MatterTerminology } from '../../MatterTerminology';
import { ClientAndContactSelector } from '../../components/ClientAndContactSelector';
import { OrganisationIAutoCompleteItem, OrganisationSelector } from '../selectors/OrganisationSelector';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { IndividualNameSummary, IndividualSelector } from '../selectors/IndividualSelector';
import { CurrencyInput } from '../../components/CurrencyInput';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { CurrencySymbolData, retrieveCurrencySymbolData } from '../../ClientAndContactDetails/referrals/ReferralsDetailsRepository';
import { EmailTemplateSelector } from '../selectors/EmailTemplateSelector';
import { EmailTemplateTypeEnum } from '../../emailTemplatesConfiguration/EmailTemplateRespository';
import { SendEmailDialog } from '../dialogs/SendEmailDialog';
import { BookMeetingEmailDialog } from '../dialogs/BookMeetingEmailDialog';
import { Alert, Rating } from '@material-ui/lab';
import { ReminderSelector } from '../../components/ReminderSelector';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import { Address, AddressSelector, PlaceType } from '../selectors/AddressSelector';
import LocalPostOfficeIcon from '@material-ui/icons/LocalPostOffice';
import { ReferredToSelector } from '../selectors/ReferredToSelector';
import { formatNumber } from '../../helpers/ChartHelper';
import { CustomTable, CustomTableContentProps, ValueTypeEnum } from '../../components/CustomTable';
import { MarkClientAndMatterCreatedDialog } from '../dialogs/MarkClientAndMatterCreatedDialog';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useHistory } from 'react-router-dom';
import { OfficeLocationSelector } from '../selectors/OfficeLocationSelector';
import { MatterAutoCompleteSelector } from '../../components/MatterAutoCompleteSelector';
// import { EntityTypeEnum, UserStaffAndTeamAutoCompleteSelector } from '../selectors/UserStaffAndTeamAutoCompleteSelector';
import { retrieveTimezoneOptionData, TimezoneOption, TimezoneOptionData, TimezoneSelector } from '../../lookupConfiguration/selectors/TimezoneSelector';
// import { RegionSettingsData } from '../../firmSettingConfiguration/tabs/RegionalTab';
import { POBoxTypeSelector } from '../../lookupConfiguration/selectors/POBoxTypeSelector';
import { LeadDetailsHistoryData, LeadDetailsHistoryQueryParams, retrieveLeadDetailsHistoryData, TaskDetails, TaskSummaryList } from '../../leadsDetail/LeadsDetailRepository';
import { EntityTypeEnum, UserStaffAndTeamAutoCompleteSelector } from '../selectors/UserStaffAndTeamAutoCompleteSelector';
import { useRegionSettings } from '../../hooks/useRegionSettings';
import { addEllipsis, arrayToString, arrayToStringWithSeparator, removeTagsAndStyles, retrieveQueryData, separateName } from '../../helpers/commonHelper';
import { RiskLevelSelector } from '../selectors/RiskLevelSelector';
import { useTranslation } from 'react-i18next';
import { TranslationTextList } from '../../locales/common';
import { TaskSummary } from '../../tasksSummary/TasksSummaryRepository';
import { EventAndCampaignAttendeeSummary } from '../../eventsAndCampaignsAttendee/EventsAndCampaignsAttendeeRepositories';
import { NameSummary, PartyAddressesData, PartyAddressesParams, PartyAddressesQuery } from '../../nameSummary/NameSummaryRepository';
import { EventsAndCampaignIAutoCompleteItem, EventsAndCampaignSelector } from '../../notesSummary/selectors/EventsAndCampaignSelector';
import { EmailAddress, Partydetails, PhoneNumber } from '../../ClientAndContactDetails/ClientAndContactDetailsRepositories';
import { DateType } from '@date-io/type';
import { formatDateString } from '../../helpers/DateHelper';
import { useQuery } from 'react-apollo';
import { TitleAutocompleteSelector } from '../selectors/TitleAutocompleteSelector';
import { debounce } from 'lodash';
import InfoIcon from '@material-ui/icons/Info';
import { 
    CrmDuplicateCheckQuery,
    DuplicateCheck, 
    DuplicateCheckData, 
    DuplicateCheckParams 
} from '../../hooks/useCrmDuplicateCheck';
// import { HistoryFile } from '../common/HistoryFile';
// import RenderCount from '../helpers/RenderCount';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '20px 15px',
            width: '100%',
            minWidth: '700px',
            maxWidth: '700px',
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
            position: 'relative',
            '& #enquiryForm': {
                flex: 1,
                display: 'flex',
                flexFlow: 'column',
            }
        },
        innerGridContainer: {
            marginTop: 0,
            marginBottom: 0,
        },
        hidden: {
            display: 'none',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            // fontWeight: theme.typography.fontWeightRegular,
            color: mainTheme.TemplateColor.Primary,
            display: 'flex',
            alignItems: 'center',
        },
        textField: {
            width: '100%',
        },
        buttonContainer: {
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-between',
            margin: '0 -8px',
            // paddingBottom: '20px'
        },
        button: {
            margin: theme.spacing(1),
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            marginLeft: '8px'
        },
        headerDetails: {
            // paddingBottom: '20px',
            display: 'flex',
            flexFlow: 'column',
            '& .user': {
                fontSize: '24px',
                marginBottom: '5px',
                color: mainTheme.TemplateColor.Primary,
            },
            '& .company': {
                fontSize: '18px',
                fontWeight: 400,
                marginBottom: '5px',
                // color: mainTheme.TemplateColor.Primary,
            },
            '& .phone': {
                color: mainTheme.TemplateColor.Primary,
                marginBottom: '5px',
                '& a': {
                    color: 'inherit'
                }
            },
            '& .email': {
                color: mainTheme.TemplateColor.Primary,
                marginBottom: '5px',
                '& a': {
                    color: 'inherit'
                }
            },
            '& .common': {
                color: '#000',
                marginBottom: '5px',
            }
        },
        headerLeft: {
            alignItems: 'flex-start'
        },
        headerRight: {
            alignItems: 'flex-end'
        },
        customFieldGrid: {
            paddingTop: '0px !important',
        },
        expandablePanelDetails: {
            paddingBottom: '15px',
        },
        expansionPanelSummary: {
            minHeight: 'auto !important',
            '& .MuiExpansionPanelSummary-content': {
                justifyContent: 'space-between',
                margin: '12px 0',
            },
        },
        infoContainer: {
            display: 'flex',
            margin: '0 -10px',
            paddingLeft: '10px',
            justifyContent: 'flex-end',
            '& .col': {
                padding: '0 10px',
                display: 'flex',
                alignItems: 'center',
                '& .label': {
                    marginRight: '10px',
                },
                '& .files': {
                    fontWeight: 600,
                },
                '& .ellipsis': {
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '250px',
                    textAlign: 'right',
                },
                '& .value': {
                    maxWidth: '400px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }
            },
        },
        dropZone: {
            minHeight: 'fit-content !important',
            '& .MuiDropzoneArea-root': {
                minHeight: 'fit-content !important'
            }
        },
        formControl: {
            width: '100%',
        },
        hideDate: {
            display: 'none'
        },
        expansionPanel: {
            marginBottom: '16px',
        },
        expansionPanel2: {
            marginBottom: '16px',
            boxShadow: '0px 3px 3px -2px rgba(81,115,255, 0.6), 0px 3px 4px 0px rgba(81,115,255,0.14), 0px 1px 8px 0px rgba(81,115,255,0.7)'
        },
        loaderWrapper: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        tabs: {
            marginBottom: 10,
        },
        historyActivityContainer: {
            flex: 1,
            position: 'relative',
            flexFlow: 'column',
            minHeight: 250,
        },
        historyActivityWrapper: {
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
        },
        activityTab: {
            flex: 1,
            flexFlow: 'column',
        },
        labelWithIcon: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                marginRight: 5,
            },
            '& a': {
                color: 'inherit'
            }
        },
        internalNotes: {
            marginTop: 10,
            fontStyle: 'italic',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        divider: {
            // marginTop: 10,
            width: '100%',
        },
        dropZoneFont: {
            '& .MuiDropzoneArea-text': {
                fontSize: '1em',
            }
        },
        checkedIcon: {
            color: mainTheme.TemplateColor.Primary,
        },
        createClientButton: {
            margin: '0 10px 10px 10px',
            flex: 1,
        },
        customGroupButton: {
            display: 'flex',
            alignItems: 'center',
            margin: '0 -10px',
        },
        contactsContainer: {
            display: 'flex',
            alignItems: 'center',
            margin: '0 -5px',
            '& > p': {
                padding: '0 5px',
            }
        },
        expandDrawer: {
            position: 'absolute',
            top: '5px',
            right: '10px',
            '& .MuiSvgIcon-root': {
                width: '20px',
                height: '20px',
            }
        },
        dropzoneClass: {
            paddingBottom: '15px',
            minHeight: 0,
        },
        checkoxContainer: {
            display: 'flex',
            alignItems: 'flex-start'
        },
        checkbboxWrapper: {
            flex: 1,
            display: 'flex',
            flexFlow: 'row wrap',
            padding: '0 8px',
            justifyContent: 'flex-start',
        },
        margin: {
            margin: theme.spacing(1),
        },
        referrerFieldWrapepr: {
            display: 'flex',
            alignItems: 'center',
            '& .field': {
                flex: 1,
            },
            '& .switcher': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }
        },
        toggleFieldWrapepr: {
            display: 'flex',
            alignItems: 'flex-start',
            flex: 1,
            '& .field': {
                flex: 1,
            },
            '& .switcher': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }
        },
        formControlLabel: {
            marginLeft: 0,
            marginRight: 0,
        },
        startTimeWrapper: {
            display: 'flex',
            flex: 1,
            alignItems: 'center',
        },
        textFieldWrapper: {
            flex: 1,
        },
        staffWrapper: {
            fontSize: 14,
            color: mainTheme.TemplateColor.Primary,
            padding: '0 5px',
        },
        noEmailMeetingWrapper: {
            fontSize: 14,
            backgroundColor: 'rgba(243, 243, 158, 0.29)',
            padding: '0 5px',
        },
        mainControlWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        selector: {
            height: '100%',
            display: 'flex',
            alignItems: 'flex-end'
        },
        fieldAndCheckbox: {
            display: 'flex',
            alignItems: 'center',
            '& .MuiFormControl-root': {
                flex: 1
            }
        },
        ratingWrapper: {
            display: 'flex',
            alignItems: 'center',
            // justifyContent: 'space-between',
            margin: '0 -10px',
            '& > legend': {
                padding: '0 10px',
            },
            '& > span': {
                padding: '0 10px',
            }
        },
        AccordionSummary: {
            '& .MuiAccordionSummary-content': {
                justifyContent: 'space-between',
            },
        },
        headingIcon: {
            marginRight: '10px',
        },
        displayFlex: {
            display: 'flex'
        },
        card: {
            display: 'flex',
            flexFlow: 'column wrap',
            minHeight: '85px',
            background: '#fff',
            width: '100%',
            boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.3)',
            borderRadius: '5px',
            // marginBottom: '15px',
            padding: '15px 10px',
            fontFamily: 'Nunito',
            alignItems: 'center',
            [`& .${`label`}`]: {
                fontWeight: '400',
                marginBottom: '15px',
                color: mainTheme.BrandColors.TemplatePrimary,
            },

            [`& .${`value`}`]: {
                textAlign: 'center',
                fontWeight: '400',
                fontSize: '2rem',
            },
            [`& .${`value-string`}`]: {
                textAlign: 'center',
                fontWeight: '400',
                fontSize: '1.5rem',
            },
        },
        cardWrapper: {
            maxWidth: '70%',
            margin: '0 auto',
        },
        matterSelectorWrapper: {
            display: 'flex',
            alignItems: 'center',
            '& > div': {
                flex: 1
            }
        },
        flex2: {
            flex: 2
        },
        notes: {
            backgroundColor: 'rgba(243, 243, 158, 0.29)',
            padding: '10px',
        },
        readRatingWrapper: {
            display: 'flex',
            alignItems: 'center',
            margin: '0 -10px',
            '& > div': {
                padding: '0 10px'
            }
        },
        truncateText: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '30rem'
        },
        associatedWarning: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
        },
        associatedColor: {
            color: 'orange'
        },
        showContactWrapper: {
            padding: '10px',
            display: 'flex',
            flexFlow: 'column',
            gap: '5px',
            // whiteSpace: 'nowrap',
        }
    })
);

const LightTooltip = withStyles((theme: Theme) => ({
    arrow: {
        color: theme.palette.common.white,
        '&::before': {
            // backgroundColor: 'blue',
            boxShadow: theme.shadows[1],
        }
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

interface BookingMeetingProps {
    staff: IAutoCompleteItem | null;
    startTime: Date | null;
    endTime: Date | null;
    isAllDay: boolean;
    officeLocation: IAutoCompleteItem | null;
    feeApplicable: number | null;
    isSendConfirmation: boolean;
    meetingNotes: string | null;
    timezone: IAutoCompleteItem | null;
    isBookMeetingEmailDialogOpen: boolean;
}

interface BotIntegrationProps {
    followUpBotUrl: string | null;
}
export interface Enquiry extends BookingMeetingProps, BotIntegrationProps {
    duplicateCheck: DuplicateCheck | null;
    handleCreateAndClose: boolean | undefined;
    handleCreateAndNew: boolean | undefined;
    handleUpdate: boolean | undefined;
    // showDiscard: boolean;
    // isFormChanged: boolean;
    isAssignmentExpand: boolean;

    guid?: string;
    firstName: string;
    lastName: string;
    individualSelector: IndividualNameSummary | null;
    email: string;
    gender: string | null;
    initialEnquiryDate: Date | null;
    phone: string;
    mobile: string;
    source: IAutoCompleteItem | null;
    office: IAutoCompleteItem | null;
    sourceNotes: string;

    organisationSelector: OrganisationIAutoCompleteItem | null;
    organisation: string;
    positionAtOrganisation: string;

    natureOfEnquiry: string;
    practiceAreaGuidID: string | null;
    areaOfLaw: string | null;

    internalNotes: string | null;
    files: DocumentFile[];

    assignedTo: string | null;
    assignedToName: string | null;
    assignedBy: string | null;
    assignedByName: string | null;
    dateAssigned: Date | null;
    followUpDueDate: Date | null;
    assignmentFollowUpDueReminder: IAutoCompleteItem | null;

    followUpAction: string | null;
    followUpActionSystemName: string | null;
    followUpSystemAction: string | null;
    followUpDate: Date | null;
    
    outcome: string | null;
    outcomeDate: Date | null;
    outcomeNotes: string | null;
    reason: string | null;
    reasonGuidID: string | null;
    followUpNotes: string | null;
    hasFollowUpNotesMentions: boolean;
    isClosed: boolean;
    isKeyOpportunity: boolean;
    isNotProceeding: boolean;
    notProceedingReason: string | null;
    notProceedingReasonGuid: string | null;
    followUpDueReminder: IAutoCompleteItem | null;

    referrerGuid: string | null;
    referrerName: string | null;
    isShowReferrer: boolean;

    isNameSwitched: boolean;
    isOrganisationSwitched: boolean;
    nameGuid: string | null;
    organisationGuid: string | null;

    emailTemplate: IAutoCompleteItem | null;

    isContactDetailsChanged: boolean;
    isUpdateContacts: boolean;
    isSentAcknowledgementEmail: boolean;

    /*
    When to update Follow Up Due Date when doing a Internal Notes action
    1. @Mention and no follow up due date change - Do not change previous follow up due date
    2. @Mention and follow up due date change - Update follow up due date
    3. No @Mention and no follow up due date change - Change follow up due date - Because, the user clicked on the Internal Notes option
    4. No @Mention and follow up due date change - Change follow up due date
    */
    updateFollowUpDueForInternalNote: boolean;
    outcomeDateChanged: boolean;

    // Other details fields
    estimatedFees: number | null;
    isEstimatedFeesIncludesTax: boolean;
    estimatedDisbursements: number | null;
    isEstimatedDisbursementsIncludesTax: boolean;
    enquiryQuality: number | null;
    likelihoodToProceed: number | null;
    clientFirstName: string | null;
    clientLastName: string | null;
    dOB: Date | DateType | null;
    isMarketingConsent: boolean;
    isConflictCheckPerformed: boolean;
    isTermsAndConditionsApproved: boolean;
    isIdentityCheckPerformed: boolean;
    isPrivacyStatementProvided: boolean;
    addressName: string | null;
    floor: string | null;
    number: string | null;
    street: string | null;
    street2: string | null;
    suburb: string | null;
    state: string | null;
    postcode: string | null;
    country: string | null;
    pOBox: string | null;
    postalSuburb: string | null;
    postalState: string | null;
    postalPostCode: string | null;
    postalCountry: string | null;
    isNoConflict: boolean;

    isShowNotProceedingReferrer: boolean;
    isNotProceedingReferrerSwitched: boolean;
    referredToFirmSelector: IAutoCompleteItem | null;
    referredToFirmGuid: string | null;
    referredToFirmName: string | null;
    notProceedingOutcomeNotes: string | null;

    isShowNotProceedingReferrerInProceeding: boolean;
    isNotProceedingReferrerSwitchedInProceeding: boolean;
    referredToFirmSelectorInProceeding: IAutoCompleteItem | null;
    referredToFirmGuidInProceeding: string | null;
    referredToFirmNameInProceeding: string | null;
    notProceedingOutcomeNotesInProceeding: string | null;

    clientRelationshipToEnquirer: string | null;

    matterId: number | null;
    matterGuid: string | null; // Saved Matter Guid
    matterTitle: string | null;
    matterFileNumber: string | null;

    // tslint:disable-next-line: no-any
    matterSelector: IAutoCompleteItem | null | any; // Selector in Matter Tab

    pOBoxType: string | null;
    enquiryNotes: string | null;

    otherPartyFirstName: string | null;
    otherPartyLastName: string | null;
    otherPartyNotes: string | null;
    otherPartyEmail: string | null;
    otherPartyPhone: string | null;
    otherPartyMobile: string | null;
    otherPartyOrganisation: string | null;
    otherPartyDOB: Date | null;
    otherPartyAddressName: string | null;
    otherPartyFloor: string | null;
    otherPartyNumber: string | null;
    otherPartyStreet: string | null;
    otherPartyStreet2: string | null;
    otherPartySuburb: string | null;
    otherPartyState: string | null;
    otherPartyPostcode: string | null;
    otherPartyCountry: string | null;

    otherPartyPOBoxType: string | null;
    otherPartyPOBox: string | null;
    otherPartyPostalSuburb: string | null;
    otherPartyPostalState: string | null;
    otherPartyPostalPostCode: string | null;
    otherPartyPostalCountry: string | null;
    isOtherPartyNameSwitched: boolean;
    otherPartyNameGuid: string | null;
    otherPartyOrganisationGuid: string | null;
    otherPartyIndividualSelector: IndividualNameSummary | null;

    isSavedDirectly: boolean;

    isRiskAssessed: boolean | null;
    riskLevelSelector: IAutoCompleteItem | null; 
    riskNotes: string | null;

    secondClientFirstName:                    null | string;
    secondClientLastName:                     null | string;
    secondClientRelationshipToEnquirer:       null | string;
    secondClientGender:                       null | string;
    secondClientSuburb:                       null | string;
    secondClientState:                        null | string;
    secondClientPostCode:                     null | string;
    secondClientIsMarketingConsent:           boolean;
    secondClientIsTermsAndConditionsApproved: boolean;
    secondClientIsPrivacyStatementProvided:   boolean;
    secondClientIsConflictCheckPerformed:     boolean;
    secondClientIsIdentityCheckPerformed:     boolean;
    secondClientIsNoConflict:                 boolean;
    secondClientCountry:                       null | string;
    secondClientAddressName:                   null | string;
    secondClientFloor:                         null | string;
    secondClientNumber:                        null | string;
    secondClientStreet1:                       null | string;
    secondClientStreet2:                       null | string;
    secondClientDOB: Date | null;
    secondClientPOBoxType:                     null | string;
    secondClientPOBox:                         null | string;
    secondClientPostalSuburb:                  null | string;
    secondClientPostalState:                   null | string;
    secondClientPostalPostCode:                null | string;
    secondClientPostalCountry:                 null | string;

    responseText:                              null | string;

    // events and campaigns
    eventAndCampaignSelector: EventsAndCampaignIAutoCompleteItem | null;
    eventsAndCampaignNotes: string | null;

    // new fields
    title: string | null;
    otherPartyTitle: string | null;

    clientTitle: string | null;
    clientGuid: string | null;
    clientEmail: string | null;
    clientPhone: string | null;
    clientMobile: string | null;

    secondClientTitle: string | null;
    secondClientGuid: string | null;
    secondClientEmail: string | null;
    secondClientPhone: string | null;
    secondClientMobile: string | null;

    isClientNameSwitched: boolean;
    isSecondClientNameSwitched: boolean;

    clientIndividualSelector: IndividualNameSummary | null;
    secondClientIndividualSelector: IndividualNameSummary | null;
}

interface EnquiryFormProps {
    guid?: string;
    formAction: string;
    assignedBy: string | null;
    assignedByName: string | null;
    onDialogClose?: () => void;
    isEnquiryFormOpen: boolean;
    isRefreshData?: boolean;
    isKeyOpportunity: boolean;
    enquiryDefaultConfig?: EnquiryDefaultConfig;
    homeCountry: string;
    pOBoxType: string | null;
    taskSummary?: TaskSummary;
    eventAndCampaignAttendeeSummary?: EventAndCampaignAttendeeSummary;
    nameSummary?: NameSummary;
    partyDetails?: Partydetails;
}

export enum EnquiryFormTab {
    details = 0,
    activity,
    matterDetails
}

interface EnquiryFormState {
    isFormChanged: boolean;
    showDiscard: boolean;
    showTabsSaveAlert: boolean;
    tab: EnquiryFormTab;
    isEmailNotification: boolean;
    isCreateClientOpen: boolean;
    isCreateClient: boolean;
    isMarkClientMatterCreatedOpen: boolean;
    sourceSystem: string;
    homeCountry: string;
    isExpandDrawer: boolean;
    currency: string;
    // tenantTimezone: IAutoCompleteItem | null;
    isSendEmailDialogOpen: boolean;
    hideSendAcknowledgement: boolean;
    generalInfoData?: CustomTableContentProps[];
    matterClientName?: string;
    financials: Financials | null;

    isFormSubmit: boolean;
    isContactDetailsPanelExpand: boolean;
    isEnquiryDetailsPanelExpand: boolean;
    isAssignPanelExpand: boolean;
    isOtherContactDetailsPanelExpand: boolean;
    isOtherDetailsPanelExpand: boolean;
    isOther2DetailsPanelExpand: boolean;
    isPostalAddressPanelExpand: boolean;
    isClient2PostalAddressPanelExpand: boolean;
    isOtherSidePanelExpand: boolean;
    isOtherSidePostalAddressPanelExpand: boolean;
    isDetailedEnquiryNotesPanelExpand: boolean;

    enquiryQuality: number | null;
    likelihoodToProceed: number | null;
    timezone: TimezoneOption[];
    isBookMeetingFromHistoryActivity: boolean;
}

// interface PreviousReferrerState {
//     referrerGuid: string | null;
//     referrerName: string | null;
//     sourceGuid: string | null; // Should match with the source, if we want to re-populate the same old value again
//     sourceNotes?: string;
// }

export const EnquiryFormDrawer: React.FunctionComponent<EnquiryFormProps> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation() as {
        t: (key: keyof TranslationTextList) => string;
    };
    const [isLoading, setIsLoading] = useState(true);
    const [filesKey, setKey] = useState('');
    const [appUser, setAppUser] = useState<AppUser>();
    const history = useHistory();

    const regionSettings = useRegionSettings();

    const isCreateForm = props.formAction === 'edit' ? false : true;

    const [state, setState] = useState<EnquiryFormState>({
        isFormChanged: false,
        showDiscard: false,
        showTabsSaveAlert: false,
        tab: EnquiryFormTab.details,
        isEmailNotification: true,
        isCreateClient: false,
        sourceSystem: '',
        homeCountry: '',
        isCreateClientOpen: false,
        isExpandDrawer: false,
        currency: '$',
        // tenantTimezone: null,
        isSendEmailDialogOpen: false,
        hideSendAcknowledgement: true,
        generalInfoData: undefined,
        financials: null,
        isMarkClientMatterCreatedOpen: false,
        matterClientName: '',
        isContactDetailsPanelExpand: isCreateForm,
        isEnquiryDetailsPanelExpand: isCreateForm,
        isDetailedEnquiryNotesPanelExpand: false,
        isAssignPanelExpand: false,
        isFormSubmit: false,
        enquiryQuality: null,
        likelihoodToProceed: null,
        isOtherContactDetailsPanelExpand: false,
        isOtherDetailsPanelExpand: false,
        isOther2DetailsPanelExpand: false,
        isClient2PostalAddressPanelExpand: false,
        isPostalAddressPanelExpand: false,
        isOtherSidePostalAddressPanelExpand: false,
        timezone: [],
        isOtherSidePanelExpand: false,
        isBookMeetingFromHistoryActivity: false
    });
    
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [enquiryActionData, setEnquiryActionData] = useState<Actions>();

    const [leadDetailsHistory, setLeadDetailsHistory] = useState<TaskSummaryList>({
        tasks: [],
        actualRowCountForSearch: 0,
        recordCount: 0
    });

    // Used for InitialData and fetching saved enquiry
    const [enquiryDetail, setEnquiryDetails] = useState<EnquiryDetail>({
        guid: props.guid,
        createdDate: moment().toDate(),
        createdByName: '',
        lastUpdated: moment().toDate(),
        isClosed: false,
        isContactCreated: false,
        isMatterCreated: false,
        firstName: '',
        lastName: '',
        email: '',
        gender: 'Unknown',
        initialEnquiryDate: moment().toDate(),
        phone: '',
        mobile: '',
        sourceGuidID: null,
        source: '',
        isSourceReferrer: false,
        officeGuidID: null,
        office: '',
        sourceNotes: '',

        organisation: '',
        positionAtOrganisation: '',

        areaOfLaw: '',
        practiceAreaGuidID: '',
        natureOfEnquiry: '',

        internalNotes: null,
        files: [],

        assignedTo: null,
        assignedToName: null, // Display Name
        assignedToEmailAddress: null,
        assignedBy: null,
        assignedByName: null, // Display Name
        assignedByEmailAddress: null,
        dateAssigned: moment().toDate(),
        followUpDueDate: null,
        assignmentReminderOffset: null,

        followUpActionGuidID: null,
        followUpAction: null,
        followUpActionSystemName: null,
        followUpSystemAction: null,
        followUpDate: null,
        outcomeGuidID: null,
        outcome: null,
        outcomeDate: null,
        outcomeNotes: '',
        reason: null,
        reasonGuidID: null,
        followUpNotes: null,
        isKeyOpportunity: props.isKeyOpportunity,
        referrerGuid: null,
        referrerName: null,
        nameGuid: null,
        nameTitle: null,
        organisationGuid: null,

        estimatedFees: null,
        isEstimatedFeesIncludesTax: false,
        estimatedDisbursements: null,
        isEstimatedDisbursementsIncludesTax: false,
        enquiryQuality: null,
        likelihoodToProceed: null,
        clientFirstName: null,
        clientLastName: null,
        dOB: null,
        isMarketingConsent: false,
        isTermsAndConditionsApproved: false,
        isPrivacyStatementProvided: false,
        isConflictCheckPerformed: false,
        isNoConflict: false,
        isIdentityCheckPerformed: false,
        addressName: null,
        floor: null,
        number: null,
        street: null,
        clientAddressStreet2: null,
        suburb: null,
        state: null,
        postcode: null,
        country: null,
        pOBoxType: props.pOBoxType ? props.pOBoxType : null,
        pOBox: null,
        postalSuburb: null,
        postalState: null,
        postalPostCode: null,
        postalCountry: null,
        referredToFirmGuid: null,
        referredToFirmName: '',
        notProceedingOutcomeNotes: null,
        clientRelationshipToEnquirer: null,
        matterId: null,
        matterGuid: null,
        matterTitle: null,
        matterFileNumber: null,
        assignedToIsStaff: false,
        assignedToStaffGuid: null,
        assignedToStaffName: null,
        otherPartyFirstName: null,
        otherPartyLastName: null,
        otherPartyNotes: null,
        otherPartyEmail: null,
        otherPartyPhone: null,
        otherPartyMobile: null,
        otherPartyOrganisation: null,
        enquiryNotes: null,
        integrationContactId: null,
        integrationClientId: null,
        integrationOtherPartyId: null,
        integrationMatterId: null,
        integrationMatterDocumentId: null,
        otherPartyDOB: null,
        otherPartyAddressName: null,
        otherPartyFloor: null,
        otherPartyNumber: null,
        otherPartyStreet: null,
        otherPartyStreet2: null,
        otherPartySuburb: null,
        otherPartyState: null,
        otherPartyPostcode: null,
        otherPartyCountry: null,

        otherPartyPOBoxType: props.pOBoxType ? props.pOBoxType : null,
        otherPartyPOBox: null,
        otherPartyPostalSuburb: null,
        otherPartyPostalState: null,
        otherPartyPostalPostCode: null,
        otherPartyPostalCountry: null,
        otherPartyNameGuid: null,
        otherPartyOrganisationGuid: null,

        isRiskAssessed: null,
        riskLevelGuid: null,
        riskLevel: null,
        riskNotes: null,

        secondClientFirstName:                    null,
        secondClientLastName:                     null,
        secondClientRelationshipToEnquirer:       null,
        secondClientGender:                       'Unknown',
        secondClientSuburb:                       null,
        secondClientState:                        null,
        secondClientPostCode:                     null,
        secondClientIsMarketingConsent:           false,
        secondClientIsTermsAndConditionsApproved: false,
        secondClientIsPrivacyStatementProvided:   false,
        secondClientIsConflictCheckPerformed:     false,
        secondClientIsIdentityCheckPerformed:     false,
        secondClientIsNoConflict:                 false,
        secondClientCountry:                       null,
        secondClientAddressName:                   null,
        secondClientFloor:                         null,
        secondClientNumber:                        null,
        secondClientStreet1:                       null,
        secondClientStreet2:                       null,
        secondClientDOB:                           null,
        secondClientPOBoxType:                     props.pOBoxType ? props.pOBoxType : null,
        secondClientPOBox:                         null,
        secondClientPostalSuburb:                  null,
        secondClientPostalState:                   null,
        secondClientPostalPostCode:                null,
        secondClientPostalCountry:                 null,
        secondClientGuid: null,

        responseText: null,

        eventAndCampaignGuid: null,
        eventAndCampaignName: null,
        eventAndCampaignNotes: null,
        eventAndCampaignNotesGuid: null,

        isNameFromDataSource: null,
        isOrganisationFromDataSource: null,
        isOtherSideFromDataSource: null,
        isSecondClientFromDataSource: null,

        // new fields
        title: null,
        otherPartyTitle: null,

        clientTitle: null,
        clientGuid: null,
        clientEmail: null,
        clientPhone: null,
        clientMobile: null,

        secondClientTitle: null,
        secondClientEmail: null,
        secondClientPhone: null,
        secondClientMobile: null,
    });

    const getReminderValue = (isAssignment: boolean) => {
        if (isAssignment && enquiryDetail && enquiryDetail.assignmentReminderOffset) {
            return { label: enquiryDetail.assignmentReminderOffset, value: enquiryDetail.assignmentReminderOffset };
        } else if (props.enquiryDefaultConfig && props.enquiryDefaultConfig.reminder) {
            return { label: props.enquiryDefaultConfig.reminder, value: props.enquiryDefaultConfig.reminder };
        } else {
            return { label: 'None', value: 'None' };
        }
    };

    useEffect(() => {
        checkEmailTemplateCount(null);
        fetchCurrency();
        // tslint:disable-next-line: align
    }, []);

    useEffect(() => {
        // - Ran only once
        // If we recieve a guid, then fetch EnquiryDetail
        if (props.guid) {
            setIsLoading(true);
            fetchAppUserOnEffect();
            fetchEnquiryDetails(props.guid);
            fetchTenantDataOnEffect();
            fetchHomeCountryOnEffect();
            // fetchTenantTimezone();
            fetchTimezone();
            fetchLeadDetailsHistory(props.guid);
        } else {
            setIsLoading(false);
        }
    // tslint:disable-next-line: align
    }, [props.isRefreshData, props.guid]);

    const shouldExecuteQuery =
        props.nameSummary ||
        (props.eventAndCampaignAttendeeSummary && (props.eventAndCampaignAttendeeSummary.entityTypeId === 0 || props.eventAndCampaignAttendeeSummary.entityTypeId === 2)) ||
        props.partyDetails
            ? true
            : false;

    const partyAddressesQuery = useQuery<PartyAddressesData, PartyAddressesParams>(PartyAddressesQuery, {
        variables: {
            ...(props.nameSummary && {
                nameId: props.nameSummary.nameId,
                isFromDataSource: props.nameSummary.isFromDataSource
            }) as PartyAddressesParams,
            ...(props.eventAndCampaignAttendeeSummary && {
                nameId: (props.eventAndCampaignAttendeeSummary as EventAndCampaignAttendeeSummary).nameId,
                isFromDataSource: (props.eventAndCampaignAttendeeSummary as EventAndCampaignAttendeeSummary).entityTypeId === 0
            }) as PartyAddressesParams,
            ...(props.partyDetails && {
                nameId: props.partyDetails.id,
                isFromDataSource: props.partyDetails.isFromDataSource
            }) as PartyAddressesParams
        },
        skip: !shouldExecuteQuery
    });

    // Form's State
    let initialValues: Enquiry = {
        duplicateCheck: null,
        isBookMeetingEmailDialogOpen: false,
        isClosed: false,
        firstName: '',
        lastName: '',
        individualSelector: null,
        isNameSwitched: false, // props.taskSummary && props.taskSummary.fromName && props.taskSummary.fromNameGuid ? true : false,
        nameGuid: null,
        email: '',
        gender: 'Unknown',
        initialEnquiryDate: moment().toDate(),
        phone: '',
        mobile: '',
        source: null,
        office: null,
        sourceNotes: '',

        organisationSelector: null,
        isOrganisationSwitched:  false, // props.taskSummary && props.taskSummary.organisation && props.taskSummary.organisationGuid ? true : false,
        organisation: '',
        organisationGuid: null,
        positionAtOrganisation: '',

        practiceAreaGuidID: null,
        areaOfLaw: '',
        natureOfEnquiry: '',

        internalNotes: '',
        files: [],

        assignedTo: null,
        assignedToName: null, // Display Name
        assignedBy: props.assignedBy,
        assignedByName: props.assignedByName, // Display Name
        dateAssigned: moment().toDate(),
        followUpDueDate: null,
        assignmentFollowUpDueReminder: getReminderValue(true),

        // followUpAction: 'Email',
        followUpAction: null,
        followUpActionSystemName: null,
        followUpSystemAction: null,
        followUpDate: null,
        // outcome: 'More Follow Up',
        outcome: null,
        outcomeDate: null,
        outcomeNotes: '',
        reason: null,
        reasonGuidID: null,
        followUpNotes: null,
        hasFollowUpNotesMentions: false,
        followUpDueReminder: null,

        handleCreateAndClose: false,
        handleCreateAndNew: false,
        handleUpdate: false,
        // showDiscard: false,
        // isFormChanged: false,
        isAssignmentExpand: true,
        isKeyOpportunity: props.isKeyOpportunity,
        isNotProceeding: false,
        notProceedingReason: null,
        notProceedingReasonGuid: null,

        referrerGuid: null,
        referrerName: null,
        isShowReferrer: false,

        staff: null,
        startTime: null, // moment().add(1, 'hour').toDate(),
        endTime: null, // moment().add(2, 'hour').toDate(),
        isAllDay: false,
        officeLocation: null,
        feeApplicable: null,
        isSendConfirmation: true,
        meetingNotes: null,
        timezone: null,

        emailTemplate: null,

        isContactDetailsChanged: false,
        isUpdateContacts: false,
        isSentAcknowledgementEmail: false,
        updateFollowUpDueForInternalNote: true,
        outcomeDateChanged: false,

        estimatedFees: null,
        isEstimatedFeesIncludesTax: false,
        estimatedDisbursements: null,
        isEstimatedDisbursementsIncludesTax: false,
        enquiryQuality: 3,
        likelihoodToProceed: 3,
        clientFirstName: null,
        clientLastName: null,
        dOB: null,
        isMarketingConsent: false,
        isConflictCheckPerformed: false,
        isTermsAndConditionsApproved: false,
        isIdentityCheckPerformed: false,
        isPrivacyStatementProvided: false,
        addressName: null,
        floor: null,
        number: null,
        street: null,
        street2: null,
        suburb: null,
        state: null,
        postcode: null,
        country: null,
        pOBox: null,
        postalSuburb: null,
        postalState: null,
        postalPostCode: null,
        postalCountry: null,
        isNoConflict: false,
        isShowNotProceedingReferrer: false,
        isNotProceedingReferrerSwitched: false,
        referredToFirmSelector: null,
        referredToFirmGuid: null,
        referredToFirmName: null,
        notProceedingOutcomeNotes: null,
        // Fields inside proceeding section
        isShowNotProceedingReferrerInProceeding: false,
        isNotProceedingReferrerSwitchedInProceeding: false,
        referredToFirmSelectorInProceeding: null,
        referredToFirmGuidInProceeding: null,
        referredToFirmNameInProceeding: null,
        notProceedingOutcomeNotesInProceeding: null,
        clientRelationshipToEnquirer: null,
        matterId: null,
        matterGuid: null,
        matterTitle: null,
        matterFileNumber: null,
        matterSelector: null,
        pOBoxType: props.pOBoxType,
        enquiryNotes: null,

        otherPartyFirstName: null,
        otherPartyLastName: null,
        otherPartyNotes: null,
        otherPartyEmail: null,
        otherPartyMobile: null,
        otherPartyPhone: null,
        otherPartyOrganisation: null,
        otherPartyDOB: null,
        otherPartyAddressName: null,
        otherPartyFloor: null,
        otherPartyNumber: null,
        otherPartyStreet: null,
        otherPartyStreet2: null,
        otherPartySuburb: null,
        otherPartyState: null,
        otherPartyPostcode: null,
        otherPartyCountry: null,

        otherPartyPOBoxType: props.pOBoxType,
        otherPartyPOBox: null,
        otherPartyPostalSuburb: null,
        otherPartyPostalState: null,
        otherPartyPostalPostCode: null,
        otherPartyPostalCountry: null,
        isOtherPartyNameSwitched: false,
        otherPartyNameGuid: null,
        otherPartyOrganisationGuid: null,
        otherPartyIndividualSelector: null,

        isSavedDirectly: false,

        isRiskAssessed: null,
        riskLevelSelector: null,
        riskNotes: null,

        secondClientFirstName: null,
        secondClientLastName: null,
        secondClientRelationshipToEnquirer: null,
        secondClientGender: 'Unknown',
        secondClientSuburb: null,
        secondClientState: null,
        secondClientPostCode: null,
        secondClientIsMarketingConsent: false,
        secondClientIsTermsAndConditionsApproved: false,
        secondClientIsPrivacyStatementProvided: false,
        secondClientIsConflictCheckPerformed: false,
        secondClientIsIdentityCheckPerformed: false,
        secondClientIsNoConflict: false,
        secondClientCountry: null,
        secondClientAddressName: null,
        secondClientFloor: null,
        secondClientNumber: null,
        secondClientStreet1: null,
        secondClientStreet2: null,
        secondClientDOB: null,
        secondClientPOBoxType: props.pOBoxType,
        secondClientPOBox: null,
        secondClientPostalSuburb: null,
        secondClientPostalState: null,
        secondClientPostalPostCode: null,
        secondClientPostalCountry: null,

        responseText: null,

        // bot integration
        followUpBotUrl: null,

        // events and campaigns
        eventAndCampaignSelector: null,
        eventsAndCampaignNotes: null,

        title: 'None',
        clientTitle: 'None',
        secondClientTitle: 'None',
        clientEmail: null,
        clientGuid: null,
        clientMobile: null,
        clientPhone: null,
        otherPartyTitle: 'None',
        secondClientGuid: null,
        secondClientEmail: null,
        secondClientMobile: null,
        secondClientPhone: null,
        clientIndividualSelector: null,
        isClientNameSwitched: false,
        secondClientIndividualSelector: null,
        isSecondClientNameSwitched: false
    };

    const getTaskSummaryIndividualSelectorValue = (taskSummary?: TaskSummary) => {
        if (!taskSummary) {
            return null;
        }
        
        if (!(props.taskSummary && props.taskSummary.fromName && props.taskSummary.fromNameGuid)) {
            return null;
        }

        return {
            label: props.taskSummary.fromName, 
            value: props.taskSummary.fromNameGuid, 
            nameId: props.taskSummary.fromNameId, 
            guidID: props.taskSummary.fromNameGuid,
            // isFromDataSource: taskSummary.isFromDataSource 
        };
    };

    // get all possible data from task summary to enquiry form
    if (props.taskSummary) {
        initialValues = {
            ...initialValues,
            firstName: props.taskSummary && props.taskSummary.fromName ? separateName(props.taskSummary.fromName).firstName : '',
            lastName: props.taskSummary && props.taskSummary.fromName ? separateName(props.taskSummary.fromName).lastName : '',
            individualSelector: getTaskSummaryIndividualSelectorValue(props.taskSummary) as IndividualNameSummary,
            nameGuid: props.taskSummary && props.taskSummary.fromNameGuid ? props.taskSummary.fromNameGuid : null,
            phone: props.taskSummary ? props.taskSummary.phoneNumber ?? '' : '',
            organisationSelector:
            props.taskSummary && props.taskSummary.organisation && props.taskSummary.organisationGuid
                ? { label: props.taskSummary.organisation, value: props.taskSummary.organisationGuid } as OrganisationIAutoCompleteItem
                : null,
            organisation: props.taskSummary ? props.taskSummary.organisation ?? '' : '',
            organisationGuid: props.taskSummary && props.taskSummary.organisationGuid ? props.taskSummary.organisationGuid : null,
            natureOfEnquiry: props.taskSummary && props.taskSummary.notes ? removeTagsAndStyles(props.taskSummary.notes)  : '',
        };
    }

    const getEventsIndividualSelectorValue = (attendee?: EventAndCampaignAttendeeSummary) => {
        
        if (!attendee) {
            return null;
        }

        if (!attendee.name && !attendee.entityGuid && !attendee.nameId) {
            return null;
        }

        return { 
            label: attendee.name, 
            value: attendee.entityGuid, 
            nameId: attendee.nameId, 
            guidID: attendee.entityGuid,
            isFromDataSource: attendee.entityTypeId === 0 ? true : false 
        };
    };

    // get all possible data from event and campaign attendee summary to enquiry form
    if (props.eventAndCampaignAttendeeSummary) {
        initialValues = {
            ...initialValues,

            // individual
            firstName:
                props.eventAndCampaignAttendeeSummary && props.eventAndCampaignAttendeeSummary.name ? separateName(props.eventAndCampaignAttendeeSummary.name).firstName : '',
            lastName: props.eventAndCampaignAttendeeSummary && props.eventAndCampaignAttendeeSummary.name ? separateName(props.eventAndCampaignAttendeeSummary.name).lastName : '',

            ...(props.eventAndCampaignAttendeeSummary &&
                (props.eventAndCampaignAttendeeSummary.entityTypeId === 0 || props.eventAndCampaignAttendeeSummary.entityTypeId === 2) && {
                    individualSelector: getEventsIndividualSelectorValue(props.eventAndCampaignAttendeeSummary) as IndividualNameSummary,
                    nameGuid: props.eventAndCampaignAttendeeSummary && props.eventAndCampaignAttendeeSummary.entityGuid ? props.eventAndCampaignAttendeeSummary.entityGuid : null,
                }),

            phone: props.eventAndCampaignAttendeeSummary ? props.eventAndCampaignAttendeeSummary.phone ?? '' : '',
            mobile: props.eventAndCampaignAttendeeSummary ? props.eventAndCampaignAttendeeSummary.mobile ?? '' : '',
            email: props.eventAndCampaignAttendeeSummary ? props.eventAndCampaignAttendeeSummary.email ?? '' : '',

            // organistaion
            organisation: props.eventAndCampaignAttendeeSummary ? props.eventAndCampaignAttendeeSummary.organisation ?? '' : '',
            organisationSelector:
                props.eventAndCampaignAttendeeSummary && props.eventAndCampaignAttendeeSummary.organisation && props.eventAndCampaignAttendeeSummary.organisationGuid
                    ? ({
                          label: props.eventAndCampaignAttendeeSummary.organisation,
                          value: props.eventAndCampaignAttendeeSummary.organisationGuid,
                      } as OrganisationIAutoCompleteItem)
                    : null,
            organisationGuid:
                props.eventAndCampaignAttendeeSummary && props.eventAndCampaignAttendeeSummary.organisationGuid ? props.eventAndCampaignAttendeeSummary.organisationGuid : null,
            natureOfEnquiry:
                props.eventAndCampaignAttendeeSummary && props.eventAndCampaignAttendeeSummary.attendanceNotes
                    ? removeTagsAndStyles(props.eventAndCampaignAttendeeSummary.attendanceNotes)
                    : '',

            eventAndCampaignSelector:
                props.eventAndCampaignAttendeeSummary && props.eventAndCampaignAttendeeSummary.eventAndCampaignName && props.eventAndCampaignAttendeeSummary.eventAndCampaignGuid
                    ? ({
                          label: props.eventAndCampaignAttendeeSummary.eventAndCampaignName,
                          value: props.eventAndCampaignAttendeeSummary.eventAndCampaignGuid,
                      } as EventsAndCampaignIAutoCompleteItem)
                    : null,
            eventsAndCampaignNotes: props.eventAndCampaignAttendeeSummary ? props.eventAndCampaignAttendeeSummary.attendanceNotes : null,
        };
    }

    const getNameSummaryIndividualSelectorValue = (nameSummary?: NameSummary) => {
        
        if (!nameSummary) {
            return null;
        }

        if (!nameSummary.name && !nameSummary.guidID && !nameSummary.nameId) {
            return null;
        }

        return { 
            label: nameSummary.name, 
            value: nameSummary.guidID, 
            nameId: nameSummary.nameId, 
            guidID: nameSummary.guidID,
            isFromDataSource: nameSummary.isFromDataSource 
        };
    };

    if (props.nameSummary) {
        initialValues = {
            ...initialValues,

            // individual
            firstName: props.nameSummary && props.nameSummary.name ? separateName(props.nameSummary.name).firstName : '',
            lastName: props.nameSummary && props.nameSummary.name ? separateName(props.nameSummary.name).lastName : '',
             
            individualSelector: getNameSummaryIndividualSelectorValue(props.nameSummary) as IndividualNameSummary,
            nameGuid: props.nameSummary && props.nameSummary.guidID,
            // ...(props.nameSummary && props.nameSummary.entityTypeId === 0 && {   
            //     individualSelector: getEventsIndividualSelectorValue(props.nameSummary) as IndividualNameSummary,
            //     nameGuid: props.nameSummary && props.nameSummary.entityGuid ? props.nameSummary.entityGuid : null,
            // }),
            
            phone: props.nameSummary ? props.nameSummary.phone ?? '' : '',
            mobile: props.nameSummary ? props.nameSummary.mobile ?? '' : '',
            email: props.nameSummary ? props.nameSummary.emailAddress ?? '' : '',

            // organistaion
            // organisation: props.nameSummary ? props.nameSummary.organisation ?? '' : '',
            // organisationSelector:
            // props.nameSummary && props.nameSummary.organisation && props.nameSummary.organisationGuid
            //     ? { label: props.nameSummary.organisation, value: props.nameSummary.organisationGuid }
            //     : null,
            // organisationGuid: props.nameSummary && props.nameSummary.organisationGuid ? props.nameSummary.organisationGuid : null,
            // natureOfEnquiry: props.nameSummary && props.nameSummary.notes ? removeTagsAndStyles(props.nameSummary.notes)  : '',
            
        };
    }

    const getPartyDetailsIndividualSelectorValue = (nameSummaryPartyDefailts?: Partydetails) => {
        
        if (!nameSummaryPartyDefailts) {
            return null;
        }

        if (!nameSummaryPartyDefailts.firstName && !nameSummaryPartyDefailts.lastName && !nameSummaryPartyDefailts.guid && !nameSummaryPartyDefailts.id) {
            return null;
        }

        const fullName = `${nameSummaryPartyDefailts.firstName} ${nameSummaryPartyDefailts.lastName}`;  // Concatenate firstName and lastName

        return { 
            label: fullName, 
            value: nameSummaryPartyDefailts.guid, 
            nameId: nameSummaryPartyDefailts.id, 
            guidID: nameSummaryPartyDefailts.guid,
            isFromDataSource: nameSummaryPartyDefailts.isFromDataSource  
        };
    };

    const getPhoneNumberByType = (phoneNumbers: PhoneNumber[], type: 'Phone' | 'Mobile') => {
        if (phoneNumbers.length === 0) {
            return null;
        }
        return phoneNumbers.find(phone => phone.type === type) ?? null;
    };

    const getPartyFirstEmail = (emailAddresses: EmailAddress[]) => {
        if (emailAddresses.length === 0) {
            return null;
        }
        return emailAddresses[0];
    };

    const getFirstAddressOfEachType = (data: PartyAddressesData) => {
        const addressTypes = new Set();
        const firstAddresses = [];
    
        for (const address of data.party.addresses.address) {
            if (!addressTypes.has(address.addressType)) {
                addressTypes.add(address.addressType);
                firstAddresses.push(address);
            }
        }
    
        return firstAddresses;
    };

    if (props.partyDetails) {
        
        initialValues = {
            ...initialValues,

            // individual
            firstName: props.partyDetails && props.partyDetails.firstName ? props.partyDetails.firstName : '',
            lastName: props.partyDetails && props.partyDetails.lastName ? props.partyDetails.lastName : '',
            // tslint:disable-next-line: no-any
            individualSelector: getPartyDetailsIndividualSelectorValue(props.partyDetails) as any,
            nameGuid: props.partyDetails && props.partyDetails.guid,
            // ...(props.partyDetails && props.partyDetails.entityTypeId === 0 && {   
            //     individualSelector: getEventsIndividualSelectorValue(props.partyDetails) as IndividualNameSummary,
            //     nameGuid: props.partyDetails && props.partyDetails.entityGuid ? props.partyDetails.entityGuid : null,
            // }),
            
            phone: props.partyDetails ? getPhoneNumberByType(props.partyDetails.phoneNumbers, 'Phone')?.number ?? '' : '',
            mobile: props.partyDetails ? getPhoneNumberByType(props.partyDetails.phoneNumbers, 'Mobile')?.number ?? '' : '',
            email: props.partyDetails.emailAddresses ? getPartyFirstEmail(props.partyDetails.emailAddresses)?.email ?? '' : '',

            // organistaion
            // organisation: props.partyDetails ? props.partyDetails.organisation ?? '' : '',
            // organisationSelector:
            // props.partyDetails && props.partyDetails.organisation && props.partyDetails.organisationGuid
            //     ? { label: props.partyDetails.organisation, value: props.partyDetails.organisationGuid }
            //     : null,
            // organisationGuid: props.partyDetails && props.partyDetails.organisationGuid ? props.partyDetails.organisationGuid : null,
            // natureOfEnquiry: props.partyDetails && props.partyDetails.nameNotes ? removeTagsAndStyles(props.partyDetails.nameNotes)  : '',
        };
    }

    // set default value for address if exists in the party addresss
    if (partyAddressesQuery.data && partyAddressesQuery.data.party.addresses.address.length > 0 && !partyAddressesQuery.loading) {

        const addresses = getFirstAddressOfEachType(partyAddressesQuery.data);

        addresses.forEach((address) => {
            // tslint:disable-next-line: no-shadow-variable
            const { addressType, country, floor, name: buildingName, number: buildingNumber, postcode, state: addressState, street, suburb } = address;

            initialValues = {
                ...initialValues,
                ...(addressType === 'Street' && {
                    addressName: buildingName,
                    floor: floor,
                    number: buildingNumber,
                    street: street,
                    street2: '',
                    suburb: suburb,
                    state: addressState,
                    postcode: postcode,
                    country: country,
                }),
                ...(addressType === 'POBox' && {
                    pOBox: buildingNumber,
                    postalSuburb: suburb,
                    postalState: addressState,
                    postalPostCode: postcode,
                    postalCountry: country,
                })
            };
        });
    }

    const fetchMatterDetails = (id: number) => {
        const generalInfoDataParams: GeneralInfoDataParams = {
            id: id
        };

        retrieveGeneralInfoData(
            generalInfoDataParams,
            true,
            (data: GeneralInfoData) => handleMatterDetailsFetch(data),
            // tslint:disable-next-line: no-any
            (reason: any) => showNotification('Failed to Fetch App User Settings', reason, 'error')
        );
    };

    const handleMatterDetailsFetch = (data: GeneralInfoData) => {
        const generalData: CustomTableContentProps[] = Object
            .entries(data.matter)
            .filter(([_, v]) => v != null && v !== undefined && v.length > 0)
            // tslint:disable-next-line: no-any
            .map((matter: any, index) => {

                let name = matter[0];
                let value = matter[1];
                let type = ValueTypeEnum.STRING;
                let hide = true;
                let order = index;

                if (matter[0] === 'id') {
                    name = 'Id';
                }
                if (matter[0] === 'matterCategory') {
                    name = 'Category';
                    hide = false;
                    order = 2;
                }
                if (matter[0] === 'country') {
                    name = 'Country';
                }
                if (matter[0] === 'family') {
                    name = 'Family';
                }
                if (matter[0] === 'fileLocation') {
                    name = 'Location';
                }
                if (matter[0] === 'fileNumber') {
                    name = 'File Number';
                }
                if (matter[0] === 'firbStatus') {
                    name = 'Status';
                }
                if (matter[0] === 'lastUpdate') {
                    name = 'Last Update';
                    type = ValueTypeEnum.DATE;
                }
                if (matter[0] === 'matterType') {
                    name = 'Matter Type';
                    hide = false;
                    order = 3;
                }
                if (matter[0] === 'property') {
                    name = 'Property';
                }
                if (matter[0] === 'propertyLabel') {
                    name = 'Property Label';
                }
                if (matter[0] === 'stage') {
                    name = 'Stage';
                }
                if (matter[0] === 'state') {
                    name = 'State';
                }
                if (matter[0] === 'status') {
                    name = 'Status';
                    hide = false;
                    order = 1;
                }
                if (matter[0] === 'description') {
                    name = 'Description';
                }
                if (name === 'title') {
                    name = 'Title';
                    hide = false;
                    order = 0;
                }
                if (name === 'images') {
                    name = 'Images';
                    // tslint:disable-next-line: no-any
                    value = matter[1].image.map((image: any) => image.location);
                    type = ValueTypeEnum.ARRAY_OF_IMAGE;
                }

                return {
                    client: {
                        value: name,
                        type: ValueTypeEnum.STRING
                    },
                    company: {
                        value: value,
                        type: type
                    },
                    hide: hide,
                    order: order
                };
            }); // .filter(a => a.items[0].value !== null && a.name !== 'id' && a.name !== '__typename');

        setState((prevState) => {
            return {
                ...prevState,
                generalInfoData: generalData,
                financials: data.matter.financials,
                matterClientName: data.matter.client
            };
        });
    };

    const fetchAppUserOnEffect = () => {
        fetchAppUserData(
            false,
            (data: AppUserData) => {
                handleAppUserFetch(data);
            },
            (reason) => {
                showNotification('Failed to Fetch App User Settings', reason, 'error');
            }
        );
    };

    const handleAppUserFetch = (data?: AppUserData) => {
        if (
            data !== null &&
            data?.appUser !== undefined &&
            data.appUser.isAdministrator !== undefined
        ) {
            setAppUser((prevAppUser) => {
                // Object.assign would also work
                return {
                    ...prevAppUser,
                    userId: data.appUser.userId,
                    name: data.appUser.name,
                    isAdministrator: data.appUser.isAdministrator,
                    userName: data.appUser.userName
                };
            });
        }
    };

    const fetchCurrency = () => {
        retrieveCurrencySymbolData(
            false,
            // tslint:disable-next-line: no-any
            (data: any) => onDataCurrency(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            });
    };

    const onDataCurrency = (data: CurrencySymbolData) => {
        setState((prevState) => {
            return {
                ...prevState,
                currency: data.options.currencySymbol.symbol
            };
        });
    };

    // const fetchTenantTimezone = () => {
    //     fetchTenantTimezoneData(
    //         false,
    //         // tslint:disable-next-line: no-any
    //         (data: any) => onDataTenantTimezone(data),
    //         // tslint:disable-next-line:no-any
    //         function (reason: any): void {
    //             showNotification(null, reason, 'error');
    //         });
    // };

    // const onDataTenantTimezone = (data: RegionSettingsData) => {
    //     setState((prevState) => {
    //         return {
    //             ...prevState,
    //             tenantTimezone: {
    //                 value: data.settings.systems.regionSettings.config.timeZone,
    //                 label: data.settings.systems.regionSettings.config.timeZoneDescription,
    //                 secondaryValue: data.settings.systems.regionSettings.config.baseUtcOffset
    //             }
    //         };
    //     });
    // };

    const fetchTimezone = () => {
        retrieveTimezoneOptionData(
            false,
            // tslint:disable-next-line: no-console
            (data) => onRetrieveTimezoneData(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onRetrieveTimezoneData = (data: TimezoneOptionData) => {
        setState((prevState) => {
            return {
                ...prevState,
                timezone: data.options.timezoneOption
            };
        });
    };

    const fetchLeadDetailsHistory = (guid: string) => {
        var leadQueryParams: LeadDetailsHistoryQueryParams = {
            offset: null,
            first: null,
            filter: null,
            sortColumn: 'DueDate',
            sortDirection: 'ASC',
            entityGuidID: guid
        };

        retrieveLeadDetailsHistoryData(
            leadQueryParams,
            true,
            // tslint:disable-next-line: no-any
            (data: any) => onEnquiryHistoryDataRetrieved(data),
            // tslint:disable-next-line
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onEnquiryHistoryDataRetrieved = (data: LeadDetailsHistoryData) => {

        setLeadDetailsHistory(data.task.summaryList);

        const summaryList = data.task.summaryList;

        // const task = summaryList.tasks[summaryList.tasks.length - 1];
        const createClientTask = summaryList.tasks.find((task: TaskDetails) => {
            return (task.taskTypeDescription.toLowerCase() === 'create client' || task.taskTypeDescription.toLowerCase() === 'create matter')
                && task.taskStatusDescription.toLowerCase() === 'not started';
        });

        let isCreateClient = false;
        if (createClientTask) {
            isCreateClient = true;
        }

        setState((prevState) => {
            return {
                ...prevState,
                isCreateClient: isCreateClient
            };
        });
    };
    
    const onGetPartyAddresses = (form: FormApi<Enquiry>, values: AnyObject, selection: IndividualNameSummary, name: string) => {

        const params: PartyAddressesParams = {
            nameId: selection.nameId,
            isFromDataSource: selection.isFromDataSource
        };

        retrieveQueryData<PartyAddressesParams, PartyAddressesData>(
            PartyAddressesQuery,
            params,
            false,
            (data) => onDataPartyAddressesRetrieved(data, form, values, name),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
                setIsLoading(false);
            }
        );
    };

    const onDataPartyAddressesRetrieved = (data: PartyAddressesData, form: FormApi<Enquiry>, values: AnyObject, name: string) => {

        if (data && data.party.addresses.address.length === 0) {

            if (name === 'individualSelector' || name === 'clientIndividualSelector') {

                form.change('addressName', null);
                form.change('floor', null);
                form.change('number', null);
                form.change('street', null);
                // form.change('street2', street2);
                form.change('suburb', null);
                form.change('state', null);
                form.change('postcode', null);
                form.change('country', null);
    
            }

            if (name === 'secondClientIndividualSelector') {

                form.change('secondClientAddressName', null);
                form.change('secondClientFloor', null);
                form.change('secondClientNumber', null);
                form.change('secondClientStreet1', null);
                // form.change('secondClientStreet2', null);
                form.change('secondClientSuburb', null);
                form.change('secondClientState', null);
                form.change('secondClientPostCode', null);
                form.change('secondClientCountry', null);
    
            }
            
            if (name === 'otherPartyIndividualSelector') {
                form.change('otherPartyAddressName', null);
                form.change('otherPartyFloor', null);
                form.change('otherPartyNumber', null);
                form.change('otherPartyStreet', null);
                form.change('otherPartySuburb', null);
                form.change('otherPartyState', null);
                form.change('otherPartyPostcode', null);
                form.change('otherPartyCountry', null);
            }
        } else {
            
            const addresses = getFirstAddressOfEachType(data);

            addresses.forEach((address) => {
                // tslint:disable-next-line: no-shadow-variable
                const { addressType, country, floor, name: buildingName, number: buildingNumber, postcode, state: addressState, street, suburb } = address;

                if (addressType === 'Street') {

                    if (name === 'individualSelector' || name === 'clientIndividualSelector') {
                        
                        form.change('addressName', buildingName);
                        form.change('floor', floor);
                        form.change('number', buildingNumber);
                        form.change('street', street);
                        // form.change('street2', street2);
                        form.change('suburb', suburb);
                        form.change('state', addressState);
                        form.change('postcode', postcode);
                        form.change('country', country);
                    }

                    if (name === 'secondClientIndividualSelector') {
                        form.change('secondClientAddressName', buildingName);
                        form.change('secondClientFloor', floor);
                        form.change('secondClientNumber', buildingNumber);
                        form.change('secondClientStreet1', street);
                        // form.change('secondClientStreet2', street2);
                        form.change('secondClientSuburb', suburb);
                        form.change('secondClientState', addressState);
                        form.change('secondClientPostCode', postcode);
                        form.change('secondClientCountry', country);
                    }

                    if (name === 'otherPartyIndividualSelector') {
                        form.change('otherPartyAddressName', buildingName);
                        form.change('otherPartyFloor', floor);
                        form.change('otherPartyNumber', buildingNumber);
                        form.change('otherPartyStreet', street);
                        form.change('otherPartySuburb', suburb);
                        form.change('otherPartyState', addressState);
                        form.change('otherPartyPostcode', postcode);
                        form.change('otherPartyCountry', country);
                    }
                }

                if (addressType === 'POBox') {

                    if (name === 'individualSelector') {
                        // form.change('pOBoxType', null);
                        form.change('pOBox', buildingNumber);
                        form.change('postalSuburb', suburb);
                        form.change('postalState', addressState);
                        form.change('postalPostCode', postcode);
                        form.change('postalCountry', country);
                    }

                    if (name === 'otherPartyIndividualSelector') {
                        form.change('otherPartyPOBox', buildingNumber);
                        form.change('otherPartyPostalSuburb', suburb);
                        form.change('otherPartyPostalState', addressState);
                        form.change('otherPartyPostalPostCode', postcode);
                        form.change('otherPartyPostalCountry', country);
                    }
                }
            });
        }
    };

    const fetchEnquiryDetails = (guid: string) => {
        var enquiryQueryParams: EnquiryDetailsQueryParams = {
            guid: guid,
        };

        retrieveEnquiryDetailsData(
            enquiryQueryParams,
            true,
            (data) => onDataRetrieved(data),
            // tslint:disable-next-line
            function (reason: any): void {
                showNotification(null, reason, 'error');
                setIsLoading(false);
            }
        );
    };

    const checkEmailTemplateCount = (areaOfLaw: string | null, form?: FormApi<Enquiry>) => {
        var emailTemplateCountQueryParams: EmailTemplateCountQueryParams = {
            emailType: EmailTemplateTypeEnum[EmailTemplateTypeEnum.NEW_ENQUIRY_RESPONSE],
            areaOfLaw: areaOfLaw === null ? '' : areaOfLaw,
        };

        retrieveEmailTemplateCount(
            emailTemplateCountQueryParams,
            true,
            (data) => onDataEmailTemplateCountRetrieved(data, form),
            // tslint:disable-next-line
            function (reason: any): void {
                showNotification(null, reason, 'error');
                setIsLoading(false);
            }
        );
    };

    const onDataEmailTemplateCountRetrieved = (data: EmailTemplateData, form?: FormApi<Enquiry>) => {
        if (data && data.templates && data.templates.emailTemplateCount
            && data.templates.emailTemplateCount.count !== undefined) {

            if (form) {
                let isSentAcknowledgementEmailOldValue = form.getFieldState('isSentAcknowledgementEmail')?.value;
                let isSentAcknowledgementEmailNewValue = false;

                if (isSentAcknowledgementEmailOldValue === true) {
                    if (data.templates.emailTemplateCount.count > 0) {
                        isSentAcknowledgementEmailNewValue = true;
                    } else {
                        isSentAcknowledgementEmailNewValue = false;
                    }
                }

                // If the value was ticked, and if we don't have any templates now, then untick it.
                form.batch(() => {
                    form.change('isSentAcknowledgementEmail', isSentAcknowledgementEmailNewValue);
                });
            }

            setState((prevState) => {
                return {
                    ...prevState,
                    hideSendAcknowledgement: data.templates.emailTemplateCount.count > 0 ? false : true
                };
            });
        }
    };

    const onDataRetrieved = (data: EnquiryData) => {

        const detail = data.enquiry.detail;

        // tslint:disable-next-line: no-console
        // console.log('Other details', detail);

        setEnquiryDetails(data.enquiry.detail);

        checkEmailTemplateCount(data.enquiry.detail.areaOfLaw);

        setState((prevState) => {
            return {
                ...prevState,
                isFormChanged: false,
                tab: detail.assignedTo && detail.assignedToName ? EnquiryFormTab.activity : EnquiryFormTab.details
            };
        });

        if (data.enquiry.actions) {
            setEnquiryActionData(data.enquiry.actions);
        }

        if (detail.matterId !== null && detail.matterId !== 0) {
            fetchMatterDetails(detail.matterId);
        }

        setKey('' + data.enquiry.detail.guid);
        setIsLoading(false);
    };

    const fetchTenantDataOnEffect = () => {
        fetchTenantData(
            false,
            (data: TenantData) => {
                handleTenantDataFetch(data);
            },
            (reason) => {
                showNotification('Failed to Fetch Tenant Data', reason, 'error');
            }
        );
    };

    const handleTenantDataFetch = (tenantsData?: TenantData) => {
        if (
            tenantsData !== null &&
            tenantsData?.tenant !== undefined &&
            tenantsData.tenant.sourceSystem !== undefined
        ) {
            setState((prevState) => {
                return {
                    ...prevState,
                    sourceSystem: tenantsData.tenant.sourceSystem
                };
            });
        }
    };

    const fetchHomeCountryOnEffect = () => {
        fetchHomeCountry(
            false,
            (data: OptionData) => {
                handleHomeCountryFetch(data);
            },
            (reason) => {
                showNotification('Failed to Fetch Home Country Settings', reason, 'error');
            }
        );
    };

    const handleHomeCountryFetch = (optionData?: OptionData) => {
        if (
            optionData !== null &&
            optionData?.options !== undefined &&
            optionData.options.homeCountry !== undefined
        ) {
            setState((prevState) => {
                return {
                    ...prevState,
                    homeCountry: optionData.options.homeCountry.stringValue === null ? 'au' : optionData.options.homeCountry.stringValue,
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    const required = (value: any) => {
        return value ? undefined : 'Required';
    };

    // tslint:disable-next-line: no-any
    const requiredDateValidator = (value: any) => {
        if (value) {
            // check for a valid date and check for sql's min date
            var date = moment(value);

            if (date.isValid() && date.year() >= 1753) {
                return undefined;
            } else {
                return 'Incorrect Date';
            }
        } else {
            return 'Required';
        }
    };

    // tslint:disable-next-line: no-any
    const dateValidator = (value: any) => {
        if (value) {
            // check for a valid date and check for sql's min date
            var date = moment(value);

            if (date.isValid() && date.year() >= 1753) {
                return undefined;
            } else {
                return 'Incorrect Date';
            }
        }

        return undefined;
    };

    // tslint:disable-next-line: no-any
    const birthDateValidator = (value: any) => {
        if (value) {
            // check for a valid date and check for sql's min date
            var date = moment(value);

            if (date.isValid() && date.year() >= 1753 && date.toDate() <= moment().toDate()) {
                return undefined;
            } else {
                return 'Incorrect Date';
            }
        }

        return undefined;
    };

    // tslint:disable-next-line
    const emailRegex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // tslint:disable-next-line: no-any
    const EmailValidateWithRequiredNew = (value: any) => {
        let error: string = '';

        if (value === undefined) {
            return value ? '' : 'One of Email/Telephone/Mobile are required'; // 'Email is required';
        } else if (value.length === 0) {
            return value ? '' : 'One of Email/Telephone/Mobile are required'; // 'Email is required';
        } else if (!emailRegex.test(value)) {
            error = 'Email is not valid';
        }
        return error;
    };

    const isTabActivity = () => {
        return state.tab === EnquiryFormTab.activity;
    };

    const isTabMatter = () => {
        return state.tab === EnquiryFormTab.matterDetails;
    };

    // tslint:disable-next-line: no-any
    const isPoBoxRequired = (values: any) => {
        if (values.postalSuburb || values.postalState || values.postalPostCode || values.postalCountry) {
            return true;
        }
        return false;
    };

    // tslint:disable-next-line: no-any
    const isSecondClientPoBoxRequired = (values: any) => {
        if (values.secondClientPostalSuburb || values.secondClientPostalState || values.secondClientPostalPostCode || values.secondClientPostalCountry) {
            return true;
        }
        return false;
    };

    // tslint:disable-next-line: no-any
    const isOtherPartyPoBoxRequired = (values: any) => {
        if (values.otherPartyPostalSuburb || values.otherPartyPostalState || values.otherPartyPostalPostCode || values.otherPartyPostalCountry) {
            return true;
        }
        return false;
    };

    // tslint:disable-next-line: no-any
    const onSubmit = (form: FormApi<Enquiry>, values: any, errors?: SubmissionErrors) => {
        setIsSubmitting(true);

        // Check if we have new Files
        const newFiles = values.files.filter((documentFile: DocumentFile) => {
            return documentFile.status === 1;
        });

        // tslint:disable-next-line: no-console
        // console.log('On Submit New Files', newFiles);

        if (newFiles.length > 0) {
            setIsLoading(true);
        }

        if (values.handleCreateAndClose) {
            createEnquiry(form, values);
        } else if (values.handleCreateAndNew) {
            createEnquiry(form, values);
        } else {

            // update enquiry details
            if (isTabMatter()) {
                if (!values.matterSelector) {
                    unlinkMatter(form, values);
                } else {
                    linkMatter(form, values);
                }
            } else if (!isTabActivity()) {

                const existingEnquiryDetails = getEnquiryDetails(false);

                const updatedValues = updatedDiff(existingEnquiryDetails, values);

                updateEnquiry(form, values, JSON.stringify(updatedValues));

            } else {

                if (values.followUpSystemAction === 'BookMeeting') {
                    saveBookMetting(form, values);
                } else {
                    saveFollowUp(form, values);
                }
            }
        }

        if (values.followUpBotUrl && values.followUpSystemAction === 'CallForm') {
            window.open(`${values.followUpBotUrl}`, '_blank');
        }
    };

    // tslint:disable-next-line: no-any
    const saveBookMetting = (form: FormApi<any>, values: AnyObject) => {

        let startDate = values.startTime === null ? moment() : moment(values.startTime);
        let endDate = values.endTime === null ? moment() : moment(values.endTime);

        let startDateValue = values.startTime === null ? moment().toDate() : moment(values.startTime).toDate();
        let endDateValue = values.endTime === null ? moment().toDate() : moment(values.endTime).toDate();

        // If UI timezone is different from location's timezone, then add the offset difference to start and end time.
        // let locationUtcOffset = values.officeLocation.baseUtcOffset;
        let selectedTimezoneUtcOffset = values.timezone.secondaryValue;
        let uiUtcOffset = new Date().getTimezoneOffset() * -1; // comparing with UI's offset because the graphql will convert to UTC based on the UI timezone

        if (selectedTimezoneUtcOffset !== uiUtcOffset && selectedTimezoneUtcOffset > 0) {
            let difference = uiUtcOffset - selectedTimezoneUtcOffset;
            startDateValue = moment(startDate).add(difference, 'minutes').toDate();
            endDateValue = moment(endDate).add(difference, 'minutes').toDate();
        }

        var isAssignedToTeam = (values.staff ? values.staff.entityType : 0) === 6;
        // book meeting
        client.mutate({
            mutation: CreateMeetingBookingTaskMutation,
            variables: {
                input: {
                    enquiryGuid: props.guid ? props.guid : null,
                    assignedTo: isAssignedToTeam ? (values.staff ? values.staff!.value : null) : (appUser ? appUser!.userId : null),
                    followUpAction: values.followUpAction,
                    followUpActionSystemName: values.followUpActionSystemName,
                    startDate: startDateValue,
                    endDate: endDateValue,
                    isAllDay: values.isAllDay,
                    location: values.officeLocation ? values.officeLocation!.label : null,
                    feeApplicable: values.feeApplicable ? values.feeApplicable : null,
                    sendConfirmation: values.isSendConfirmation,
                    staff: values.staff ? values.staff!.value : null,
                    staffEntityType: values.staff ? values.staff.entityType : null,
                    meetingNotes: values.meetingNotes ? values.meetingNotes : null,
                    timezone: values.timezone ? values.timezone!.value : null
                }
            },
        })
            // tslint:disable-next-line: no-any
            .then((results: { data: any; }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');

                        // var link = document.getElementById('resetButton');
                        // if (link !== null) {
                        //     link.click();
                        // }
                        // setIsSubmitting(false);
                        onReset(form, values);
                    } else {
                        showNotification('Failed to Save Follow up', results.data.error, 'error');
                        setIsSubmitting(false);
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Save Follow up', reason, 'error');
                setIsSubmitting(false);
            });
    };

    const saveFollowUp = (form: FormApi<Enquiry>, values: Enquiry) => {
        // Save the File
        client.mutate({
            mutation: FollowUpEnquiryMutation,
            variables: {
                input: getFollowUpEnquiryInput(values)
            },
        })
            // tslint:disable-next-line: no-any
            .then((results: { data: any; }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');

                        // var link = document.getElementById('resetButton');
                        // if (link !== null) {
                        //     link.click();
                        // }
                        // setIsSubmitting(false);
                        onReset(form, values);

                    } else {
                        showNotification('Failed to Save Follow up', results.data.error, 'error');
                        setIsSubmitting(false);
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Save Follow up', reason, 'error');
                setIsSubmitting(false);
            });
    };

    const linkMatter = (form: FormApi<Enquiry>, values: Enquiry) => {

        client.mutate({
            mutation: LinkEnquiryMutation,
            variables: {
                input: getLinkMatterEnquiryInput(values)
            },
        })
            // tslint:disable-next-line: no-any
            .then((results: { data: any; }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');

                        // var link = document.getElementById('resetButton');
                        // if (link !== null) {
                        //     link.click();
                        // }
                        // setIsSubmitting(false);

                        onReset(form, values);

                    } else {
                        showNotification('Failed to Link Matter', results.data.error, 'error');
                        setIsSubmitting(false);
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Link Matter', reason, 'error');
                setIsSubmitting(false);
            });
    };

    const createEnquiry = (form: FormApi<Enquiry>, values: Enquiry) => {
        // Save the File
        client
            .mutate({
                mutation: CreateEnquiryMutation,
                variables: {
                    input: getCreateEnquiryInput(values)
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');

                        // move into subscription success
                        if (values.handleCreateAndNew) {
                            // tslint:disable-next-line: no-console
                            // console.log('handleCreateAndNew', values.handleCreateAndNew, 'reset');
                            // tslint:disable-next-line
                            // var link = document.getElementById('resetButton');
                            // if (link !== null) {
                            //     link.click();
                            // }
                            onReset(form, values);
                        } else {
                            setIsSubmitting(false);
                            closePanel(form, values, true);
                        }
                    } else {
                        showNotification('Failed to Save Enquiry', results.data.error, 'error');
                        setIsSubmitting(false);
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Save Enquiry', reason, 'error');
                setIsSubmitting(false);
            });
    };

    const updateEnquiry = (form: FormApi<Enquiry>, values: Enquiry, updatedValues: string) => {
        // Save the File
        client
            .mutate({
                mutation: UpdateEnquiryMutation,
                variables: {
                    input: getUpdateEnquiryInput(values, updatedValues)
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');

                        // var link = document.getElementById('resetButton');
                        // if (link !== null) {
                        //     link.click();
                        // }
                        // onReset(form, values);
                        setIsSubmitting(false);
                        setState((prevState) => {
                            return {
                                ...prevState,
                                showTabsSaveAlert: false
                            };
                        });
                        // onReset(form, values);
                    } else {
                        showNotification('Failed to Save Enquiry', results.data.error, 'error');
                        setIsSubmitting(false);
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Save Enquiry', reason, 'error');
                setIsSubmitting(false);
            });
    };

    const getCreateEnquiryInput = (values: Enquiry) => {
        // Set not proceeding values
        let followUpAction = values.followUpAction;
        let followUpActionSystemName = values.followUpActionSystemName;
        let reason = values.reason;
        let reasonGuid = values.reasonGuidID;
        let outcome = values.outcome;
        let outcomeDate = /*values.outcome === 'New Client' || values.outcome === 'New Matter' ||*/ values.outcome === 'Proceeding' || values.outcome === 'Not Proceeding'
            ? values.followUpDate : values.outcomeDate;
        let outcomeNotes = values.outcomeNotes;
        let followUpDueDate = values.assignedTo ? values.followUpDueDate : null;

        if (values.isNotProceeding) {
            followUpAction = null; // If not proceeding on creation, there is no follow up action.
            followUpActionSystemName = null;
            reason = values.notProceedingReason;
            reasonGuid = values.notProceedingReasonGuid;
            outcome = 'Not Proceeding';
            outcomeDate = moment().toDate();
            outcomeNotes = '';
            // FollowUpDue in Assignment is not mandatory if not proceeding. But, if we set the value, then use that. Or else pass a default value
            followUpDueDate = followUpDueDate ? followUpDueDate : moment().toDate();
        }

        let clientDobFinal = formatDateString(values.dOB);
        let secondClientDobFinal = formatDateString(values.secondClientDOB);
        let otherPartyDobFinal = formatDateString(values.otherPartyDOB);

        return {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            gender: values.gender ? values.gender : 'Unknown',
            initialEnquiryDate: values.initialEnquiryDate,
            phone: values.phone,
            mobile: values.mobile,
            source: values.source!.label,
            office: values.office && values.office.label ? values.office.label : null,
            sourceNotes: values.sourceNotes,
            organisation: values.organisation,
            positionAtOrganisation: values.positionAtOrganisation,
            natureOfEnquiry: values.natureOfEnquiry,
            // practiceAreaGuidID: values.practiceAreaGuidID,
            areaOfLaw: values.areaOfLaw,
            internalNotes: values.internalNotes,
            files: GetFiles(values.files),

            // Assignment
            assignedTo: values.assignedTo,
            followUpDueDate: followUpDueDate,
            assignedBy: values.assignedBy,
            dateAssigned: values.dateAssigned,
            reminderOffset: values.assignedTo
                ? values.assignmentFollowUpDueReminder ? values.assignmentFollowUpDueReminder.value : null
                : null,

            // Follow Up
            followUpAction: followUpAction,
            followUpActionSystemName: followUpActionSystemName,
            followUpDate: values.followUpAction ? values.followUpDate : null, // Date in Follow Up
            outcome: outcome,
            outcomeDate: outcomeDate,
            outcomeNotes: outcomeNotes,
            reason: reason,
            reasonGuid: reasonGuid,
            followUpNotes: values.followUpNotes,
            referredToFirmName: values.referredToFirmNameInProceeding,
            referredToFirmGuid: values.referredToFirmGuidInProceeding,
            notProceedingNotes: values.notProceedingOutcomeNotesInProceeding,

            sendNotification: state.isEmailNotification,
            isKeyOpportunity: values.isKeyOpportunity,
            referrerGuid: values.referrerGuid,
            nameGuid: values.nameGuid,
            organisationGuid: values.organisationGuid,

            isUpdateContacts: values.isUpdateContacts,
            isSendEnquiryResponse: values.isSentAcknowledgementEmail,

            estimatedFees: values.estimatedFees,
            isEstimatedFeesIncludesTax: values.isEstimatedFeesIncludesTax,
            estimatedDisbursements: values.estimatedDisbursements,
            isEstimatedDisbursementsIncludesTax: values.isEstimatedDisbursementsIncludesTax,
            enquiryQuality: values.enquiryQuality,
            likelihoodToProceed: values.likelihoodToProceed,
            clientFirstName: values.clientFirstName,
            clientLastName: values.clientLastName,
            dOB: clientDobFinal,
            isMarketingConsent: values.isMarketingConsent,
            isConflictCheckPerformed: values.isConflictCheckPerformed,
            isTermsAndConditionsApproved: values.isTermsAndConditionsApproved,
            isIdentityCheckPerformed: values.isIdentityCheckPerformed,
            isPrivacyStatementProvided: values.isPrivacyStatementProvided,
            addressName: values.addressName,
            floor: values.floor,
            number: values.number,
            street: values.street,
            clientAddressStreet2: values.street2,
            suburb: values.suburb,
            state: values.state,
            postcode: values.postcode,
            country: values.country,
            pOBoxType: values.pOBox ? values.pOBoxType ? values.pOBoxType : null : null,
            pOBox: values.pOBox,
            postalSuburb: values.postalSuburb,
            postalState: values.postalState,
            postalPostCode: values.postalPostCode,
            postalCountry: values.postalCountry,
            isNoConflict: values.isNoConflict,
            clientRelationshipToEnquirer: values.clientRelationshipToEnquirer,
            otherPartyFirstName: values.otherPartyFirstName,
            otherPartyLastName: values.otherPartyLastName,
            otherPartyNotes: values.otherPartyNotes,
            otherPartyEmail: values.otherPartyEmail,
            otherPartyPhone: values.otherPartyPhone,
            otherPartyMobile: values.otherPartyMobile,
            otherPartyOrganisation: values.otherPartyOrganisation,
            enquiryNotes: values.enquiryNotes,
            otherPartyDOB: otherPartyDobFinal,
            otherPartyAddressName: values.otherPartyAddressName,
            otherPartyFloor: values.otherPartyFloor,
            otherPartyNumber: values.otherPartyNumber,
            otherPartyStreet: values.otherPartyStreet,
            otherPartyStreet2: values.otherPartyStreet2,
            otherPartySuburb: values.otherPartySuburb,
            otherPartyState: values.otherPartyState,
            otherPartyPostcode: values.otherPartyPostcode,
            otherPartyCountry: values.otherPartyCountry,

            otherPartyPOBoxType: values.otherPartyPOBoxType,
            otherPartyPOBox: values.otherPartyPOBox,
            otherPartyPostalSuburb: values.otherPartyPostalSuburb,
            otherPartyPostalState: values.otherPartyPostalState,
            otherPartyPostalPostCode: values.otherPartyPostalPostCode,
            otherPartyPostalCountry: values.otherPartyPostalCountry,
            otherPartyGuid: values.otherPartyNameGuid,
            otherPartyOrganisationGuid: values.otherPartyOrganisationGuid,

            isRiskAssessed: values.isRiskAssessed,
            riskLevel: values.isRiskAssessed ? values.riskLevelSelector ? values.riskLevelSelector.label : null : null,
            riskNotes: values.isRiskAssessed ? values.riskNotes : null,

            secondClientFirstName: values.secondClientFirstName,
            secondClientLastName: values.secondClientLastName,
            secondClientRelationshipToEnquirer: values.secondClientRelationshipToEnquirer,
            secondClientGender: values.secondClientGender ?? 'Unknown',
            secondClientSuburb: values.secondClientSuburb,
            secondClientState: values.secondClientState,
            secondClientPostCode: values.secondClientPostCode,
            secondClientIsMarketingConsent: values.secondClientIsMarketingConsent,
            secondClientIsTermsAndConditionsApproved: values.secondClientIsTermsAndConditionsApproved,
            secondClientIsPrivacyStatementProvided: values.secondClientIsPrivacyStatementProvided,
            secondClientIsConflictCheckPerformed: values.secondClientIsConflictCheckPerformed,
            secondClientIsIdentityCheckPerformed: values.secondClientIsIdentityCheckPerformed,
            secondClientIsNoConflict: values.secondClientIsNoConflict,
            secondClientCountry: values.secondClientCountry,
            secondClientAddressName: values.secondClientAddressName,
            secondClientFloor: values.secondClientFloor,
            secondClientNumber: values.secondClientNumber,
            secondClientStreet1: values.secondClientStreet1,
            secondClientStreet2: values.secondClientStreet2,
            secondClientDOB: secondClientDobFinal,
            secondClientPOBoxType: values.secondClientPOBoxType,
            secondClientPOBox: values.secondClientPOBox,
            secondClientPostalSuburb: values.secondClientPostalSuburb,
            secondClientPostalState: values.secondClientPostalState,
            secondClientPostalPostCode: values.secondClientPostalPostCode,
            secondClientPostalCountry: values.secondClientPostalCountry,

            responseText: values.responseText,

            // events and campaign
            eventAndCampaignGuid:                      values.eventAndCampaignSelector ? values.eventAndCampaignSelector.value : null,
            eventAndCampaignNotes:                     values.eventsAndCampaignNotes,

            isNameFromDataSource:                      values.individualSelector?.isFromDataSource ?? null,
            // tslint:disable-next-line: max-line-length
            isOrganisationFromDataSource:              values.organisationSelector?.isFromDataSource ?? (values.individualSelector?.organisationName !== null ? values.individualSelector?.isFromDataSource : null),
            isOtherSideFromDataSource:                 values.otherPartyIndividualSelector?.isFromDataSource ?? null,
            isSecondClientFromDataSource:              values.secondClientIndividualSelector?.isFromDataSource ?? null,

            title: values.title,
            clientTitle: values.clientTitle,
            secondClientTitle: values.secondClientTitle,
            otherPartyTitle: values.otherPartyTitle,
            clientGuid: values.clientGuid,
            clientEmail: values.clientEmail,
            clientPhone: values.clientPhone,
            clientMobile: values.clientMobile,
            secondClientGuid: values.secondClientGuid,
            secondClientEmail: values.secondClientEmail,
            secondClientPhone: values.secondClientPhone,
            secondClientMobile: values.secondClientMobile,
        };
    };

    const getUpdateEnquiryInput = (values: Enquiry, updatedValues: string) => {

        let clientDobFinal = formatDateString(values.dOB);
        let secondClientDobFinal = formatDateString(values.secondClientDOB);
        let otherPartyDobFinal = formatDateString(values.otherPartyDOB);

        return {
            guid: values.guid,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            gender: values.gender ? values.gender : 'Unknown',
            initialEnquiryDate: values.initialEnquiryDate,
            phone: values.phone,
            mobile: values.mobile,
            source: values.source!.label,
            office: values.office && values.office.label ? values.office.label : null,
            sourceNotes: values.sourceNotes,
            organisation: values.organisation,
            positionAtOrganisation: values.positionAtOrganisation,
            natureOfEnquiry: values.natureOfEnquiry,
            areaOfLaw: values.areaOfLaw,
            internalNotes: values.internalNotes,
            files: GetFiles(values.files),

            assignedTo: values.assignedTo,
            followUpDueDate: values.assignedTo ? values.followUpDueDate : null,
            assignedBy: values.assignedBy,
            dateAssigned: values.dateAssigned,
            reminderOffset: values.assignedTo
                ? values.assignmentFollowUpDueReminder ? values.assignmentFollowUpDueReminder.value : null
                : null,

            followUpAction: null, // values.followUpAction,
            followUpActionSystemName: null,
            followUpDate: null, // values.followUpAction ? values.followUpDate : null,
            outcome: null, // values.outcome,
            outcomeDate: null, // values.outcome === 'New Client' || values.outcome === 'Not Proceeding' ? values.followUpDate : values.outcomeDate,
            outcomeNotes: null,
            reason: null, // values.reason,
            reasonGuid: null, // values.reasonGuidID,
            followUpNotes: null, // values.followUpNotes,

            updatedValues: updatedValues,
            sendNotification: state.isEmailNotification,
            isKeyOpportunity: values.isKeyOpportunity,
            referrerGuid: values.referrerGuid,
            nameGuid: values.nameGuid,
            organisationGuid: values.organisationGuid,

            isUpdateContacts: values.isUpdateContacts,
            isSendEnquiryResponse: values.isSentAcknowledgementEmail,

            estimatedFees: values.estimatedFees,
            isEstimatedFeesIncludesTax: values.isEstimatedFeesIncludesTax,
            estimatedDisbursements: values.estimatedDisbursements,
            isEstimatedDisbursementsIncludesTax: values.isEstimatedDisbursementsIncludesTax,
            enquiryQuality: values.enquiryQuality,
            likelihoodToProceed: values.likelihoodToProceed,
            clientFirstName: values.clientFirstName,
            clientLastName: values.clientLastName,
            dOB: clientDobFinal,
            isMarketingConsent: values.isMarketingConsent,
            isConflictCheckPerformed: values.isConflictCheckPerformed,
            isTermsAndConditionsApproved: values.isTermsAndConditionsApproved,
            isIdentityCheckPerformed: values.isIdentityCheckPerformed,
            isPrivacyStatementProvided: values.isPrivacyStatementProvided,
            addressName: values.addressName,
            floor: values.floor,
            number: values.number,
            street: values.street,
            clientAddressStreet2: values.street2,
            suburb: values.suburb,
            state: values.state,
            postcode: values.postcode,
            country: values.country,
            pOBoxType: values.pOBox ? values.pOBoxType ? values.pOBoxType : null : null,
            pOBox: values.pOBox,
            postalSuburb: values.postalSuburb,
            postalState: values.postalState,
            postalPostCode: values.postalPostCode,
            postalCountry: values.postalCountry,
            isNoConflict: values.isNoConflict,
            clientRelationshipToEnquirer: values.clientRelationshipToEnquirer,
            otherPartyFirstName: values.otherPartyFirstName,
            otherPartyLastName: values.otherPartyLastName,
            otherPartyNotes: values.otherPartyNotes,
            otherPartyEmail: values.otherPartyEmail,
            otherPartyPhone: values.otherPartyPhone,
            otherPartyMobile: values.otherPartyMobile,
            otherPartyOrganisation: values.otherPartyOrganisation,
            enquiryNotes: values.enquiryNotes,
            otherPartyDOB: otherPartyDobFinal,
            otherPartyAddressName: values.otherPartyAddressName,
            otherPartyFloor: values.otherPartyFloor,
            otherPartyNumber: values.otherPartyNumber,
            otherPartyStreet: values.otherPartyStreet,
            otherPartyStreet2: values.otherPartyStreet2,
            otherPartySuburb: values.otherPartySuburb,
            otherPartyState: values.otherPartyState,
            otherPartyPostcode: values.otherPartyPostcode,
            otherPartyCountry: values.otherPartyCountry,

            otherPartyPOBoxType: values.otherPartyPOBoxType,
            otherPartyPOBox: values.otherPartyPOBox,
            otherPartyPostalSuburb: values.otherPartyPostalSuburb,
            otherPartyPostalState: values.otherPartyPostalState,
            otherPartyPostalPostCode: values.otherPartyPostalPostCode,
            otherPartyPostalCountry: values.otherPartyPostalCountry,
            otherPartyGuid: values.otherPartyNameGuid,
            otherPartyOrganisationGuid: values.otherPartyOrganisationGuid,

            isRiskAssessed: values.isRiskAssessed,
            riskLevel: values.isRiskAssessed ? values.riskLevelSelector ? values.riskLevelSelector.label : null : null,
            riskNotes: values.isRiskAssessed ? values.riskNotes : null,

            secondClientFirstName: values.secondClientFirstName,
            secondClientLastName: values.secondClientLastName,
            secondClientRelationshipToEnquirer: values.secondClientRelationshipToEnquirer,
            secondClientGender: values.secondClientGender ?? 'Unknown',
            secondClientSuburb: values.secondClientSuburb,
            secondClientState: values.secondClientState,
            secondClientPostCode: values.secondClientPostCode,
            secondClientIsMarketingConsent: values.secondClientIsMarketingConsent,
            secondClientIsTermsAndConditionsApproved: values.secondClientIsTermsAndConditionsApproved,
            secondClientIsPrivacyStatementProvided: values.secondClientIsPrivacyStatementProvided,
            secondClientIsConflictCheckPerformed: values.secondClientIsConflictCheckPerformed,
            secondClientIsIdentityCheckPerformed: values.secondClientIsIdentityCheckPerformed,
            secondClientIsNoConflict: values.secondClientIsNoConflict,
            secondClientCountry: values.secondClientCountry,
            secondClientAddressName: values.secondClientAddressName,
            secondClientFloor: values.secondClientFloor,
            secondClientNumber: values.secondClientNumber,
            secondClientStreet1: values.secondClientStreet1,
            secondClientStreet2: values.secondClientStreet2,
            secondClientDOB: secondClientDobFinal,
            secondClientPOBoxType: values.secondClientPOBoxType,
            secondClientPOBox: values.secondClientPOBox,
            secondClientPostalSuburb: values.secondClientPostalSuburb,
            secondClientPostalState: values.secondClientPostalState,
            secondClientPostalPostCode: values.secondClientPostalPostCode,
            secondClientPostalCountry: values.secondClientPostalCountry,

            responseText: values.responseText,

            // events and campaign
            eventAndCampaignGuid:                      values.eventAndCampaignSelector ? values.eventAndCampaignSelector.value : null,
            eventAndCampaignNotes:                     values.eventsAndCampaignNotes,

            isNameFromDataSource:                      values.individualSelector?.isFromDataSource ?? null,
            isOrganisationFromDataSource:              values.organisationSelector?.isFromDataSource ?? null,

            isOtherSideFromDataSource:                 values.otherPartyIndividualSelector?.isFromDataSource ?? null,
            isSecondClientFromDataSource:              values.secondClientIndividualSelector?.isFromDataSource ?? null,

            title: values.title,
            clientTitle: values.clientTitle,
            secondClientTitle: values.secondClientTitle,
            otherPartyTitle: values.otherPartyTitle,
            clientGuid: values.clientGuid,
            clientEmail: values.clientEmail,
            clientPhone: values.clientPhone,
            clientMobile: values.clientMobile,
            secondClientGuid: values.secondClientGuid,
            secondClientEmail: values.secondClientEmail,
            secondClientPhone: values.secondClientPhone,
            secondClientMobile: values.secondClientMobile,
        };
    };

    const onSelectionChange = (form: FormApi<Enquiry>, selection: IAreaOfLawAutoCompleteItem, name: string) => {
        if (name === 'areaOfLaw') {
            form.batch(() => {
                form.change('practiceAreaGuidID', selection && selection.value ? selection.value : '');
                form.change('areaOfLaw', selection && selection.label ? selection.label : '');

                if (!props.guid) {
                    form.change('estimatedFees', selection && selection.estimatedFees ? selection.estimatedFees : null);
                    form.change('estimatedDisbursements', selection && selection.estimatedDisbursements ? selection.estimatedDisbursements : null);
                    form.change('isEstimatedFeesIncludesTax', selection && selection.isEstimatedFeesIncludesTax ? selection.isEstimatedFeesIncludesTax : false);
                    form.change('isEstimatedDisbursementsIncludesTax', selection && selection.isEstimatedDisbursementsIncludesTax 
                        ? selection.isEstimatedDisbursementsIncludesTax 
                        : false);
                }
            });

            if (selection && selection.label) {
                checkEmailTemplateCount(selection.label, form);
            }
        }
    };
    
    const onFollowUpChange = (
        form: FormApi<Enquiry>,
        // tslint:disable-next-line: no-any
        values: any,
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean,
        value: string,
        systemAction: string,
        url: string | null,
    ) => {
        const followUpActionValue = event && event.currentTarget && event.currentTarget.value ? event.currentTarget.value : '';
        const followUpActionSystemName = value ? value : '';
        const followUpSystemAction = systemAction ? systemAction : '';

        form.batch(() => {
            form.change('followUpAction', followUpActionValue);
            form.change('followUpActionSystemName', followUpActionSystemName);
            form.change('followUpSystemAction', followUpSystemAction);
            form.change('followUpBotUrl', url);

            if (systemAction && systemAction.toLowerCase() === 'internalnotes') {
                if (values.hasFollowUpNotesMentions === true && values.outcomeDateChanged === false) {
                    form.change('updateFollowUpDueForInternalNote', false);
                } else {
                    form.change('updateFollowUpDueForInternalNote', true);
                }
            }

            if (followUpSystemAction === 'CreateEmail') {
                setIsSubmitting(true);
            } else {
                setIsSubmitting(false);
                form.change('emailTemplate', null);
            }

            if (followUpActionValue && !values.outcome) {
                form.change('outcome', followUpActionValue ? 'More Follow Up' : '');
            }
        });
    };

    const onEmailTemplateChange = (form: FormApi<Enquiry>, selection: IAutoCompleteItem) => {
        form.change('emailTemplate', selection);

        if (selection && selection.value) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isSendEmailDialogOpen: true
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    const onOutcomeChange = (form: FormApi<Enquiry>, values: any, event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const outcome = event && event.currentTarget && event.currentTarget.value ? event.currentTarget.value : '';

        form.batch(() => {
            form.change('outcome', outcome);

            if (outcome !== 'Not Proceeding') {
                form.change('reason', null);
                form.change('reasonGuidID', null);
                form.change('isShowNotProceedingReferrer', false);
            }
        });
    };

    const stringTruncate = (value: string, limit: number) => {
        if (value.length > limit) {
            return `${value.substring(0, limit)}...`;
        }
        return value;
    };

    const getInternalNotes = (value: string, limit: number) => {
        if (value.length >= limit) {
            return (
                <LightTooltip
                    title={value}
                    placement="bottom-start"
                    arrow={true}
                >
                    <p className={classes.internalNotes}>
                        {stringTruncate(value, (limit - 3))}
                    </p>
                </LightTooltip>
            );
        } else {
            return (
                <p className={classes.internalNotes}>
                    {value}
                </p>
            );
        }
    };

    // tslint:disable-next-line: no-any max-line-length
    const onDropDownSelect = (form: FormApi<Enquiry>, values: AnyObject, input: FieldInputProps<any, HTMLElement>, selection: IndividualNameSummary | IDownShiftItem | any, name: string) => {
        // input.OnChange(selection.value);
        if (name === 'assignedTo') {
            form.batch(() => {
                form.change('assignedTo', selection.value);
                form.change('assignedToName', selection.label);

                if (selection.value === undefined) {
                    form.change('isNotProceeding', false);
                    form.change('notProceedingReason', null);
                    form.change('notProceedingReasonGuid', null);
                } else {
                    if (values.followUpDueDate === undefined || values.followUpDueDate === null) {
                        // only set assignment's follow up due value when we select assigned to value
                        let followUpDueValue = getFollowUpDueDefaultValue();
                        form.change('followUpDueDate', followUpDueValue);
                    }
                }
            });
        } else if (name === 'assignedBy') {
            form.batch(() => {
                form.change('assignedBy', selection.value);
                form.change('assignedByName', selection.label);
            });
        } else if (name === 'individualSelector') {
            form.batch(() => {
                // Update required fields
                if (selection) {
                    form.change('firstName', selection && selection.firstName ? selection.firstName : '');
                    form.change('lastName', selection && selection.lastName ? selection.lastName : '');
                    form.change('nameGuid', selection && selection.guidID ? selection.guidID : null);
                    form.change('email', selection && selection.emailAddress ? selection.emailAddress : '');
                    form.change('phone', selection && selection.phone ? selection.phone : '');
                    form.change('mobile', selection && selection.mobile ? selection.mobile : '');
                    form.change('gender', selection && selection.gender ? selection.gender : null);
                    form.change('title', selection && selection.title ? selection.title : 'None');

                    if (selection && selection.organisationName && selection.organisationGuid) {
                        // Only change if the selected individual has an organisation.
                        const organisationSelectorValue: OrganisationIAutoCompleteItem = {
                            label: selection.organisationName,
                            value: selection.organisationGuid,
                            isFromDataSource: selection.isFromDataSource
                        } as OrganisationIAutoCompleteItem;

                        // Do not switch if the organisation is selected through Last Name Search. We disable organisation field
                        // form.change('isOrganisationSwitched', true);
                        form.change('organisationSelector', organisationSelectorValue);
                        form.change('organisation', selection && selection.organisationName ? selection.organisationName : null);
                        form.change('organisationGuid', selection && selection.organisationGuid ? selection.organisationGuid : null);
                    }

                    if (values.isNameSwitched) {
                        form.change('isNameSwitched', !values.isNameSwitched);
                    }
                }
            });
        } else if (name === 'clientIndividualSelector') {
            form.batch(() => {
                // Update required fields
                if (selection) {
                    form.change('clientFirstName', selection && selection.firstName ? selection.firstName : '');
                    form.change('clientLastName', selection && selection.lastName ? selection.lastName : '');
                    form.change('clientGuid', selection && selection.guidID ? selection.guidID : null);
                    form.change('clientEmail', selection && selection.emailAddress ? selection.emailAddress : '');
                    form.change('clientPhone', selection && selection.phone ? selection.phone : '');
                    form.change('clientMobile', selection && selection.mobile ? selection.mobile : '');
                    form.change('gender', selection && selection.gender ? selection.gender : null);
                    form.change('clientTitle', selection && selection.title ? selection.title : 'None');

                    if (values.isClientNameSwitched) {
                        form.change('isClientNameSwitched', !values.isClientNameSwitched);
                    }
                }
            });
        } else if (name === 'secondClientIndividualSelector') {
            form.batch(() => {
                // Update required fields
                if (selection) {
                    form.change('secondClientFirstName', selection && selection.firstName ? selection.firstName : '');
                    form.change('secondClientLastName', selection && selection.lastName ? selection.lastName : '');
                    form.change('secondClientEmail', selection && selection.emailAddress ? selection.emailAddress : '');
                    form.change('secondClientPhone', selection && selection.phone ? selection.phone : '');
                    form.change('secondClientMobile', selection && selection.mobile ? selection.mobile : '');
                    form.change('secondClientGender', selection && selection.gender ? selection.gender : null);
                    form.change('secondClientTitle', selection && selection.title ? selection.title : 'None');
                    form.change('secondClientGuid', selection && selection.guidID ? selection.guidID : null);
                    
                    if (values.isSecondClientNameSwitched) {
                        form.change('isSecondClientNameSwitched', !values.isSecondClientNameSwitched);
                    }
                }
            });
        } else if (name === 'organisationSelector') {
            form.batch(() => {
                form.change('organisation', selection && selection.label ? selection.label : '');
                form.change('organisationGuid', selection && selection.value ? selection.value : null);

                if (values.isOrganisationSwitched) {
                    form.change('isOrganisationSwitched', !values.isOrganisationSwitched);
                }
            });
        } else if (name === 'otherPartyIndividualSelector') {
            form.batch(() => {
                // Update required fields
                if (selection) {
                    form.change('otherPartyFirstName', selection && selection.firstName ? selection.firstName : '');
                    form.change('otherPartyLastName', selection && selection.lastName ? selection.lastName : '');
                    form.change('otherPartyNameGuid', selection && selection.guidID ? selection.guidID : null);
                    form.change('otherPartyEmail', selection && selection.emailAddress ? selection.emailAddress : '');
                    form.change('otherPartyPhone', selection && selection.phone ? selection.phone : '');
                    form.change('otherPartyMobile', selection && selection.mobile ? selection.mobile : '');

                    form.change('otherPartyAddressName', selection && selection.addressName ? selection.addressName : '');
                    form.change('otherPartyFloor', selection && selection.floor ? selection.floor : '');
                    form.change('otherPartyNumber', selection && selection.number ? selection.number : '');
                    form.change('otherPartyStreet', selection && selection.street ? selection.street : '');
                    form.change('otherPartySuburb', selection && selection.city ? selection.city : '');
                    form.change('otherPartyState', selection && selection.state ? selection.state : '');
                    form.change('otherPartyPostcode', selection && selection.postCode ? selection.postCode : '');
                    form.change('otherPartyCountry', selection && selection.country ? selection.country : '');
                    form.change('otherPartyTitle', selection && selection.title ? selection.title : 'None');

                    if (selection && selection.organisationName && selection.organisationGuid) {
                        // Do not switch if the organisation is selected through Last Name Search. We disable organisation field
                        // form.change('isOrganisationSwitched', true);
                        form.change('otherPartyOrganisation', selection && selection.organisationName ? selection.organisationName : null);
                        form.change('otherPartyOrganisationGuid', selection && selection.organisationGuid ? selection.organisationGuid : null);
                    }

                    if (values.isOtherPartyNameSwitched) {
                        form.change('isOtherPartyNameSwitched', !values.isOtherPartyNameSwitched);
                    }
                }
            });
        } else if (name === 'referredToFirmSelector') {
            form.batch(() => {
                const referredToFirmSelectorValue: IAutoCompleteItem = {
                    label: selection && selection.label ? selection.label : '',
                    value: selection && selection.value ? selection.value : null
                };

                form.change('referredToFirmSelector', referredToFirmSelectorValue);
                form.change('referredToFirmName', selection && selection.label ? selection.label : '');
                form.change('referredToFirmGuid', selection && selection.value ? selection.value : null);
            });
        } else if (name === 'referredToFirmSelectorInProceeding') {
            form.batch(() => {
                const referredToFirmSelectorInProceedingValue: IAutoCompleteItem = {
                    label: selection && selection.label ? selection.label : '',
                    value: selection && selection.value ? selection.value : null
                };

                form.change('referredToFirmSelectorInProceeding', referredToFirmSelectorInProceedingValue);
                form.change('referredToFirmNameInProceeding', selection && selection.label ? selection.label : '');
                form.change('referredToFirmGuidInProceeding', selection && selection.value ? selection.value : null);
            });
        }
    };

    const onSwitch = (form: FormApi<Enquiry>, values: AnyObject, name: string) => {
        if (name === 'lastName') {
            form.batch(() => {
                form.change('individualSelector', null); // clear the selector value

                if (!values.isNameSwitched) {
                    form.change('nameGuid', null);
                }

                form.change('isNameSwitched', !values.isNameSwitched);
            });
        } else if (name === 'organisation') {
            form.batch(() => {
                form.change('isOrganisationSwitched', !values.isOrganisationSwitched);
                form.change('organisationSelector', null);
                form.change('organisationGuid', null);
                form.change('organisation', '');
            });
        } else if (name === 'referredToFirm') {
            form.batch(() => {
                form.change('isNotProceedingReferrerSwitched', !values.isNotProceedingReferrerSwitched);
                // form.change('isShowNotProceedingReferrer', !values.isShowNotProceedingReferrer);
                form.change('referredToFirmSelector', null);
                form.change('referredToFirmGuid', null);
                form.change('referredToFirmName', '');
            });
        } else if (name === 'referredToFirmInProceeding') {
            form.batch(() => {
                form.change('isNotProceedingReferrerSwitchedInProceeding', !values.isNotProceedingReferrerSwitchedInProceeding);
                // form.change('isShowNotProceedingReferrer', !values.isShowNotProceedingReferrer);
                form.change('referredToFirmSelectorInProceeding', null);
                form.change('referredToFirmGuidInProceeding', null);
                form.change('referredToFirmNameInProceeding', '');
            });
        } else if (name === 'otherPartyLastName') {
            form.batch(() => {
                form.change('otherPartyIndividualSelector', null); // clear the selector value

                if (!values.isOtherPartyNameSwitched) {
                    form.change('otherPartyNameGuid', null);
                    form.change('otherPartyOrganisationGuid', null);
                }

                form.change('isOtherPartyNameSwitched', !values.isOtherPartyNameSwitched);
            });
        } else if (name === 'clientLastName') {
            form.batch(() => {
                form.change('clientIndividualSelector', null); // clear the selector value

                if (!values.isClientNameSwitched) {
                    form.change('clientGuid', null);
                }

                form.change('isClientNameSwitched', !values.isClientNameSwitched);
            });
        } else if (name === 'secondClientLastName') {
            form.batch(() => {
                form.change('secondClientIndividualSelector', null); // clear the selector value

                if (!values.isSecondClientNameSwitched) {
                    form.change('secondClientGuid', null);
                }

                form.change('isSecondClientNameSwitched', !values.isSecondClientNameSwitched);
            });
        }
    };

    const onReset = (form: FormApi<Enquiry>, values: AnyObject) => {
        // If we are using keepDirtyOnReinitialize, then the form.Reset() will not work.
        // So we have change this to false, reset and then set it to true
        form.setConfig('keepDirtyOnReinitialize', false);

        form.batch(() => {
            form.reset();
            form.initialize(initialValues);

            // Do this to reset touched prop
            form.resetFieldState('firstName');
            form.resetFieldState('lastName');
            form.resetFieldState('email');
            form.resetFieldState('gender');
            form.resetFieldState('initialEnquiryDate');
            form.resetFieldState('phone');
            form.resetFieldState('mobile');
            form.resetFieldState('source');
            form.resetFieldState('office');
            form.resetFieldState('sourceNotes');
            form.resetFieldState('organisation');
            form.resetFieldState('positionAtOrganisation');
            form.resetFieldState('areaOfLaw');
            form.resetFieldState('natureOfEnquiry');
            form.resetFieldState('internalNotes');
            form.resetFieldState('files');
            form.resetFieldState('assignedTo');
            form.resetFieldState('assignedBy');
            form.resetFieldState('dateAssigned');
            form.resetFieldState('assignmentFollowUpDueReminder');

            // Other details
            form.resetFieldState('estimatedFees');
            form.resetFieldState('isEstimatedFeesIncludesTax');
            form.resetFieldState('estimatedDisbursements');
            form.resetFieldState('isEstimatedDisbursementsIncludesTax');
            form.resetFieldState('enquiryQuality');
            form.resetFieldState('likelihoodToProceed');
            form.resetFieldState('clientFirstName');
            form.resetFieldState('clientLastName');
            form.resetFieldState('dOB');
            form.resetFieldState('isMarketingConsent');
            form.resetFieldState('isConflictCheckPerformed');
            form.resetFieldState('isTermsAndConditionsApproved');
            form.resetFieldState('isIdentityCheckPerformed');
            form.resetFieldState('isPrivacyStatementProvided');
            form.resetFieldState('addressName');
            form.resetFieldState('floor');
            form.resetFieldState('number');
            form.resetFieldState('street');
            form.resetFieldState('street2');
            form.resetFieldState('suburb');
            form.resetFieldState('state');
            form.resetFieldState('postcode');
            form.resetFieldState('country');
            form.resetFieldState('pOBoxType');
            form.resetFieldState('pOBox');
            form.resetFieldState('postalSuburb');
            form.resetFieldState('postalState');
            form.resetFieldState('postalPostCode');
            form.resetFieldState('postalCountry');
            form.resetFieldState('isNoConflict');

            form.resetFieldState('otherPartyFirstName');
            form.resetFieldState('otherPartyLastName');
            form.resetFieldState('otherPartyNotes');
            form.resetFieldState('enquiryNotes');

            form.resetFieldState('otherPartyDOB');
            form.resetFieldState('otherPartyAddressName');
            form.resetFieldState('otherPartyFloor');
            form.resetFieldState('otherPartyNumber');
            form.resetFieldState('otherPartyStreet');
            form.resetFieldState('otherPartyStreet2');
            form.resetFieldState('otherPartySuburb');
            form.resetFieldState('otherPartyState');
            form.resetFieldState('otherPartyPostcode');
            form.resetFieldState('otherPartyCountry');

            form.resetFieldState('otherPartyPOBoxType');
            form.resetFieldState('otherPartyPOBox');
            form.resetFieldState('otherPartyPostalSuburb');
            form.resetFieldState('otherPartyPostalState');
            form.resetFieldState('otherPartyPostalPostCode');
            form.resetFieldState('otherPartyPostalCountry');

            form.change('otherPartyNameGuid', null);
            form.change('otherPartyOrganisationGuid', null);

            // We are not resetting here because, in few conditions, these fields does not exists in UI and that will throw errors.

            form.change('followUpAction', 'Emailed');

            form.change('followUpDueDate', null);
            form.change('followUpDate', null);
            form.change('outcome', null);
            form.change('outcomeDate', null);
            form.change('outcomeNotes', '');
            form.change('reason', null);
            form.change('followUpNotes', null);
            form.change('followUpDueReminder', null);

            form.change('followUpActionSystemName', '');
            form.change('followUpSystemAction', '');
            form.change('isContactDetailsChanged', false);
            form.change('isUpdateContacts', false);
            form.change('updateFollowUpDueForInternalNote', true);
            form.change('outcomeDateChanged', false);

            form.change('isNotProceedingReferrerSwitched', false);
            form.change('referredToFirmGuid', null);
            form.change('referredToFirmName', null);
            form.change('notProceedingOutcomeNotes', null);

            form.change('isNotProceedingReferrerSwitchedInProceeding', false);
            form.change('referredToFirmGuidInProceeding', null);
            form.change('referredToFirmNameInProceeding', null);
            form.change('notProceedingOutcomeNotesInProceeding', null);
            form.change('isSavedDirectly', false);

            form.change('riskNotes', null);
            form.change('riskLevelSelector', null);
            form.change('isRiskAssessed', null);

            // client 2 details
            form.change('secondClientFirstName', null);
            form.change('secondClientLastName', null);
            form.change('secondClientRelationshipToEnquirer', null);
            form.change('secondClientGender', 'Unknown');
            form.change('secondClientSuburb', null);
            form.change('secondClientState', null);
            form.change('secondClientPostCode', null);
            form.change('secondClientIsMarketingConsent', false);
            form.change('secondClientIsTermsAndConditionsApproved', false);
            form.change('secondClientIsPrivacyStatementProvided', false);
            form.change('secondClientIsConflictCheckPerformed', false);
            form.change('secondClientIsIdentityCheckPerformed', false);
            form.change('secondClientIsNoConflict', false);
            form.change('secondClientCountry', null);
            form.change('secondClientAddressName', null);
            form.change('secondClientFloor', null);
            form.change('secondClientNumber', null);
            form.change('secondClientStreet1', null);
            form.change('secondClientStreet2', null);
            form.change('secondClientDOB', null);
            form.change('secondClientPOBoxType', null);
            form.change('secondClientPOBox', null);
            form.change('secondClientPostalSuburb', null);
            form.change('secondClientPostalState', null);
            form.change('secondClientPostalPostCode', null);
            form.change('secondClientPostalCountry', null);

            if (UserFeature.HasAccess(UserFeatureEnum.hasKeyOpportunity)) {
                form.change('isKeyOpportunity', props.isKeyOpportunity);
            }

            form.change('isSentAcknowledgementEmail', false);

            if (!props.guid) {
                form.resetFieldState('isNotProceeding');
                form.resetFieldState('notProceedingReason');
            }

            form.change('hasFollowUpNotesMentions', false);
        });

        form.setConfig('keepDirtyOnReinitialize', true);

        setState((prevState) => {
            return {
                ...prevState,
                showDiscard: false,
                showTabsSaveAlert: false,
                isFormChanged: false,
                isKeyOpportunity: props.isKeyOpportunity,
                tab: EnquiryFormTab.details,
                isContactDetailsPanelExpand: isCreateForm,
                isEnquiryDetailsPanelExpand: isCreateForm,
                isAssignPanelExpand: false,
                isBookMeetingFromHistoryActivity: false
            };
        });

        setIsSubmitting(false);
    };

    const onHistoryCreateClient = (name: string) => (isCreate: boolean) => {
        setState((prevState) => {
            return {
                ...prevState,
                [name]: isCreate
            };
        });
    };

    // tslint:disable-next-line: no-any
    const closePanel = (form: FormApi<Enquiry>, values: any, isForceClose: boolean) => {
        if (state.isFormChanged === true && !isForceClose) {
            // If required pass the values from FormSpy and compare using updateDiff as shown above
            // form.change('showDiscard', true); // TODO: Remove if not used.  Why this?
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: true,
                    showTabsSaveAlert: false
                };
            });

        } else {
            onEnquiryFormClosed();
        }
    };

    const onActionButtonClick = (form: FormApi<Enquiry>, handleCreateAndClose: boolean, handleCreateAndNew: boolean, handleUpdate: boolean) => {
        setState((prevState) => {
            return {
                ...prevState,
                isFormSubmit: true
            };
        });

        form.batch(() => {
            form.change('handleCreateAndClose', handleCreateAndClose);
            form.change('handleCreateAndNew', handleCreateAndNew);
            form.change('handleUpdate', handleUpdate);
        });
    };

    const onExpandPanel = (panel: string) => (event: React.ChangeEvent<{}>, expanded: boolean) => {
        setState((prevState) => {
            return {
                ...prevState,
                [panel]: expanded
            };
        });
    };

    const openErrorPanel = (errors: ValidationErrors) => {
        if (Object.keys(errors).length) {
            setState((prevState) => {

                let isContactDetailsPanelExpand = false;
                let isEnquiryDetailsPanelExpand = false;
                let isAssignPanelExpand = false;
                let isOtherDetailsPanelExpand = false;
                let isOther2DetailsPanelExpand = false;
                let isClient2PostalAddressPanelExpand = false;
                let isPostalAddressPanelExpand = false;

                if (errors.hasOwnProperty('firstName')
                    || errors.hasOwnProperty('lastName')
                    || errors.hasOwnProperty('email')
                    || errors.hasOwnProperty('mobile')
                    || errors.hasOwnProperty('phone')
                    || errors.hasOwnProperty('source')) {
                    isContactDetailsPanelExpand = true;
                }
                if (prevState.isContactDetailsPanelExpand) {
                    isContactDetailsPanelExpand = true;
                }

                if (errors.hasOwnProperty('areaOfLaw')) {
                    isEnquiryDetailsPanelExpand = true;
                }

                if (prevState.isEnquiryDetailsPanelExpand) {
                    isEnquiryDetailsPanelExpand = true;
                }

                if (errors.hasOwnProperty('followUpDueDate')) {
                    isAssignPanelExpand = true;
                }

                if (prevState.isAssignPanelExpand) {
                    isAssignPanelExpand = true;
                }

                if (errors.hasOwnProperty('pOBox')) {
                    isOtherDetailsPanelExpand = true;
                    isPostalAddressPanelExpand = true;
                }

                if (errors.hasOwnProperty('secondClientPOBox')) {
                    isOther2DetailsPanelExpand = true;
                    isClient2PostalAddressPanelExpand = true;
                }

                if (prevState.isOtherDetailsPanelExpand) {
                    isOtherDetailsPanelExpand = true;
                }

                if (prevState.isPostalAddressPanelExpand) {
                    isPostalAddressPanelExpand = true;
                }

                return {
                    ...prevState,
                    isContactDetailsPanelExpand: isContactDetailsPanelExpand,
                    isEnquiryDetailsPanelExpand: isEnquiryDetailsPanelExpand,
                    isAssignPanelExpand: isAssignPanelExpand,
                    isOtherDetailsPanelExpand: isOtherDetailsPanelExpand,
                    isPostalAddressPanelExpand: isPostalAddressPanelExpand,
                    isOther2DetailsPanelExpand: isOther2DetailsPanelExpand,
                    isClient2PostalAddressPanelExpand: isClient2PostalAddressPanelExpand,
                    isFormSubmit: false
                };
            });
        }
    };

    // tslint:disable-next-line: no-any max-line-length
    const onRatingChange = (name: string, isDirectCallMutation: boolean, input: FieldInputProps<any, HTMLElement>, form: FormApi<Enquiry>, values: AnyObject) => (event: React.ChangeEvent<{}>, value: number | null) => {
            if (isDirectCallMutation) {
                form.change('isSavedDirectly', true);

                if (name === 'enquiryQuality') {
                    onUpdateEnquiryQuality(value, values);
                }
                if (name === 'likelihoodToProceed') {
                    onUpdateLikelihood(value, values);
                }
            } else {
                form.change('isSavedDirectly', false);
            }

            input.onChange(value);
        };

    const onUpdateEnquiryQuality = (value: number | null, values: AnyObject) => {

        client
            // tslint:disable-next-line: no-any
            .mutate<any, UpdateEnquiryQualityParams>({
                mutation: UpdateEnquiryQualityMutation,
                variables: {
                    enquiryQuality: value,
                    guidId: props.guid ? props.guid : null,
                    isKeyOpportunity: values.isKeyOpportunity
                },
            });
    };

    const onUpdateLikelihood = (value: number | null, values: AnyObject) => {
        client
            // tslint:disable-next-line: no-any
            .mutate<any, UpdateLikelihoodParams>({
                mutation: UpdateEnquiryLikelihoodMutation,
                variables: {
                    likelihoodToProceed: value,
                    guidId: props.guid ? props.guid : null,
                    isKeyOpportunity: values.isKeyOpportunity
                },
            });
    };

    const onToggleBookMeetingEmailDialog = (form: FormApi<Enquiry>, isOpen: boolean) => {
        form.change('isBookMeetingEmailDialogOpen', isOpen);
    };

    const actionButton = (
        // tslint:disable-next-line: no-any
        form: FormApi<Enquiry>,
        submitting: boolean,
        pristine: boolean,
        // tslint:disable-next-line: no-any
        values: any,
        showEmailNotificationCheckbox: boolean,
        isShowTab: boolean,
        isShowKeyOpportunity: boolean,
        isShowSentAcknowledgementCheckBox: boolean,
        showModifyAndSend: boolean
    ) => {

        const isModifyAndSendPassRequirements = values.followUpSystemAction === 'BookMeeting' && values.staff 
                                                    && values.officeLocation && values.timezone && values.startTime && values.endTime 
                                                    && UserFeature.HasAccess(UserFeatureEnum.hasBookMeetingEmailPreview);

        return (
            <div className={classes.buttonContainer}>
                {/* <RenderCount /> */}
                <div className={classes.mainControlWrapper}>
                    {!isCreateForm ? (
                        <div>
                            <Button
                                variant="contained"
                                type="submit"
                                className={classes.button}
                                onClick={() => {
                                    onActionButtonClick(form, false, false, true);
                                }}
                                disabled={(submitting || pristine || isSubmitting) || values.isSavedDirectly || !UserFeature.HasAccess(UserFeatureEnum.hasModifyLead)}
                            >
                                {isLoading && <div><CircularProgress size={14} /> </div>}
                                {!isLoading && 'Save'}
                                {/* Update */}
                            </Button>
                            {isModifyAndSendPassRequirements && showModifyAndSend && (
                                <Button
                                    variant="contained"
                                    type="button"
                                    className={classes.button} 
                                    onClick={() => onToggleBookMeetingEmailDialog(form, true)}
                                >
                                   Review & Send
                                </Button>
                            )}
                        </div>
                    ) : (
                        <div>
                            <Button
                                variant="contained"
                                type="submit"
                                className={classes.button}
                                onClick={() => {
                                    onActionButtonClick(form, true, false, false);
                                }}
                                disabled={submitting || (pristine && !props.taskSummary) || isSubmitting || !UserFeature.HasAccess(UserFeatureEnum.hasCreateLead)}

                            >
                                {isLoading && <div>Saving... <CircularProgress size={14} /></div>}
                                {!isLoading && 'Save and Close'}
                            </Button>
                            {/* <Button
                                variant="contained"
                                type="submit"
                                className={classes.button}
                                onClick={() => {
                                    // When we attach a document the form crashes. Find a solution to that before changing this back.
                                    onActionButtonClick(form, true, false, false);
                                }}
                                disabled={submitting || (pristine && !props.taskSummary) || isSubmitting}
                            >
                                {isLoading && <div>Saving... <CircularProgress size={14} /></div>}
                                {!isLoading && 'Save and New'}
                            </Button> */}
                        </div>
                    )}
                    {/* {!!!isCreateForm && state.tab === EnquiryFormTab.details && (
                        <div className={classes.checkbboxWrapper}>
                            {showEmailNotificationCheckbox && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.isEmailNotification}
                                            checkedIcon={<CheckBoxIcon className={classes.checkedIcon} />}
                                            onChange={onChangeEmailNotification}
                                            name="emailNotification"
                                        />
                                    }
                                    label="Email Notification"
                                />
                            )}
                            {isShowSentAcknowledgementCheckBox && !state.hideSendAcknowledgement && (
                                <Field
                                    name="isSentAcknowledgementEmail"
                                >
                                    {({ input, meta }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.isSentAcknowledgementEmail}
                                                    checkedIcon={<CheckBoxIcon className={classes.checkedIcon} />}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                                        // Another approach.
                                                        onSendAcknowledgementEmail(form, values, input, checked);
                                                    }}
                                                    name="isSentAcknowledgementEmail"
                                                />
                                            }
                                            label="Send Acknowledgement"
                                        />
                                    )}
                                </Field>
                            )}
                            {isShowKeyOpportunity && UserFeature.HasAccess(UserFeatureEnum.hasKeyOpportunity)
                                && (
                                    <Field
                                        name="isKeyOpportunity"
                                    >
                                        {({ input, meta }) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.isKeyOpportunity}
                                                        checkedIcon={<CheckBoxIcon className={classes.checkedIcon} />}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                                            // Another approach.
                                                            onKeyOpportunity(form, values, input, checked);
                                                        }}
                                                        name="isKeyOpportunity"
                                                    />
                                                }
                                                label="Key Opportunity"
                                            />
                                        )}
                                    </Field>
                                )}
                        </div>
                    )} */}
                    {!!!isCreateForm && state.tab === EnquiryFormTab.activity && isShowTab && (
                        <div className={classes.readRatingWrapper}>
                            <Field name="enquiryQuality">
                                {(
                                    { input } /* FieldRenderProps */
                                ) => {
                                    // tslint:disable-next-line: radix
                                    return (
                                        <div className={classes.ratingWrapper}>
                                            <Typography component="legend">Enquiry Quality</Typography>
                                            <Rating
                                                // tslint:disable-next-line: radix
                                                value={parseInt(input.value)}
                                                onChange={onRatingChange('enquiryQuality', true, input, form, values)}
                                            />
                                        </div>
                                    );
                                }}
                            </Field>
                            <Field name="likelihoodToProceed">
                                {(
                                    { input } /* FieldRenderProps */
                                ) => {
                                    // tslint:disable-next-line: radix
                                    return (
                                        <div className={classes.ratingWrapper}>
                                            <Typography component="legend">Likelihood to Proceed</Typography>
                                            <Rating
                                                // tslint:disable-next-line: radix
                                                value={parseInt(input.value)}
                                                onChange={onRatingChange('likelihoodToProceed', true, input, form, values)}
                                            />
                                        </div>
                                    );
                                }}
                            </Field>
                        </div>
                    )}
                    <div className={classes.checkoxContainer}>
                        <Button
                            variant="contained"
                            type="button"
                            className={classes.button}
                            onClick={() => closePanel(form, values, false)}
                        >
                            {isLoading && <div><CircularProgress size={14} /></div>}
                            {!isLoading && 'Close'}
                            {/* Close */}
                        </Button>
                        {/* <Button
                            id="resetButton"
                            type="button"
                            onClick={() => onReset(form, values)}
                            disabled={submitting || pristine}
                            className={classes.hidden}
                        >
                            {isLoading && <div><CircularProgress size={14} /></div>}
                            {!isLoading && 'Reset'}
                        </Button>                    */}
                    </div>
                </div>
                {state.tab === EnquiryFormTab.details && (
                    <div className={classes.checkbboxWrapper}>
                        {showEmailNotificationCheckbox && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.isEmailNotification}
                                        checkedIcon={<CheckBoxIcon className={classes.checkedIcon} />}
                                        onChange={onChangeEmailNotification}
                                        name="emailNotification"
                                    />
                                }
                                label="Email Notification"
                            />
                        )}
                        {isShowSentAcknowledgementCheckBox && !state.hideSendAcknowledgement && (
                            <Field
                                name="isSentAcknowledgementEmail"
                            >
                                {({ input, meta }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.isSentAcknowledgementEmail}
                                                checkedIcon={<CheckBoxIcon className={classes.checkedIcon} />}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                                    // Another approach.
                                                    onSendAcknowledgementEmail(form, values, input, checked);
                                                }}
                                                name="isSentAcknowledgementEmail"
                                            />
                                        }
                                        label="Send Acknowledgement"
                                    />
                                )}
                            </Field>
                        )}
                        {isShowKeyOpportunity && UserFeature.HasAccess(UserFeatureEnum.hasKeyOpportunity) && (
                            <Field
                                name="isKeyOpportunity"
                            >
                                {({ input, meta }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.isKeyOpportunity}
                                                checkedIcon={<CheckBoxIcon className={classes.checkedIcon} />}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                                    // Another approach.
                                                    onKeyOpportunity(form, values, input, checked);
                                                }}
                                                name="isKeyOpportunity"
                                            />
                                        }
                                        label="Key Opportunity"
                                    />
                                )}
                            </Field>
                        )}
                    </div>
                )}
            </div>
        );
    };

    const displayLoader = () => {
        if (isLoading || regionSettings.loading || partyAddressesQuery.loading) {
            return <RvLoader />;
        } else {
            return null;
        }
    };

    // tslint:disable-next-line
    const onFormValueChanged = (form: FormApi<any>, props: any) => {

        // If isSavedDirectly is true, then we don't want to enable save button/ show discard messages
        let formState = form.getState().values;
        if (formState.isSavedDirectly === true) {
            // Check pristine prop for Follow Up Section's controls. if they are not pristine anymore, then the form is changed.
            if ((form.getFieldState('followUpAction') !== undefined && !form.getFieldState('followUpAction')?.pristine) ||
                (form.getFieldState('staff') !== undefined && !form.getFieldState('staff')?.pristine) ||
                (form.getFieldState('isSendConfirmation') !== undefined && !form.getFieldState('isSendConfirmation')?.pristine) ||
                (form.getFieldState('officeLocation') !== undefined && !form.getFieldState('officeLocation')?.pristine) ||
                (form.getFieldState('timezone') !== undefined && !form.getFieldState('timezone')?.pristine) ||
                (form.getFieldState('startTime') !== undefined && !form.getFieldState('startTime')?.pristine) ||
                (form.getFieldState('endTime') !== undefined && !form.getFieldState('endTime')?.pristine) ||
                (form.getFieldState('isAllDay') !== undefined && !form.getFieldState('isAllDay')?.pristine) ||
                (form.getFieldState('meetingNotes') !== undefined && !form.getFieldState('meetingNotes')?.pristine) ||
                (form.getFieldState('feeApplicable') !== undefined && !form.getFieldState('feeApplicable')?.pristine) ||
                (form.getFieldState('emailTemplate') !== undefined && !form.getFieldState('emailTemplate')?.pristine) ||
                (form.getFieldState('followUpDate') !== undefined && !form.getFieldState('followUpDate')?.pristine) ||
                (form.getFieldState('followUpNotes') !== undefined && !form.getFieldState('followUpNotes')?.pristine) ||
                (form.getFieldState('outcome') !== undefined && !form.getFieldState('outcome')?.pristine) ||
                (form.getFieldState('outcomeDate') !== undefined && !form.getFieldState('outcomeDate')?.pristine) ||
                (form.getFieldState('followUpDueReminder') !== undefined && !form.getFieldState('followUpDueReminder')?.pristine) ||
                (form.getFieldState('outcomeNotes') !== undefined && !form.getFieldState('outcomeNotes')?.pristine) ||
                (form.getFieldState('reason') !== undefined && !form.getFieldState('reason')?.pristine) ||
                (form.getFieldState('referredToFirmName') !== undefined && !form.getFieldState('referredToFirmName')?.pristine) ||
                (form.getFieldState('referredToFirmSelector') !== undefined && !form.getFieldState('referredToFirmSelector')?.pristine) ||
                (form.getFieldState('notProceedingOutcomeNotes') !== undefined && !form.getFieldState('notProceedingOutcomeNotes')?.pristine)
            ) {
                setState((prevState) => {
                    return {
                        ...prevState,
                        isFormChanged: true
                    };
                });
                form.change('isSavedDirectly', false);
            } else if (!state.isFormChanged) {
                // Set it to not changed if the values are changed from rating controls.
                setState((prevState) => {
                    return {
                        ...prevState,
                        isFormChanged: false
                    };
                });
            }
        } else if (!props.pristine) {
            if (!state.isFormChanged) {
                // let currentChangesMadeInTheDetailsTab = state.tab === EnquiryFormTab.details;

                setState((prevState) => {
                    return {
                        ...prevState,
                        isFormChanged: true,
                        showTabsSaveAlert: true // currentChangesMadeInTheDetailsTab
                    };
                });
                form.change('isSavedDirectly', false);
            } else { // isFormChanged = true. But changes could have been made from other tabs before coming into the details tab.
                // let currentChangesMadeInTheDetailsTab = state.tab === EnquiryFormTab.details;

                setState((prevState) => {
                    return {
                        ...prevState,
                        showTabsSaveAlert: true // currentChangesMadeInTheDetailsTab
                    };
                });
            }
        }

        // If Estimated Fees has value then set Likelihood to Proceed to 3 stars
        if (form.getFieldState('estimatedFees')?.value) {
            if (form.getFieldState('likelihoodToProceed')?.value === null || form.getFieldState('likelihoodToProceed')?.value === 0) {
                form.change('likelihoodToProceed', 3);
            }
        }
    };

    const GetFiles = (files: DocumentFile[]) => {
        return files.map((document: DocumentFile) => {
            return {
                file: document.file,
                name: document.name,
                guidID: document.guidID,
                status: document.status
            };
        });
    };

    const getFollowUpEnquiryInput = (values: Enquiry) => {
        return {
            enquiryGuid: props.guid,
            followUpAction: values.followUpAction,
            followUpActionSystemName: values.followUpActionSystemName,
            followUpDate: values.followUpDate === null ? moment().toDate() : moment(values.followUpDate).toDate(),
            outcome: values.outcome,
            outcomeDate: values.outcomeDate === null ? null : moment(values.outcomeDate).toDate(),
            outcomeNotes: values.outcomeNotes,
            reason: values.reason,
            reasonGuid: values.reasonGuidID,
            followUpNotes: values.followUpNotes,
            reminderOffset: values.outcome === 'More Follow Up' // Only set reminderOffSet value if the outcome is More Follow Up
                ? (values.followUpDueReminder ? values.followUpDueReminder.value : null)
                : null,
            updateFollowUpDueForInternalNote: values.updateFollowUpDueForInternalNote,
            referredToFirmName: values.referredToFirmName,
            referredToFirmGuid: values.referredToFirmGuid,
            notProceedingNotes: values.notProceedingOutcomeNotes
        };
    };

    const getLinkMatterEnquiryInput = (values: Enquiry) => {
        return {
            enquiryGuid: props.guid,
            matterGuid: values.matterSelector.value
        };
    };

    const getFollowUpDueDefaultValue = () => {
        // check outcome date (FollowUpDue)
        let outcomeDate: Date | null = null;

        if (props.enquiryDefaultConfig) {

            let hours = 0;
            let minutes = 0;
            let validTimeOfDay = false;

            if (props.enquiryDefaultConfig.timeOfDay && props.enquiryDefaultConfig.timeOfDay.toLowerCase() !== 'invalid date') {
                var timeOfDaySplit = props.enquiryDefaultConfig.timeOfDay ? props.enquiryDefaultConfig.timeOfDay.split(':') : null;

                if (timeOfDaySplit) {
                    validTimeOfDay = true;
                    hours = Number(timeOfDaySplit[0]);
                    minutes = Number(timeOfDaySplit[1]);
                }
            }

            if (props.enquiryDefaultConfig.isCalculatedDays) {
                let date = addBusinessDays(moment().toDate(), props.enquiryDefaultConfig.addDays ? props.enquiryDefaultConfig.addDays : 0);

                if (validTimeOfDay) {
                    outcomeDate = date
                        .set('hour', hours)
                        .set('minute', minutes)
                        .toDate();
                } else {
                    outcomeDate = date.toDate();
                }
            } else if (props.enquiryDefaultConfig.isCalculatedHours) {
                let addhours = props.enquiryDefaultConfig.addHours ? props.enquiryDefaultConfig.addHours : 0;

                outcomeDate = moment()
                    .add(addhours, 'hour')
                    .toDate();
            }
        }

        return outcomeDate;
    };

    const getOutcomeDefaultTime = () => {
        let defaultTime = new Date().setHours(17, 0, 0, 0);

        let hours = 0;
        let minutes = 0;

        if (props.enquiryDefaultConfig && props.enquiryDefaultConfig.isManualEntry
            && props.enquiryDefaultConfig.timeOfDay && props.enquiryDefaultConfig.timeOfDay.toLowerCase() !== 'invalid date') {
            var timeOfDaySplit = props.enquiryDefaultConfig.timeOfDay ? props.enquiryDefaultConfig.timeOfDay.split(':') : null;

            if (timeOfDaySplit) {
                hours = Number(timeOfDaySplit[0]);
                minutes = Number(timeOfDaySplit[1]);
            }

            defaultTime = new Date().setHours(hours, minutes, 0, 0);
        }

        return defaultTime;
    };

    // tslint:disable-next-line: no-any
    const getEnquiryDetails = (isInitialLoad: boolean): Enquiry => {
        let assignedBy = enquiryDetail.assignedBy;
        let assignedByName = enquiryDetail.assignedByName;
        let individualSelector: IndividualNameSummary | null = null;
        let clientIndividualSelector: IndividualNameSummary | null = null;
        let secondClientIndividualSelector: IndividualNameSummary | null = null;
        let organisationSelector: OrganisationIAutoCompleteItem | null = null;
        let referredToFirmSelector: IAutoCompleteItem | null = null;
        let otherPartyIndividualSelector: IndividualNameSummary | null = null;

        if (isInitialLoad && !isCreateForm) {
            if (assignedBy === null) {
                assignedBy = props.assignedBy;
                assignedByName = props.assignedByName;
            }
        }

        if (enquiryDetail.clientGuid) {
            clientIndividualSelector = {
                value: enquiryDetail.clientGuid,
                label: (enquiryDetail.clientFirstName + ' ' + enquiryDetail.clientLastName),
                id: 1,
                guidID: enquiryDetail.clientGuid,
                name: (enquiryDetail.clientFirstName + ' ' + enquiryDetail.clientLastName),
                firstName: enquiryDetail.clientFirstName,
                lastName: enquiryDetail.clientLastName,
                emailAddress: enquiryDetail.clientEmail,
                gender: null,
                mobile: enquiryDetail.clientMobile,
                phone: enquiryDetail.clientPhone,
                organisationGuid: null,
                organisationName: null,
                nameId: null,
                addressName: null,
                floor: null,
                number: null,
                street: null,
                city: null,
                state: null,
                postCode: null,
                country: null,
                isFromDataSource: null,
                title: enquiryDetail.clientTitle ?? 'none',
            };
        }

        if (enquiryDetail.secondClientGuid) {
            secondClientIndividualSelector = {
                value: enquiryDetail.secondClientGuid,
                label: (enquiryDetail.secondClientFirstName + ' ' + enquiryDetail.secondClientLastName),
                id: 1,
                guidID: enquiryDetail.secondClientGuid,
                name: (enquiryDetail.secondClientFirstName + ' ' + enquiryDetail.secondClientLastName),
                firstName: enquiryDetail.secondClientFirstName,
                lastName: enquiryDetail.secondClientLastName,
                emailAddress: enquiryDetail.secondClientEmail,
                gender: null,
                mobile: enquiryDetail.secondClientMobile,
                phone: enquiryDetail.secondClientPhone,
                organisationGuid: null,
                organisationName: null,
                nameId: null,
                addressName: null,
                floor: null,
                number: null,
                street: null,
                city: null,
                state: null,
                postCode: null,
                country: null,
                isFromDataSource: null,
                title: enquiryDetail.secondClientTitle ?? 'none',
            };
        }

        if (enquiryDetail.nameGuid) {
            individualSelector = {
                value: enquiryDetail.nameGuid,
                label: (enquiryDetail.firstName + ' ' + enquiryDetail.lastName),
                id: 1,
                guidID: enquiryDetail.nameGuid,
                name: (enquiryDetail.firstName + ' ' + enquiryDetail.lastName),
                firstName: enquiryDetail.firstName,
                lastName: enquiryDetail.lastName,
                emailAddress: enquiryDetail.email,
                gender: enquiryDetail.gender,
                mobile: enquiryDetail.mobile,
                phone: enquiryDetail.phone,
                organisationGuid: enquiryDetail.organisationGuid,
                organisationName: enquiryDetail.organisation,
                nameId: null,
                addressName: null,
                floor: null,
                number: null,
                street: null,
                city: null,
                state: null,
                postCode: null,
                country: null,
                isFromDataSource: enquiryDetail.isNameFromDataSource,
                title: enquiryDetail.title ?? 'none',
            };
        }

        if (enquiryDetail.otherPartyNameGuid) {
            otherPartyIndividualSelector = {
                value: enquiryDetail.otherPartyNameGuid,
                label: (enquiryDetail.otherPartyFirstName + ' ' + enquiryDetail.otherPartyLastName),
                id: 2,
                guidID: enquiryDetail.otherPartyNameGuid,
                name: (enquiryDetail.otherPartyFirstName + ' ' + enquiryDetail.otherPartyLastName),
                firstName: enquiryDetail.otherPartyFirstName,
                lastName: enquiryDetail.otherPartyLastName,
                emailAddress: enquiryDetail.otherPartyEmail,
                gender: null,
                mobile: enquiryDetail.otherPartyMobile,
                phone: enquiryDetail.otherPartyPhone,
                organisationGuid: enquiryDetail.organisationGuid,
                organisationName: enquiryDetail.otherPartyOrganisation,
                nameId: null,
                addressName: enquiryDetail.otherPartyAddressName,
                floor: enquiryDetail.otherPartyFloor,
                number: enquiryDetail.otherPartyNumber,
                street: enquiryDetail.otherPartyStreet,
                city: enquiryDetail.otherPartySuburb,
                state: enquiryDetail.otherPartyState,
                postCode: enquiryDetail.otherPartyPostcode,
                country: enquiryDetail.otherPartyCountry,
                isFromDataSource: enquiryDetail.isNameFromDataSource,
                title: enquiryDetail.otherPartyTitle ?? 'none',
            };
        }

        if (enquiryDetail.organisationGuid) {
            organisationSelector = { value: enquiryDetail.organisationGuid, label: enquiryDetail.organisation } as OrganisationIAutoCompleteItem;
        }

        if (enquiryDetail.referredToFirmGuid) {
            referredToFirmSelector = { value: enquiryDetail.referredToFirmGuid, label: enquiryDetail.referredToFirmName };
        }

        return {
            duplicateCheck: null,
            isBookMeetingEmailDialogOpen: false,
            guid: props.guid,
            isClosed: enquiryDetail.isClosed,
            firstName: enquiryDetail.firstName,
            lastName: enquiryDetail.lastName,
            individualSelector: individualSelector,
            email: enquiryDetail.email,
            gender: enquiryDetail.gender,
            initialEnquiryDate: enquiryDetail.initialEnquiryDate ? moment(enquiryDetail.initialEnquiryDate).toDate() : moment().toDate(),
            phone: enquiryDetail.phone,
            mobile: enquiryDetail.mobile,
            source: enquiryDetail.source && enquiryDetail.sourceGuidID ? { label: enquiryDetail.source, value: enquiryDetail.sourceGuidID } : null,
            office: enquiryDetail.office && enquiryDetail.officeGuidID ? { label: enquiryDetail.office, value: enquiryDetail.officeGuidID } : null,
            sourceNotes: enquiryDetail.sourceNotes,

            organisationSelector: organisationSelector,
            organisation: enquiryDetail.organisation,
            positionAtOrganisation: enquiryDetail.positionAtOrganisation,

            natureOfEnquiry: enquiryDetail.natureOfEnquiry,
            practiceAreaGuidID: enquiryDetail.practiceAreaGuidID,
            areaOfLaw: enquiryDetail.areaOfLaw,

            internalNotes: enquiryDetail.internalNotes,
            files: enquiryDetail.files,

            assignedTo: enquiryDetail.assignedTo,
            assignedToName: enquiryDetail.assignedToName,
            // assignedToEmailAddress: enquiryDetail.assignedToEmailAddress,
            assignedBy: assignedBy,
            assignedByName: assignedByName,
            // assignedByEmailAddress: enquiryDetail.assignedByEmailAddress,
            dateAssigned: enquiryDetail.dateAssigned === null ? moment().toDate() : moment(enquiryDetail.dateAssigned).toDate(),
            followUpDueDate: enquiryDetail.followUpDueDate === null ? null : moment(enquiryDetail.followUpDueDate).toDate(),
            // followUpDueDate: enquiryDetail.followUpDueDate === null
            //     ? assignedBy // When an enquiry is closed and re-opened, there will not be a due date.
            //                  // So the Follow Up Due in Assignment will be empty and cause validation errors. So, if we have a assignment value, then set it to today.
            //         ? (getFollowUpDueDefaultValue() == null ? moment().toDate() : getFollowUpDueDefaultValue())
            //         : null
            //     : moment(enquiryDetail.followUpDueDate).toDate(),
            assignmentFollowUpDueReminder: getReminderValue(true),

            followUpAction: 'Emailed', // enquiryDetail.followUpAction,
            followUpActionSystemName: 'Emailed',
            followUpSystemAction: '',
            followUpDate: moment().toDate(), // enquiryDetail.followUpDate === null ? moment().toDate() : moment(enquiryDetail.followUpDate).toDate(),
            outcome: 'More Follow Up', // enquiryDetail.outcome,y
            outcomeDate: getFollowUpDueDefaultValue(), // null,
            outcomeNotes: '',
            reason: null, // enquiryDetail.reason,
            reasonGuidID: null,
            followUpNotes: null, // enquiryDetail.followUpNotes,
            hasFollowUpNotesMentions: false,
            followUpDueReminder: getReminderValue(false),

            handleCreateAndClose: false,
            handleCreateAndNew: false,
            handleUpdate: false,
            // showDiscard: false,
            // isFormChanged: false,
            isAssignmentExpand: enquiryDetail.assignedTo ? false : true,
            isKeyOpportunity: enquiryDetail.isKeyOpportunity,
            isNotProceeding: enquiryDetail.outcome === 'Not Proceeding' ? true : false,
            notProceedingReason: null,
            notProceedingReasonGuid: null,

            referrerGuid: enquiryDetail.referrerGuid,
            referrerName: enquiryDetail.referrerName,
            isShowReferrer: enquiryDetail.isSourceReferrer ? true : (enquiryDetail.referrerGuid ? true : false),

            isNameSwitched: false,
            isOrganisationSwitched: false, // enquiryDetail.organisationGuid ? true : false,
            nameGuid: enquiryDetail.nameGuid,
            organisationGuid: enquiryDetail.organisationGuid,

            // tslint:disable-next-line: max-line-length
            staff: null,
            startTime: null, // moment().add(1, 'hour').toDate(),
            endTime: null, // moment().add(2, 'hour').toDate(),
            isAllDay: false,
            officeLocation: null,
            feeApplicable: null,
            isSendConfirmation: true,
            meetingNotes: null,
            timezone: null,

            emailTemplate: null,

            isContactDetailsChanged: false,
            isUpdateContacts: false,
            isSentAcknowledgementEmail: false,
            updateFollowUpDueForInternalNote: true,
            outcomeDateChanged: false,

            // Other details
            estimatedFees: enquiryDetail.estimatedFees,
            isEstimatedFeesIncludesTax: enquiryDetail.isEstimatedFeesIncludesTax,
            estimatedDisbursements: enquiryDetail.estimatedDisbursements,
            isEstimatedDisbursementsIncludesTax: enquiryDetail.isEstimatedDisbursementsIncludesTax,
            enquiryQuality: enquiryDetail.enquiryQuality,
            likelihoodToProceed: enquiryDetail.likelihoodToProceed,

            clientFirstName: enquiryDetail.clientFirstName,
            clientLastName: enquiryDetail.clientLastName,
            dOB: enquiryDetail.dOB,
            isMarketingConsent: enquiryDetail.isMarketingConsent,
            isConflictCheckPerformed: enquiryDetail.isConflictCheckPerformed,
            isTermsAndConditionsApproved: enquiryDetail.isTermsAndConditionsApproved,
            isIdentityCheckPerformed: enquiryDetail.isIdentityCheckPerformed,
            isPrivacyStatementProvided: enquiryDetail.isPrivacyStatementProvided,
            addressName: enquiryDetail.addressName,
            floor: enquiryDetail.floor,
            number: enquiryDetail.number,
            street: enquiryDetail.street,
            street2: enquiryDetail.clientAddressStreet2,
            suburb: enquiryDetail.suburb,
            state: enquiryDetail.state,
            postcode: enquiryDetail.postcode,
            country: enquiryDetail.country,
            pOBoxType: enquiryDetail.pOBoxType ? enquiryDetail.pOBoxType : props.pOBoxType,
            pOBox: enquiryDetail.pOBox,
            postalSuburb: enquiryDetail.postalSuburb,
            postalState: enquiryDetail.postalState,
            postalPostCode: enquiryDetail.postalPostCode,
            postalCountry: enquiryDetail.postalCountry,
            isNoConflict: enquiryDetail.isNoConflict,
            isShowNotProceedingReferrer: enquiryDetail.referredToFirmGuid ? true : false,
            isNotProceedingReferrerSwitched: false,
            referredToFirmSelector: referredToFirmSelector,
            referredToFirmGuid: enquiryDetail.referredToFirmGuid,
            referredToFirmName: enquiryDetail.referredToFirmName,
            notProceedingOutcomeNotes: enquiryDetail.notProceedingOutcomeNotes,
            isShowNotProceedingReferrerInProceeding: enquiryDetail.referredToFirmGuid ? true : false,
            isNotProceedingReferrerSwitchedInProceeding: false,
            referredToFirmSelectorInProceeding: referredToFirmSelector,
            referredToFirmGuidInProceeding: enquiryDetail.referredToFirmGuid,
            referredToFirmNameInProceeding: enquiryDetail.referredToFirmName,
            notProceedingOutcomeNotesInProceeding: enquiryDetail.notProceedingOutcomeNotes,
            clientRelationshipToEnquirer: enquiryDetail.clientRelationshipToEnquirer,
            matterId: enquiryDetail.matterId,
            matterGuid: enquiryDetail.matterGuid,
            matterTitle: enquiryDetail.matterTitle,
            matterFileNumber: enquiryDetail.matterFileNumber,
            matterSelector: enquiryDetail.matterId ? {
                label: enquiryDetail.matterFileNumber + ' (' + enquiryDetail.matterTitle + ')',
                value: '' + enquiryDetail.matterGuid,
                fileNumber: enquiryDetail.matterFileNumber,
                title: enquiryDetail.matterTitle,
                id: enquiryDetail.matterId
            } : null,
            enquiryNotes: enquiryDetail.enquiryNotes,

            otherPartyFirstName: enquiryDetail.otherPartyFirstName,
            otherPartyLastName: enquiryDetail.otherPartyLastName,
            otherPartyNotes: enquiryDetail.otherPartyNotes,
            otherPartyEmail: enquiryDetail.otherPartyEmail,
            otherPartyPhone: enquiryDetail.otherPartyPhone,
            otherPartyMobile: enquiryDetail.otherPartyMobile,
            otherPartyOrganisation: enquiryDetail.otherPartyOrganisation,
            isSavedDirectly: false,
            otherPartyDOB: enquiryDetail.otherPartyDOB,
            otherPartyAddressName: enquiryDetail.otherPartyAddressName,
            otherPartyFloor: enquiryDetail.otherPartyFloor,
            otherPartyNumber: enquiryDetail.otherPartyNumber,
            otherPartyStreet: enquiryDetail.otherPartyStreet,
            otherPartyStreet2: enquiryDetail.otherPartyStreet2,
            otherPartySuburb: enquiryDetail.otherPartySuburb,
            otherPartyState: enquiryDetail.otherPartyState,
            otherPartyPostcode: enquiryDetail.otherPartyPostcode,
            otherPartyCountry: enquiryDetail.otherPartyCountry,

            otherPartyPOBoxType: enquiryDetail.otherPartyPOBoxType,
            otherPartyPOBox: enquiryDetail.otherPartyPOBox,
            otherPartyPostalSuburb: enquiryDetail.otherPartyPostalSuburb,
            otherPartyPostalState: enquiryDetail.otherPartyPostalState,
            otherPartyPostalPostCode: enquiryDetail.otherPartyPostalPostCode,
            otherPartyPostalCountry: enquiryDetail.otherPartyPostalCountry,
            otherPartyNameGuid: enquiryDetail.otherPartyNameGuid,
            otherPartyOrganisationGuid: enquiryDetail.otherPartyOrganisationGuid,
            otherPartyIndividualSelector: otherPartyIndividualSelector,
            isOtherPartyNameSwitched: false,

            isRiskAssessed: enquiryDetail.isRiskAssessed,
            riskLevelSelector: enquiryDetail.riskLevelGuid ?  { label: enquiryDetail.riskLevel ?? '', value: enquiryDetail.riskLevelGuid } : null,
            riskNotes: enquiryDetail.riskNotes,

            secondClientFirstName:                    enquiryDetail.secondClientFirstName,
            secondClientLastName:                     enquiryDetail.secondClientLastName,
            secondClientRelationshipToEnquirer:       enquiryDetail.secondClientRelationshipToEnquirer,
            secondClientGender:                       enquiryDetail.secondClientGender,
            secondClientSuburb:                       enquiryDetail.secondClientSuburb,
            secondClientState:                        enquiryDetail.secondClientState,
            secondClientPostCode:                     enquiryDetail.secondClientPostCode,
            secondClientIsMarketingConsent:           enquiryDetail.secondClientIsMarketingConsent,
            secondClientIsTermsAndConditionsApproved: enquiryDetail.secondClientIsTermsAndConditionsApproved,
            secondClientIsPrivacyStatementProvided:   enquiryDetail.secondClientIsPrivacyStatementProvided,
            secondClientIsConflictCheckPerformed:     enquiryDetail.secondClientIsConflictCheckPerformed,
            secondClientIsIdentityCheckPerformed:     enquiryDetail.secondClientIsIdentityCheckPerformed,
            secondClientIsNoConflict:                 enquiryDetail.secondClientIsNoConflict,
            secondClientCountry:                       enquiryDetail.secondClientCountry,
            secondClientAddressName:                   enquiryDetail.secondClientAddressName,
            secondClientFloor:                         enquiryDetail.secondClientFloor,
            secondClientNumber:                        enquiryDetail.secondClientNumber,
            secondClientStreet1:                       enquiryDetail.secondClientStreet1,
            secondClientStreet2:                       enquiryDetail.secondClientStreet2,
            secondClientDOB:                           enquiryDetail.secondClientDOB,
            secondClientPOBoxType:                     enquiryDetail.secondClientPOBoxType,
            secondClientPOBox:                         enquiryDetail.secondClientPOBox,
            secondClientPostalSuburb:                  enquiryDetail.secondClientPostalSuburb,
            secondClientPostalState:                   enquiryDetail.secondClientPostalState,
            secondClientPostalPostCode:                enquiryDetail.secondClientPostalPostCode,
            secondClientPostalCountry:                 enquiryDetail.secondClientPostalCountry,

            responseText:                               enquiryDetail.responseText,

            // bot integration
            followUpBotUrl: null,

            // events and campaigns
            // tslint:disable-next-line: max-line-length
            eventAndCampaignSelector: enquiryDetail.eventAndCampaignGuid && enquiryDetail.eventAndCampaignName ? { label: enquiryDetail.eventAndCampaignName, value: enquiryDetail.eventAndCampaignGuid } as EventsAndCampaignIAutoCompleteItem : null,
            eventsAndCampaignNotes: enquiryDetail.eventAndCampaignNotes,

            title: enquiryDetail.title,
            otherPartyTitle: enquiryDetail.otherPartyTitle,
    
            clientTitle: enquiryDetail.clientTitle,
            clientGuid: enquiryDetail.clientGuid,
            clientEmail: enquiryDetail.clientEmail,
            clientPhone: enquiryDetail.clientPhone,
            clientMobile: enquiryDetail.clientMobile,
    
            secondClientTitle: enquiryDetail.secondClientTitle,
            secondClientGuid: enquiryDetail.secondClientGuid,
            secondClientEmail: enquiryDetail.secondClientEmail,
            secondClientPhone: enquiryDetail.secondClientPhone,
            secondClientMobile: enquiryDetail.secondClientMobile,

            clientIndividualSelector: clientIndividualSelector,
            isClientNameSwitched: false,
            isSecondClientNameSwitched: false,
            secondClientIndividualSelector: secondClientIndividualSelector,
        };
    };

    // tslint:disable-next-line: no-any
    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            // onEnquiryFormClose();
            // onEnquiryFormEmpty(true);

            onEnquiryFormClosed();
        } else {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: false,
                    showTabsSaveAlert: false
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    const onShowMatterDetails = (values: any) => () => {

        sessionStorage.setItem('typeOfView', 'noback');

        const tempMatter = values.matterSelector;

        var href = history.createHref({
            pathname: '/matterDetail/' + tempMatter.id,
            key: 'matterManagement',
            state: {
                matterManagementState: 'false',
            }
        });

        window.open(href, '_blank');
    };

    const onEnquiryFormClosed = () => {
        if (props.onDialogClose) {
            props.onDialogClose();
        }
    };

    const handleExpandChange = (event: React.ChangeEvent<{}>, expanded: boolean, form: FormApi<Enquiry>) => {
        form.change('isAssignmentExpand', expanded);
    };

    const estimatedFeesColour = (financial: Financials | null) => {
        if (financial && (financial.billedTimeAndFees + financial.unbilledTimeAndFees) > financial.estimatedCosts) {
            return 'red';
        }
        return undefined;
    };

    // tslint:disable-next-line: no-any
    const followUpDueDateValidation = (form: FormApi<any>, values: any) => {
        if (isTabMatter()) {
            return undefined;
        } else if (!isTabActivity() && (enquiryActionData && enquiryActionData.canAssign)) {
            if (form.getFieldState('assignedTo')?.value) {
                return values.isNotProceeding === true ? undefined : requiredDateValidator;
            } else {
                return undefined;
            }
        } else {
            if (!isTabActivity()) {
                if (form.getFieldState('assignedTo')?.value) {
                    if (values.isClosed) {
                        return undefined;
                    }
                    return values.isNotProceeding === true ? undefined : requiredDateValidator;
                } else {
                    return undefined;
                }
            } else {
                return undefined;
            }
        }
    };

    // tslint:disable-next-line: no-any
    const followUpDueDateRequired = (form: FormApi<any>, values: any) => {
        if (isTabMatter()) {
            return false;
        } else if (!isTabActivity() && (enquiryActionData && enquiryActionData.canAssign)) {
            if (form.getFieldState('assignedTo')?.value) {
                return values.isNotProceeding === true ? false : true;
            } else {
                return false;
            }
        } else {
            if (!isTabActivity()) {
                if (form.getFieldState('assignedTo')?.value) {
                    if (values.isClosed) {
                        return false;
                    }
                    return values.isNotProceeding === true ? false : true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    };

    // tslint:disable-next-line: no-any
    const getAssignment = (form: FormApi<any>, values: AnyObject, pristine: boolean) => {
        // const isExpand = (values.isAssignmentExpand !== undefined && values.isAssignmentExpand !== null)
        //                     ? values.isAssignmentExpand
        //                     : true;

        let defaultExpanded = enquiryActionData && !enquiryActionData.canAssign
            ? false
            : enquiryActionData?.canFollowUp ? false : true;
        return (
            <ExpansionPanel
                defaultExpanded={defaultExpanded}
                expanded={state.isAssignPanelExpand === true ? state.isAssignPanelExpand : defaultExpanded}
                onChange={onExpandPanel('isAssignPanelExpand')}
                className={classes.expansionPanel}
                disabled={enquiryActionData ? !enquiryActionData.canAssign : false}
            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.expansionPanelSummary}
                >
                    <Typography className={classes.heading}>
                        Assignment
                    </Typography>
                    {
                        values ? (
                            <div className={'col'}>
                                {values.assignedToName
                                    ? (
                                        'Assigned to ' + values.assignedToName +
                                        (
                                            values.dateAssigned && values.dateAssigned !== null ? (
                                                ' on ' + moment(values.dateAssigned).format('DD MMM YYYY')
                                            ) : ''
                                        )
                                    ) : ''
                                }
                            </div>
                        ) : null
                    }
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    className={classes.expandablePanelDetails}
                >
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={5} className={classes.customFieldGrid}>
                            <div>
                                <Field
                                    name="assignedTo"
                                    validate={!isTabActivity()
                                        ? form.getFieldState('followUpDueDate')?.value !== null
                                            ? (form.getFieldState('isNotProceeding')?.value === true ? undefined : required)
                                            : undefined
                                        : undefined}
                                >
                                    {({ input, meta }) => (
                                        <div>
                                            {/* <RenderCount/> */}
                                            <UserSelector
                                                {...input}
                                                label="Assigned To"
                                                name="assignedTo"
                                                required={!isTabActivity()
                                                    ? form.getFieldState('followUpDueDate')?.value !== null
                                                        ? (form.getFieldState('isNotProceeding')?.value === true ? false : true) : false
                                                    : false}
                                                // tslint:disable-next-line: max-line-length
                                                onSelection={(selection: IDownShiftItem, name: string) => {
                                                    // Another approach.
                                                    onDropDownSelect(form, values, input, selection, name);
                                                }}
                                                isGuidAsValue={true}
                                                // isStaffOnly={true}
                                                displayLabel={values.assignedToName === null
                                                    ? undefined : values.assignedToName}
                                                isExternal={false}
                                                isAuthorized={true}
                                            />
                                        </div>
                                    )}
                                </Field>
                            </div>
                        </Grid>
                        <Grid item={true} xs={4} className={classes.customFieldGrid}>
                            <FormSpy subscription={{ values: true, pristine: true }}>
                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                {({ values }) => (
                                    <div>
                                        <Field
                                            name="followUpDueDate"
                                            // tslint:disable-next-line: max-line-length
                                            validate={followUpDueDateValidation(form, values)}
                                            subscription={{ touched: true, error: true, value: true }}
                                        >
                                            {({ input, meta }) => (
                                                <div>
                                                    <KeyboardDateTimePicker
                                                        {...input}
                                                        className={classes.textField}
                                                        label="Follow Up Due"
                                                        format="DD/MM/YYYY hh:mm a"
                                                        placeholder="dd/mm/yyyy hh:mm a"
                                                        required={followUpDueDateRequired(form, values)}
                                                        animateYearScrolling={true}
                                                        // tslint:disable-next-line: max-line-length
                                                        autoOk={true}
                                                        allowKeyboardControl={true}
                                                        variant={'inline'}
                                                        error={meta.error && meta.touched}
                                                        helperText={
                                                            meta.error && meta.touched
                                                                ? meta.error // 'Follow Up Due date is required'
                                                                : ''
                                                        }
                                                        inputProps={{ autocomplete: 'off' }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        value={values.followUpDueDate}
                                                        // minDate={new Date()}
                                                        initialFocusedDate={getOutcomeDefaultTime()}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                )}
                            </FormSpy>
                        </Grid>
                        <Grid item={true} xs={3} alignItems="flex-end" className={classes.customFieldGrid}>
                            <FormSpy subscription={{ values: true, pristine: true }}>
                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                {({ values }) => (
                                    <Field
                                        name="assignmentFollowUpDueReminder"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <div className={classes.selector}>
                                                <ReminderSelector
                                                    {...input}
                                                    label="Reminder"
                                                    className={classes.textField}
                                                    onSelection={(selection: IAutoCompleteItem) => {
                                                        input.onChange(selection);
                                                    }}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                            meta.touched
                                                            ? meta.error
                                                            : ''
                                                    }
                                                    showIcon={true}
                                                />
                                            </div>
                                        )}
                                    </Field>
                                )}
                            </FormSpy>
                        </Grid>
                        <Grid item={true} xs={6} className={classes.customFieldGrid}>
                            <div>
                                <Field
                                    name="assignedBy"
                                    validate={!isTabActivity()
                                        ? form.getFieldState('assignedTo')?.value !== null ? required : undefined
                                        : undefined}
                                >
                                    {({ input, meta }) => (
                                        <div>
                                            {/* <RenderCount/> */}
                                            <UserSelector
                                                {...input}
                                                label="Assigned By"
                                                name="assignedBy"
                                                // tslint:disable-next-line: max-line-length
                                                required={!isTabActivity()
                                                    ? form.getFieldState('assignedTo')?.value !== null ? true : undefined
                                                    : false}
                                                // tslint:disable-next-line: max-line-length
                                                onSelection={(selection: IDownShiftItem, name: string) => {
                                                    // input.onChange(selection.value);
                                                    // Another approach.
                                                    onDropDownSelect(form, values, input, selection, name);
                                                }}
                                                value={values.assignedBy}
                                                displayLabel={values.assignedByName}
                                                isGuidAsValue={true}
                                                isAuthorized={true}
                                                isExternal={false}
                                            // isStaffOnly={true}
                                            />
                                        </div>
                                    )}
                                </Field>
                            </div>
                        </Grid>
                        <Grid item={true} xs={6} className={classes.customFieldGrid}>
                            <div>
                                <Field
                                    name="dateAssigned"
                                    // tslint:disable-next-line: max-line-length
                                    validate={!isTabActivity()
                                        ? form.getFieldState('assignedTo')?.value !== null ? requiredDateValidator : dateValidator
                                        : undefined}
                                    subscription={{ touched: true, error: true, value: true }}
                                >
                                    {({ input, meta }) => (
                                        <div>
                                            {/* tslint:disable-next-line: no-console */}
                                            {/* {console.log({...input})} */}
                                            <KeyboardDatePicker
                                                {...input}
                                                className={classes.textField + ' active'}
                                                id="dateAssigned"
                                                name="dateAssigned"
                                                label="Date Assigned"
                                                format="DD/MM/YYYY"
                                                placeholder="dd/mm/yyyy"
                                                // tslint:disable-next-line: max-line-length
                                                required={!isTabActivity()
                                                    ? form.getFieldState('assignedTo')?.value !== null ? true : undefined
                                                    : undefined}
                                                animateYearScrolling={true}
                                                // tslint:disable-next-line: max-line-length
                                                autoOk={true}
                                                allowKeyboardControl={false}
                                                variant={'inline'}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error}
                                                inputProps={{ autocomplete: 'off' }}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(date: MaterialUiPickersDate | null, value?: string | null) => input.onChange(date)}
                                            />
                                        </div>
                                    )}
                                </Field>
                            </div>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    };

    // tslint:disable-next-line: no-any
    const getProceeding = (form: FormApi<any>, values: AnyObject, pristine: boolean) => {
        /*
            Sometimes we want to collect customers who are not proceeding when creating an enquiry itself.
            This will be used for future reporting and improvements.
            Selecting not proceeding reasons on enquiry create is easier than doing a follow up afterwards.
        */

        const isHidePanel = props.guid ? true : false;

        return (
            <ExpansionPanel
                defaultExpanded={true}
                // expanded={false}
                onChange={(event: React.ChangeEvent<{}>, expanded: boolean) => handleExpandChange(event, expanded, form)}
                className={isHidePanel ? classes.hideDate : classes.expansionPanel}
            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.expansionPanelSummary}
                >
                    <Typography className={classes.heading}>
                        Proceeding
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    className={classes.expandablePanelDetails}
                >
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={6} className={classes.customFieldGrid}>
                            <Field
                                name="isNotProceeding"
                            >
                                {({ input, meta }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.isNotProceeding}
                                                checkedIcon={<CheckBoxIcon className={classes.checkedIcon} />}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                                    // Another approach.
                                                    onCheckBoxChanged(form, values, input, checked, 'isNotProceeding');
                                                }}
                                                name="isNotProceeding"
                                                disabled={values.assignedTo ? false : true}
                                            />
                                        }
                                        label="Not Proceeding"
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={6} className={classes.customFieldGrid}>
                            <div>
                                <Grid item={true} xs={12}>
                                    <Field
                                        name="notProceedingReason"
                                        validate={values.isNotProceeding === true && !isHidePanel ? required : undefined}
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <ReasonSelector
                                                {...input}
                                                label="Not Proceeding Reason"
                                                name="notProceedingReason"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                disablePortal={false}
                                                onSelection={(selection: IAutoCompleteItem, name: string, reasonGuid: string) => {
                                                    onDropdownChange(selection, name, input, form, reasonGuid);
                                                }}
                                                required={values.isNotProceeding === true && !isHidePanel ? true : false}
                                                disabled={values.isNotProceeding === false ? true : false}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </div>
                        </Grid>
                        {values.isShowNotProceedingReferrerInProceeding && (
                            <Grid item={true} xs={6} className={`${classes.customFieldGrid} ${classes.displayFlex}`} alignItems="flex-end">
                                <div className={classes.toggleFieldWrapepr}>
                                    <div className="field">
                                        {
                                            (values.isNotProceedingReferrerSwitchedInProceeding) ? (
                                                <Field
                                                    name="referredToFirmNameInProceeding"
                                                    // validate={required}
                                                    subscription={{ touched: true, error: true, value: true }}
                                                >
                                                    {({ input, meta }) => (
                                                        <TextField
                                                            {...input}
                                                            label="Referred To"
                                                            type="text"
                                                            // required={!isTabActivity()}
                                                            className={classes.textField}
                                                            error={
                                                                meta.error &&
                                                                meta.touched
                                                            }
                                                            autoComplete="abcd"
                                                            helperText={
                                                                meta.error &&
                                                                    meta.touched
                                                                    ? 'Referred To is required'
                                                                    : ''
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                            ) :
                                                (
                                                    <Field
                                                        name="referredToFirmSelectorInProceeding"
                                                        subscription={{ touched: true, error: true, value: true }}
                                                    >
                                                        {({ input, meta }) => (
                                                            <ReferredToSelector
                                                                {...input}
                                                                label="Referred To"
                                                                value={values.referredToFirmSelectorInProceeding}
                                                                error={meta.error && meta.touched}
                                                                helperText={
                                                                    meta.error &&
                                                                        meta.touched
                                                                        ? 'Referred To is required'
                                                                        : ''
                                                                }
                                                                onSelection={(selection: IndividualNameSummary, name: string) => {
                                                                    input.onChange(selection);
                                                                    onDropDownSelect(form, values, input, selection, name);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                )
                                        }
                                    </div>
                                    <div className="switcher">
                                        <Tooltip
                                            // placement="left-start"
                                            title={
                                                values.isNotProceedingReferrerSwitchedInProceeding ? (
                                                    <Typography color="inherit">
                                                        Switch to select an existing client & contact
                                                    </Typography>
                                                ) :
                                                    (
                                                        <Typography color="inherit">Switch to enter the referred to name</Typography>
                                                    )
                                            }
                                        >
                                            <IconButton
                                                aria-label="delete"
                                                // className={classes.margin}
                                                onClick={() => {
                                                    // Another approach.
                                                    onSwitch(form, values, 'referredToFirmInProceeding');
                                                }}
                                            >
                                                <SyncAltIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            </Grid>
                        )}
                        <Grid item={true} xs={values.isShowNotProceedingReferrerInProceeding ? 6 : 12} className={classes.customFieldGrid}>
                            <Field
                                name="notProceedingOutcomeNotesInProceeding"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <div>
                                        <TextField
                                            {...input}
                                            label="Not Proceeding Notes"
                                            type="text"
                                            className={classes.textField}
                                            error={meta.error && meta.touched}
                                            autoComplete="abcd"
                                            disabled={values.isNotProceeding === false ? true : false}
                                        />
                                    </div>
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    };

    const getCorrespondenceRequired = (values: AnyObject) => {
        if ((!values.email)
            && (!values.mobile)
            && (!values.phone)) {
            return true;
        } else if ((values.email && values.email.length === 0)
            && (values.mobile && values.mobile.length === 0)
            && (values.phone && values.phone.length === 0)) {
            return true;
        } else {
            return false;
        }
    };

    // tslint:disable-next-line: no-any
    const onContactDetailsChanged = (form: FormApi<any>, values: AnyObject) => {
        // If we picked a contact from dropdown and then change contact details, then we need to confirm with the user whether it needs to be saved.
        if (values.nameGuid && !values.isContactDetailsChanged) {
            form.batch(() => {
                form.change('isContactDetailsChanged', true);
                form.change('isUpdateContacts', true);
            });
        }
    };

    // const fetchPartyNameSummary = (value: string, form: FormApi<Enquiry>) => {
    //     const partyQueryParams: PartyQueryParams = {
    //         filter: value,
    //         first: 1,
    //         offset: 0,
    //         includeOrganisation: null,
    //         includeIndividual: true,
    //         includeStaff: null,
    //     };

    //     retrievePartyData(
    //         partyQueryParams,
    //         false,
    //         // tslint:disable-next-line: no-console
    //         (data) => onRetrieveData(data, form),
    //         // tslint:disable-next-line:no-any
    //         function (reason: any): void {
    //             showNotification(null, reason, 'error');
    //         }
    //     );
    // };

    // const onRetrieveData = (data: PartyData, form: FormApi<Enquiry>) => {

    //     const nameSummaryData = data.party.nameSummaries.nameSummary;

    //     if (nameSummaryData.length > 0) {
    //         const defaultContact = {
    //             ...nameSummaryData[0],
    //             value: nameSummaryData[0].guidID.toString(),
    //             label: nameSummaryData[0].name,
    //         };

    //         form.change('emailAssociatedContact', defaultContact);
    //     } else {
    //         form.change('emailAssociatedContact', null);
    //     }

    // };

    // const onEnquiryDataRetrieved = (data: EnquiryData, form: FormApi<Enquiry>) => {
    //     const enquirySummaryData = data.enquiry.summaryList.summaries;

    //     if (enquirySummaryData.length > 0) {
    //         const defaultEnquiry = {
    //             ...enquirySummaryData[0],
    //             value: enquirySummaryData[0].guid,
    //             label: enquirySummaryData[0].name,
    //         };

    //         form.change('emailEnquiryAssociatedContact', defaultEnquiry);
    //     } else {
    //         form.change('emailEnquiryAssociatedContact', null);
    //     }
    // };

    // const fetchEnquirySummary = (search: string, form: FormApi<Enquiry>) => {

    //     var enquiryQueryParams: EnquirySummaryQueryParams = {
    //         offset: 0,
    //         first: 1,
    //         filter: null,
    //         emailAddress: search,
    //         isIncludeCurrent: true,
    //         isIncludeClosed: true,
    //         isMyEnquiriesOnly: false,
    //         groupBy: null,
    //         groupByValue: null,
    //         followUpDue: null,
    //         newEnquiry: null,
    //         assignedTo: null,
    //         areaOfLaw: null,
    //         teamList: null,
    //         isKeyOpportunity: null,
    //         enquiryQuality: null,
    //         enquiryLikelihoodToProceed: null,
    //         meetingWith: null,
    //         status: null,
    //         sourceList: null,
    //         location: null,
    //         // sortColumn: this.sortColumn,
    //         // sortDirection: this.sortDirection
    //     };

    //     retrieveEnquirySummaryData(
    //         enquiryQueryParams,
    //         false, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
    //         (data) => onEnquiryDataRetrieved(data, form),
    //         // tslint:disable-next-line:no-any
    //         function (reason: any): void {
    //             showNotification(null, reason, 'error');
    //         }
    //     );
    // }

    const checkDuplicateEmail = debounce((form: FormApi<Enquiry>, values: AnyObject, value: string) => {

        // this is not part of the email duplciate checker but need to put it in here so that we can only use 1 function in the textfield
        // If we picked a contact from dropdown and then change contact details, then we need to confirm with the user whether it needs to be saved.
        onContactDetailsChanged(form, values);
    
        const tempValue = value;
        // tslint:disable-next-line: max-line-length no-shadow-variable
        // const emailRegex2 = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // tslint:disable-next-line: no-shadow-variable
        if (tempValue.length === 0 || (tempValue && !emailRegex.test(tempValue))) {
            form.change('duplicateCheck', null);
        } else {
            const params: DuplicateCheckParams = {
                email: tempValue,
                isIncludeEnquiry: true,
                isIncludeParty: true
            };
    
            retrieveQueryData<DuplicateCheckParams, DuplicateCheckData>(
                CrmDuplicateCheckQuery,
                params,
                false,
                (data) => onDataDupicateCheckRetrieved(data, form, values),
                // tslint:disable-next-line: no-any
                function (reason: any): void {
                    showNotification(null, reason, 'error');
                    setIsLoading(false);
                }
            );

        }
    // tslint:disable-next-line: align
    }, 500); // 500 is the debounce delay in milliseconds

    const onDataDupicateCheckRetrieved = (data: DuplicateCheckData, form: FormApi<Enquiry>, values: AnyObject) => {
        form.change('duplicateCheck', data.crm.duplicateCheck);
    };

    const onClose = () => {
        if (state.isFormChanged) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: true,
                    showTabsSaveAlert: false
                };
            });
        } else {
            onEnquiryFormClosed();
        }
    };

    const onCreateClientDialog = (open: boolean) => () => {
        setState((prevState) => {
            return {
                ...prevState,
                isCreateClientOpen: open
            };
        });
    };

    const onMarkClientMatterCreateDialog = (open: boolean) => () => {
        setState((prevState) => {
            return {
                ...prevState,
                isMarkClientMatterCreatedOpen: open
            };
        });
    };

    const onCompleteCreateClient = () => {
        const test = false;
        if (test) {
            completeCreateClient();
        }
        setState((prevState) => {
            return {
                ...prevState,
                isMarkClientMatterCreatedOpen: true
            };
        });
    };
    // for test only
    // const downloadEnquirySummary = (enquiryDetails: EnquiryDetail) => {
    //     console.log('alright');
    //     HistoryFile(enquiryDetails, 'DESC', state.currency, state.timezone, leadDetailsHistory,
    //         (results) => {
    //             // tslint:disable-next-line: no-console
    //             var url = URL.createObjectURL(results);
    //             window.open(url, '_blank', 'noopener, noreferrer');
    //         }
    //     );
    // };

    const completeCreateClient = () => {
        client.mutate({
            mutation: CompleteCreateClientMutation,
            variables: {
                input: getCompleteCreateClientInput()
            },
        })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');
                    } else {
                        showNotification('Complete Create Client Failed', results.data.error, 'error');
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Complete Create Client Failed', reason, 'error');
            });
    };

    // tslint:disable-next-line: no-any
    const onReOpen = (form: FormApi<any>, values: AnyObject) => {
        reOpenEnquiry(form, values);
    };

    const getReOpenFollowUpInput = () => {
        return {
            enquiryGuid: props.guid,
            followUpAction: 'Re-Open Enquiry',
            followUpActionSystemName: 'ReOpenEnquiry',
            followUpDate: moment().toDate(),
            outcome: 'More Follow Up',
            outcomeDate: null,
            outcomeNotes: '',
            reason: null,
            reasonGuid: null,
            followUpNotes: null,
        };
    };

    // tslint:disable-next-line: no-any
    const reOpenEnquiry = (form: FormApi<any>, values: AnyObject) => {
        client.mutate({
            mutation: FollowUpEnquiryMutation,
            variables: {
                input: getReOpenFollowUpInput()
            },
        })
            // tslint:disable-next-line: no-any
            .then((results: { data: any; }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');

                        // var link = document.getElementById('resetButton');
                        // if (link !== null) {
                        //     link.click();
                        // }
                        onReset(form, values);

                    } else {
                        showNotification('Failed to Re-Open Enquiry', results.data.error, 'error');
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => { showNotification('Failed to Re-Open Enquiry', reason, 'error'); });
    };

    const getUnlinkMatterFollowUpInput = () => {
        return {
            enquiryGuid: props.guid,
            followUpAction: 'Unlink Matter',
            followUpActionSystemName: 'UnlinkMatter',
            followUpDate: moment().toDate(),
            outcome: 'Proceeding',
            outcomeDate: null,
            outcomeNotes: '',
            reason: null,
            reasonGuid: null,
            followUpNotes: null,
        };
    };

    // tslint:disable-next-line: no-any
    const unlinkMatter = (form: FormApi<any>, values: AnyObject) => {
        client.mutate({
            mutation: FollowUpEnquiryMutation,
            variables: {
                input: getUnlinkMatterFollowUpInput()
            },
        })
            // tslint:disable-next-line: no-any
            .then((results: { data: any; }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');

                        // var link = document.getElementById('resetButton');
                        // if (link !== null) {
                        //     link.click();
                        // }
                        onReset(form, values);

                    } else {
                        showNotification('Failed to Unlink Matter', results.data.error, 'error');
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => { showNotification('Failed to Unlink Matter', reason, 'error'); });
    };

    const getCompleteCreateClientInput = () => {
        return {
            enquiryGuid: props.guid,
            matterGuid: null
        };
    };

    const isDateDue = (value: Date) => {
        const currentDate = moment();
        const dateDiff = moment(value).diff(currentDate, 'days');

        return dateDiff < 0;
    };

    const showDateEndOfFromNow = (value: Date) => {

        // return moment.utc(value).local().endOf('day').fromNow();
        return moment(value).fromNow();
    };

    const formatDate = (value: Date, format: string) => {

        // return moment.utc(value).local().format(format);
        return moment(value).format(format);
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: EnquiryFormTab) => {
        if (!state.showTabsSaveAlert) {
            setState((prevState) => {
                return {
                    ...prevState,
                    tab: newValue
                };
            });
        } else {
            showNotification('warning', 'Please save the changes before continuing', 'warning');
        }        
    };

    // tslint:disable-next-line: no-any max-line-length
    const onDropdownChange = (selection: IAutoCompleteItem, name: string, input: FieldInputProps<any, HTMLElement>, form: FormApi<any>, valueGuid: string) => {
        input.onChange(selection.label.length > 0 ? selection.label : null);

        if (name === 'reason') {
            form.change('reasonGuidID', valueGuid.length > 0
                ? valueGuid
                : null);
        } else if (name === 'notProceedingReason') {
            form.change('notProceedingReasonGuid', valueGuid.length > 0
                ? valueGuid
                : null);
        }

        if (selection.secondaryValue) {
            form.change('isShowNotProceedingReferrer', selection.secondaryValue);
            form.change('isShowNotProceedingReferrerInProceeding', selection.secondaryValue);
        } else {
            form.change('isShowNotProceedingReferrer', false);
            form.change('isShowNotProceedingReferrerInProceeding', false);

            form.change('referredToFirmSelector', null);
            form.change('referredToFirmName', '');
            form.change('referredToFirmGuid', null);
            form.change('isNotProceedingReferrerSwitched', false);

            form.change('referredToFirmSelectorInProceeding', null);
            form.change('referredToFirmNameInProceeding', '');
            form.change('referredToFirmGuidInProceeding', null);
            form.change('isNotProceedingReferrerSwitchedInProceeding', false);
        }
    };

    const onChangeEmailNotification = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setState((prevState) => {
            return {
                ...prevState,
                isEmailNotification: checked
            };
        });
    };

    // tslint:disable-next-line: no-any
    const onSendAcknowledgementEmail = (form: FormApi<Enquiry>, values: AnyObject, input: any, checked: boolean) => {
        form.batch(() => {
            form.change('isSentAcknowledgementEmail', checked);
        });
    };

    // tslint:disable-next-line: no-any
    const onKeyOpportunity = (form: FormApi<Enquiry>, values: AnyObject, input: any, checked: boolean) => {
        form.batch(() => {
            form.change('isKeyOpportunity', checked);
        });
    };

    // tslint:disable-next-line: no-any
    const onUpdateContact = (form: FormApi<Enquiry>, values: AnyObject, checked: boolean) => {
        form.batch(() => {
            form.change('isUpdateContacts', checked);
        });
    };

    // tslint:disable-next-line: no-any
    const onCheckBoxChanged = (form: FormApi<Enquiry>, values: AnyObject, input: any, checked: boolean, name: string) => {
        if (name === 'isKeyOpportunity') {
            form.batch(() => {
                form.change('isKeyOpportunity', checked);
            });
        } else if (name === 'isNotProceeding') {
            form.batch(() => {
                form.change('isNotProceeding', checked);
                form.change('isSentAcknowledgementEmail', !checked); // Do not send email if not proceeding or uncheck by default

                if (checked === false) {
                    form.change('notProceedingReason', null);
                    form.change('notProceedingReasonGuid', null);
                }
            });

            // If not proceeding, then no need to send email notification to the client. But user can check again.
            setState((prevState) => {
                return {
                    ...prevState,
                    isEmailNotification: false,
                };
            });
        }
    };

    const onSourceChanged = (form: FormApi<Enquiry>, values: AnyObject, selection: IAutoCompleteItem) => {
        if (selection && selection.secondaryValue === true) {
            form.batch(() => {
                form.change('isShowReferrer', true);
                form.change('referrerGuid', null);
                form.change('referrerName', null);
            });
        } else {
            // Check against the previously selected referrer values, if there are any
            form.batch(() => {
                form.change('isShowReferrer', false);
                form.change('referrerGuid', null);
                form.change('referrerName', null);
            });
        }
    };

    // tslint:disable-next-line: no-any max-line-length
    const onDateTimePickerChange = (input: FieldInputProps<any, HTMLElement>, formApi: FormApi<any>) => (date: MaterialUiPickersDate, value?: string | null | undefined) => {
        let defaultMeetingMinutes = 60;

        if (props.enquiryDefaultConfig && props.enquiryDefaultConfig.defaultMeetingLength) {
            var defaultMeetingLength = props.enquiryDefaultConfig.defaultMeetingLength;
            var meetingLengthSplit = defaultMeetingLength.split(':'); // split it at the colons
            defaultMeetingMinutes = (+meetingLengthSplit[0]) * 60 + (+meetingLengthSplit[1]);
        }

        input.onChange(date);
        formApi.batch(() => {
            formApi.change('endTime', moment(date).add(defaultMeetingMinutes, 'minute').toDate());
        });
    };

    // tslint:disable-next-line: no-any
    const onOutcomeDateChange = (input: FieldInputProps<any, HTMLElement>, formApi: FormApi<any>) => (date: MaterialUiPickersDate, value?: string | null | undefined) => {
        input.onChange(date);

        formApi.batch(() => {
            formApi.change('updateFollowUpDueForInternalNote', true);
            formApi.change('outcomeDateChanged', true);
        });
    };

    // tslint:disable-next-line: max-line-length no-any
    const onChangeAllDay = (input: FieldInputProps<any, HTMLElement>, formApi: FormApi<any>, values: AnyObject) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        input.onChange(checked);
        if (checked) {
            const startDate = moment(values.startTime).set({ hours: 9, minutes: 0, seconds: 0, milliseconds: 0 }).toDate();
            const endDate = moment(values.endTime).set({ hours: 17, minutes: 0, seconds: 0, milliseconds: 0 }).toDate();
            formApi.batch(() => {
                formApi.change('startTime', startDate);
                formApi.change('endTime', endDate);
            });
        }
    };

    const onReferrerChanged = (form: FormApi<Enquiry>, values: AnyObject, selection: IAutoCompleteItem) => {
        form.batch(() => {
            form.change('referrerGuid', selection ? selection.value : null);
            form.change('referrerName', selection ? selection.label : null);
        });
    };

    const onFileClicked = (file: DocumentFile) => {
        if (file && file.url) {
            var win = window.open(file.url, '_blank');
            if (win) {
                win.focus();
            }
        }
    };

    const CustomPopper = function (popperProps: PopperProps) {
        return <Popper {...popperProps} placement="top" />;
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getFileItems = (files?: DocumentFile[]) => {
        if (files) {
            const filteredFiles = files.filter((file: DocumentFile) => {
                return file.status !== 2 && file.url;
            });

            if (filteredFiles) {
                const chip = (
                    <div>
                        {
                            filteredFiles.length > 0
                                ? <Typography>{'Click on the following to open the uploaded files'}</Typography>
                                : null
                        }
                        <div className={classes.chips}>
                            {
                                filteredFiles.map((file: DocumentFile, index: number) => {
                                    return (
                                        <Chip
                                            key={index}
                                            className={classes.chip}
                                            label={file.name}
                                            onClick={() => onFileClicked(file)}
                                        />
                                    );
                                }
                                )
                            }
                        </div>
                    </div>
                );

                return chip;
            } else {
                return (<div />);
            }
        } else {
            return (<div />);
        }
    };

    // tslint:disable-next-line: no-any
    const onInputChanged = (form: FormApi<any>, name: string | any) => (value: string) => {
        form.change(name, value);
    };

    // tslint:disable-next-line: max-line-length
    const getAddressLabelForPanel = (buildingName: string, level: string, streetNumber: string, street1: string, street2: string, suburb: string, addressState: string, postCode: string, country: string) => {
        let address = buildingName ? buildingName : '';
        if (level && level.length > 0) {
            address = address.length > 0 ? (address + ', ' + level) : level;
        }
        if (streetNumber && streetNumber.length > 0) {
            address = address.length > 0 ? (address + (level && level.length > 0 ? '/' : ' ') + streetNumber) : streetNumber;
        }
        if (street1 && street1.length > 0) {
            address = address.length > 0 ? (address + ' ' + street1) : street1;
        }
        if (street2 && street2.length > 0) {
            address = address.length > 0 ? (address + ', ' + street2) : street2;
        }
        if (suburb && suburb.length > 0) {
            address = address.length > 0 ? (address + ', ' + suburb) : suburb;
        }
        if (addressState && addressState.length > 0) {
            address = address.length > 0 ? (address + ', ' + addressState) : addressState;
        }
        if (postCode && postCode.length > 0) {
            address = address.length > 0 ? (address + ', ' + postCode) : postCode;
        }
        if (country && country.length > 0) {
            address = address.length > 0 ? (address + ', ' + country) : country;
        }

        return address;
    };

    // tslint:disable-next-line: no-any
    const onSelection = (form: FormApi<Enquiry>, values: AnyObject) => (placeType: PlaceType | null, address: Address, name: string) => {

        if (name === 'street') {
            form.change('country', address.country ? address.country : values.country ? values.country : '');
            form.change('postcode', address.postalCode ? address.postalCode : values.postcode ? values.postcode : '');
            form.change('suburb', address.locality ? address.locality : values.suburb ? values.suburb : '');
            // tslint:disable-next-line: max-line-length
            form.change('state', address.administrativeAreaLevel1 ? address.administrativeAreaLevel1 : values.state ? values.state : '');
            form.change('addressName', address.premise ? address.premise : values.addressName ? values.addressName : '');
            // form.change('street', placeType?.structured_formatting.main_text);
            form.change('street', address.route ? address.route : values.street1 ? values.street1 : '');
            form.change('number', address.streetNumber ? address.streetNumber : values.number ? values.number : '');
            form.change('floor', address.floor ? address.floor : values.floor ? values.floor : '');
        }

        if (name === 'postalSuburb') {
            form.change('postalSuburb', address.locality ? address.locality : values.postalSuburb ? values.postalSuburb : '');
            form.change('postalPostCode', address.postalCode ? address.postalCode : values.postalPostCode ? values.postalPostCode : '');
            form.change('postalCountry', address.country ? address.country : values.postalCountry ? values.postalCountry : '');
            form.change('postalState', address.administrativeAreaLevel1 ? address.administrativeAreaLevel1 : values.postalState ? values.postalState : '');
        }

        if (name === 'street2') {
            form.change('street2', placeType?.structured_formatting.main_text);
        }

        // other side
        if (name === 'otherPartyStreet') {
            form.change('otherPartyCountry', address.country ? address.country : values.otherPartyCountry ? values.otherPartyCountry : '');
            form.change('otherPartyPostcode', address.postalCode ? address.postalCode : values.otherPartyPostcode ? values.otherPartyPostcode : '');
            form.change('otherPartySuburb', address.locality ? address.locality : values.otherPartySuburb ? values.otherPartySuburb : '');
            // tslint:disable-next-line: max-line-length
            form.change('otherPartyState', address.administrativeAreaLevel1 ? address.administrativeAreaLevel1 : values.otherPartyState ? values.otherPartyState : '');
            form.change('otherPartyAddressName', address.premise ? address.premise : values.otherPartyAddressName ? values.otherPartyAddressName : '');
            // form.change('otherPartyStreet', placeType?.structured_formatting.main_text);
            form.change('otherPartyStreet', address.route ? address.route : values.otherPartyStreet ? values.otherPartyStreet : '');
            form.change('otherPartyNumber', address.streetNumber ? address.streetNumber : values.otherPartyNumber ? values.otherPartyNumber : '');
            form.change('otherPartyFloor', address.floor ? address.floor : values.otherPartyFloor ? values.otherPartyFloor : '');
        }

        if (name === 'otherPartyPostalSuburb') {
            form.change('otherPartyPostalSuburb', address.locality ? address.locality : values.otherPartyPostalSuburb ? values.otherPartyPostalSuburb : '');
            form.change('otherPartyPostalPostCode', address.postalCode ? address.postalCode : values.otherPartyPostalPostCode ? values.otherPartyPostalPostCode : '');
            form.change('otherPartyPostalCountry', address.country ? address.country : values.otherPartyPostalCountry ? values.otherPartyPostalCountry : '');
            form.change('otherPartyPostalState', address.administrativeAreaLevel1 ? address.administrativeAreaLevel1 : values.otherPartyPostalState ? values.otherPartyPostalState : '');
        }

        if (name === 'otherPartyStreet2') {
            form.change('otherPartyStreet2', placeType?.structured_formatting.main_text);
        }

        // client 2
        if (name === 'secondClientStreet1') {
            form.change('secondClientCountry', address.country ? address.country : values.secondClientCountry ? values.secondClientCountry : '');
            form.change('secondClientPostCode', address.postalCode ? address.postalCode : values.secondClientPostCode ? values.secondClientPostCode : '');
            form.change('secondClientSuburb', address.locality ? address.locality : values.secondClientSuburb ? values.secondClientSuburb : '');
            // tslint:disable-next-line: max-line-length
            form.change('secondClientState', address.administrativeAreaLevel1 ? address.administrativeAreaLevel1 : values.secondClientState ? values.secondClientState : '');
            form.change('secondClientAddressName', address.premise ? address.premise : values.secondClientAddressName ? values.secondClientAddressName : '');
            // form.change('street', placeType?.structured_formatting.main_text);
            form.change('secondClientStreet1', address.route ? address.route : values.secondClientStreet1 ? values.secondClientStreet1 : '');
            form.change('secondClientNumber', address.streetNumber ? address.streetNumber : values.secondClientNumber ? values.secondClientNumber : '');
            form.change('secondClientFloor', address.floor ? address.floor : values.secondClientFloor ? values.secondClientFloor : '');
        }

        if (name === 'secondClientPostalSuburb') {
            form.change('secondClientPostalSuburb', address.locality ? address.locality : values.secondClientPostalSuburb ? values.secondClientPostalSuburb : '');
            form.change('secondClientPostalPostCode', address.postalCode ? address.postalCode : values.secondClientPostalPostCode ? values.secondClientPostalPostCode : '');
            form.change('secondClientPostalCountry', address.country ? address.country : values.secondClientPostalCountry ? values.secondClientPostalCountry : '');
            form.change('secondClientPostalState', address.administrativeAreaLevel1 ? address.administrativeAreaLevel1 :
                                                         values.secondClientPostalState ? values.secondClientPostalState : '');
        }

        if (name === 'secondClientStreet2') {
            form.change('secondClientStreet2', placeType?.structured_formatting.main_text);
        }
    };

    // tslint:disable-next-line: no-any
    const onChangeSelection = (selection: any, input: FieldInputProps<Enquiry, any>, form: FormApi<Enquiry>, name: string) => {
        if (name === 'staff') {

            input.onChange(selection);

            if (selection) {
                if (selection && selection.hasOwnProperty('entityType') && selection.entityType === EntityTypeEnum.TEAM) {
                    form.change('isSendConfirmation', false);
                }
                if (selection && selection.hasOwnProperty('entityType') && selection.entityType === EntityTypeEnum.STAFF) {
                    form.change('isSendConfirmation', true);
                }
            } else {
                form.change('isSendConfirmation', true);
            }
        }
        if (name === 'officeLocation') {
            input.onChange(selection);

            if (selection && selection.hasOwnProperty('timezone') && selection.hasOwnProperty('timezoneDescription')
                && selection.timezone && selection.timezoneDescription) {
                const timezoneSelector = {
                    label: selection.timezoneDescription,
                    value: selection.timezone,
                    secondaryValue: selection.baseUtcOffset
                };
                form.change('timezone', timezoneSelector);
            } else {

                const { timeZone, timeZoneDescription, baseUtcOffset } = regionSettings.data!.settings.systems.regionSettings.config;

                const timezoneSelector = {
                    label: timeZoneDescription,
                    value: timeZone,
                    secondaryValue: baseUtcOffset,
                };

                form.change('timezone', timezoneSelector);
            }
        }
    };

    const clientAddressPanel = (defaultExpanded: boolean, form: FormApi<Enquiry>) => {
        return (
            <FormSpy subscription={{ values: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Accordion
                        defaultExpanded={defaultExpanded}
                    // disabled={disabledAddress(values)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className={classes.AccordionSummary}
                        >
                            <Typography className={classes.heading}>
                                <ContactMailIcon className={classes.headingIcon} />
                                Main Address
                            </Typography>

                            <div className={classes.infoContainer}>
                                <div className="col">
                                    <div className="value">
                                        {getAddressLabelForPanel(
                                            values.addressName,
                                            values.floor,
                                            values.number,
                                            values.street,
                                            values.street2,
                                            values.suburb,
                                            values.state,
                                            values.postcode,
                                            values.country
                                        )}
                                    </div>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails
                            className={classes.expandablePanelDetails}
                        >
                            <Grid container={true} spacing={3}>
                                <Grid item={true} md={6} xs={12} className={classes.customFieldGrid}>
                                    <Field
                                        name="addressName"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Building name"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Building name is required'
                                                        : ''
                                                }
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={3} className={classes.customFieldGrid}>
                                    <Field
                                        name="floor"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Unit/Level"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Unit/Level is required'
                                                        : ''
                                                }
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={3} className={classes.customFieldGrid}>
                                    <Field
                                        name="number"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Number"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Number is required'
                                                        : ''
                                                }
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={6} className={classes.customFieldGrid}>
                                    <Field
                                        name="street"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <AddressSelector
                                                {...input}
                                                freeSolo={true}
                                                label="Street 1"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                name="street"
                                                homeCountry={props.homeCountry}
                                                // tslint:disable-next-line: max-line-length
                                                // placeTypes={['geocode', 'establishment']} // ['geocode', 'address', 'establishment', '(regions)', '(cities)'];
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Street 1 is required'
                                                        : ''
                                                }
                                                value={values.street}
                                                onSelection={onSelection(form, values)}
                                                onInputChanged={onInputChanged(form, 'street')}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={6} className={classes.customFieldGrid}>
                                    <Field
                                        name="street2"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <AddressSelector
                                                {...input}
                                                freeSolo={true}
                                                label="Street 2"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                name="street2"
                                                homeCountry={props.homeCountry}
                                                // tslint:disable-next-line: max-line-length
                                                // placeTypes={['geocode', 'establishment']} // ['geocode', 'address', 'establishment', '(regions)', '(cities)'];
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Street 2 is required'
                                                        : ''
                                                }
                                                onSelection={onSelection(form, values)}
                                                onInputChanged={onInputChanged(form, 'street2')}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="suburb"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="City/Suburb"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'City/Suburb is required'
                                                        : ''
                                                }
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="state"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="State"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'State is required'
                                                        : ''
                                                }
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="postcode"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Postal Code"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Postal Code is required'
                                                        : ''
                                                }
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} className={classes.customFieldGrid}>
                                    <Field
                                        name="country"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Country"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Country is required'
                                                        : ''
                                                }
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )}
            </FormSpy>
        );
    };

    const otherPartyAddressPanel = (defaultExpanded: boolean, form: FormApi<Enquiry>) => {
        return (
            <FormSpy subscription={{ values: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Accordion
                        defaultExpanded={defaultExpanded}
                    // disabled={disabledAddress(values)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className={classes.AccordionSummary}
                        >
                            <Typography className={classes.heading}>
                                <ContactMailIcon className={classes.headingIcon} />
                                Main Address
                            </Typography>

                            <div className={classes.infoContainer}>
                                <div className="col">
                                    <div className="value">
                                        {getAddressLabelForPanel(
                                            values.otherPartyAddressName,
                                            values.otherPartyFloor,
                                            values.otherPartyNumber,
                                            values.otherPartyStreet,
                                            values.otherPartyStreet2,
                                            values.otherPartySuburb,
                                            values.otherPartyState,
                                            values.otherPartyPostcode,
                                            values.otherPartyCountry
                                        )}
                                    </div>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails
                            className={classes.expandablePanelDetails}
                        >
                            <Grid container={true} spacing={3}>
                                <Grid item={true} md={6} xs={12} className={classes.customFieldGrid}>
                                    <Field
                                        name="otherPartyAddressName"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Building name"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                disabled={values.otherPartyNameGuid}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Building name is required'
                                                        : ''
                                                }
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={3} className={classes.customFieldGrid}>
                                    <Field
                                        name="otherPartyFloor"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Unit/Level"
                                                type="text"
                                                className={classes.textField}
                                                disabled={values.otherPartyNameGuid}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Unit/Level is required'
                                                        : ''
                                                }
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={3} className={classes.customFieldGrid}>
                                    <Field
                                        name="otherPartyNumber"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Number"
                                                type="text"
                                                className={classes.textField}
                                                disabled={values.otherPartyNameGuid}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Number is required'
                                                        : ''
                                                }
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={6} className={classes.customFieldGrid}>
                                    <Field
                                        name="otherPartyStreet"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <AddressSelector
                                                {...input}
                                                freeSolo={true}
                                                label="Street 1"
                                                className={classes.textField}
                                                disabled={values.otherPartyNameGuid}
                                                error={meta.error && meta.touched}
                                                name="otherPartyStreet"
                                                homeCountry={props.homeCountry}
                                                // tslint:disable-next-line: max-line-length
                                                // placeTypes={['geocode', 'establishment']} // ['geocode', 'address', 'establishment', '(regions)', '(cities)'];
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Street 1 is required'
                                                        : ''
                                                }
                                                value={values.otherPartyStreet}
                                                onSelection={onSelection(form, values)}
                                                onInputChanged={onInputChanged(form, 'otherPartyStreet')}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={6} className={classes.customFieldGrid}>
                                    <Field
                                        name="otherPartyStreet2"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <AddressSelector
                                                {...input}
                                                freeSolo={true}
                                                label="Street 2"
                                                className={classes.textField}
                                                disabled={values.otherPartyNameGuid}
                                                error={meta.error && meta.touched}
                                                name="otherPartyStreet2"
                                                homeCountry={props.homeCountry}
                                                // tslint:disable-next-line: max-line-length
                                                // placeTypes={['geocode', 'establishment']} // ['geocode', 'address', 'establishment', '(regions)', '(cities)'];
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Street 2 is required'
                                                        : ''
                                                }
                                                onSelection={onSelection(form, values)}
                                                onInputChanged={onInputChanged(form, 'otherPartyStreet2')}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="otherPartySuburb"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="City/Suburb"
                                                type="text"
                                                className={classes.textField}
                                                disabled={values.otherPartyNameGuid}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'City/Suburb is required'
                                                        : ''
                                                }
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="otherPartyState"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="State"
                                                type="text"
                                                className={classes.textField}
                                                disabled={values.otherPartyNameGuid}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'State is required'
                                                        : ''
                                                }
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="otherPartyPostcode"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Postal Code"
                                                type="text"
                                                className={classes.textField}
                                                disabled={values.otherPartyNameGuid}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Postal Code is required'
                                                        : ''
                                                }
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} className={classes.customFieldGrid}>
                                    <Field
                                        name="otherPartyCountry"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Country"
                                                type="text"
                                                className={classes.textField}
                                                disabled={values.otherPartyNameGuid}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Country is required'
                                                        : ''
                                                }
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )}
            </FormSpy>
        );
    };

    // tslint:disable-next-line: max-line-length
    const clientPostalAddressPanel = (defaultExpanded: boolean, form: FormApi<Enquiry>) => {
        return (
            <FormSpy subscription={{ values: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Accordion
                        // defaultExpanded={defaultExpanded}
                        expanded={state.isPostalAddressPanelExpand}
                        onChange={onExpandPanel('isPostalAddressPanelExpand')}
                    // disabled={disabledAddress(values)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className={classes.AccordionSummary}
                        >
                            <Typography className={classes.heading}>
                                <LocalPostOfficeIcon className={classes.headingIcon} />
                                Postal Address
                            </Typography>

                            <div className={classes.infoContainer}>
                                <div className="col">
                                    <div className="value">
                                        {/* {getAddressLabelForPanel} */}
                                        {values.pOBoxType ? `${values.pOBoxType} ` : ''}
                                        {values.pOBox ? `${values.pOBox}, ` : ''}
                                        {values.postalSuburb ? `${values.postalSuburb}, ` : ''}
                                        {values.postalState ? `${values.postalState}, ` : ''}
                                        {values.postalPostCode ? `${values.postalPostCode}, ` : ''}
                                        {values.postalCountry ? `${values.postalCountry}` : ''}
                                    </div>
                                </div>
                            </div>

                        </AccordionSummary>
                        <AccordionDetails
                            className={classes.expandablePanelDetails}
                        >
                            <Grid container={true} spacing={3}>
                                <Grid
                                    item={true}
                                    xs={4}
                                    className={classes.customFieldGrid}
                                >
                                    <Field
                                        name="pOBoxType"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <POBoxTypeSelector
                                                // {...input}
                                                value={input.value ? { label: input.value, value: input.value } : null}
                                                label="PO Box Type"
                                                // required={true}
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                // tslint:disable-next-line: max-line-length
                                                onSelection={(selection: IAutoCompleteItem, name: string) => {
                                                    input.onChange(selection ? selection.value : null);
                                                }}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Field is required'
                                                        : ''
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={8} className={classes.customFieldGrid}>
                                    <Field
                                        name="pOBox"
                                        subscription={{ touched: true, error: true, value: true }}
                                        validate={isPoBoxRequired(values) ? required : undefined}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Number"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                required={isPoBoxRequired(values) ? true : false}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Number is required'
                                                        : ''
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="postalSuburb"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <AddressSelector
                                                {...input}
                                                freeSolo={true}
                                                label="Suburb"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                homeCountry={props.homeCountry}
                                                // tslint:disable-next-line: max-line-length
                                                // placeTypes={['geocode', 'establishment']} // ['geocode', 'address', 'establishment', '(regions)', '(cities)'];
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Suburb is required'
                                                        : ''
                                                }
                                                placeTypes={['(cities)']}
                                                onSelection={onSelection(form, values)}
                                                onInputChanged={onInputChanged(form, 'postalSuburb')}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="postalState"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="State"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'State is required'
                                                        : ''
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="postalPostCode"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Postal Code"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Postal Code is required'
                                                        : ''
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} className={classes.customFieldGrid}>
                                    <Field
                                        name="postalCountry"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Country"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                // disabled={props.sourceSystem?.toLowerCase() === 'leap'}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Country is required'
                                                        : ''
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )}
            </FormSpy>
        );
    };

    const otherPartyPostalAddressPanel = (defaultExpanded: boolean, form: FormApi<Enquiry>) => {
        return (
            <FormSpy subscription={{ values: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Accordion
                        // defaultExpanded={defaultExpanded}
                        expanded={state.isOtherSidePostalAddressPanelExpand}
                        onChange={onExpandPanel('isOtherSidePostalAddressPanelExpand')}
                    // disabled={disabledAddress(values)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className={classes.AccordionSummary}
                        >
                            <Typography className={classes.heading}>
                                <LocalPostOfficeIcon className={classes.headingIcon} />
                                Postal Address
                            </Typography>

                            <div className={classes.infoContainer}>
                                <div className="col">
                                    <div className="value">
                                        {/* {getAddressLabelForPanel} */}
                                        {values.otherPartyPOBoxType ? `${values.otherPartyPOBoxType} ` : ''}
                                        {values.otherPartyPOBox ? `${values.otherPartyPOBox}, ` : ''}
                                        {values.otherPartyPostalSuburb ? `${values.otherPartyPostalSuburb}, ` : ''}
                                        {values.otherPartyPostalState ? `${values.otherPartyPostalState}, ` : ''}
                                        {values.otherPartyPostalPostCode ? `${values.otherPartyPostalPostCode}, ` : ''}
                                        {values.otherPartyPostalCountry ? `${values.otherPartyPostalCountry}` : ''}
                                    </div>
                                </div>
                            </div>

                        </AccordionSummary>
                        <AccordionDetails
                            className={classes.expandablePanelDetails}
                        >
                            <Grid container={true} spacing={3}>
                                <Grid
                                    item={true}
                                    xs={4}
                                    className={classes.customFieldGrid}
                                >
                                    <Field
                                        name="otherPartyPOBoxType"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <POBoxTypeSelector
                                                // {...input}
                                                value={input.value ? { label: input.value, value: input.value } : null}
                                                label="PO Box Type"
                                                // required={true}
                                                className={classes.textField}
                                                disabled={values.otherPartyNameGuid}
                                                error={meta.error && meta.touched}
                                                // tslint:disable-next-line: max-line-length
                                                onSelection={(selection: IAutoCompleteItem, name: string) => {
                                                    input.onChange(selection ? selection.value : null);
                                                }}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Field is required'
                                                        : ''
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={8} className={classes.customFieldGrid}>
                                    <Field
                                        name="otherPartyPOBox"
                                        subscription={{ touched: true, error: true, value: true }}
                                        validate={isOtherPartyPoBoxRequired(values) ? required : undefined}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Number"
                                                type="text"
                                                className={classes.textField}
                                                disabled={values.otherPartyNameGuid}
                                                error={meta.error && meta.touched}
                                                required={isOtherPartyPoBoxRequired(values) ? true : false}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Number is required'
                                                        : ''
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="otherPartyPostalSuburb"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <AddressSelector
                                                {...input}
                                                freeSolo={true}
                                                label="Suburb"
                                                className={classes.textField}
                                                disabled={values.otherPartyNameGuid}
                                                error={meta.error && meta.touched}
                                                homeCountry={props.homeCountry}
                                                // tslint:disable-next-line: max-line-length
                                                // placeTypes={['geocode', 'establishment']} // ['geocode', 'address', 'establishment', '(regions)', '(cities)'];
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Suburb is required'
                                                        : ''
                                                }
                                                placeTypes={['(cities)']}
                                                onSelection={onSelection(form, values)}
                                                onInputChanged={onInputChanged(form, 'otherPartyPostalSuburb')}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="otherPartyPostalState"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="State"
                                                type="text"
                                                className={classes.textField}
                                                disabled={values.otherPartyNameGuid}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'State is required'
                                                        : ''
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="otherPartyPostalPostCode"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Postal Code"
                                                type="text"
                                                className={classes.textField}
                                                disabled={values.otherPartyNameGuid}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Postal Code is required'
                                                        : ''
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} className={classes.customFieldGrid}>
                                    <Field
                                        name="otherPartyPostalCountry"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Country"
                                                type="text"
                                                className={classes.textField}
                                                disabled={values.otherPartyNameGuid}
                                                error={meta.error && meta.touched}
                                                // disabled={props.sourceSystem?.toLowerCase() === 'leap'}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Country is required'
                                                        : ''
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )}
            </FormSpy>
        );
    };

    const countValidFiles = (data: DocumentFile[]): number => {
  
        let validFileCount = 0;
      
        for (const item of data) {
          if ((item.url && item.url.length > 0) || (item.file && Object.keys(item.file).length > 0)) {
            validFileCount++;
          }
        }
      
        return validFileCount;
    };

    const documentsPanel = (form: FormApi<Enquiry>, values: AnyObject) => {
        return (
            <ExpansionPanel
                className={classes.expansionPanel}
                defaultExpanded={false}
            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.expansionPanelSummary}
                >
                    <Typography className={classes.heading}>
                        Documents
                    </Typography>
                    <div className={classes.infoContainer}>
                        <div className="col">
                            <Field name="files">
                                {(
                                    { input } /* FieldRenderProps */
                                ) => {

                                    const files: DocumentFile[] = input.value;

                                    return (
                                        <div className="value files">
                                            {countValidFiles(files) > 0 && `${formatNumber(countValidFiles(files))} file(s)`}
                                        </div>
                                    );
                                }}
                            </Field>
                        </div>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    className={classes.expandablePanelDetails}
                >
                    <Grid container={true} spacing={3}>
                        <Grid
                            item={true}
                            xs={12}
                            className={classes.customFieldGrid}
                        >
                            <div>
                                {getFileItems(values.files)}
                            </div>
                        </Grid>
                        <Grid
                            item={true}
                            xs={12}
                            className={classes.customFieldGrid + ' ' + classes.dropZone}
                        >
                            <Field
                                name="files"
                            // subscription={{touched: true, error: true, value: true}}
                            >
                                {({ input, meta }) => (
                                    <DropzoneArea
                                        {...input}
                                        inputProps={{
                                            className: classes.dropZone
                                        }}
                                        key={filesKey}
                                        maxFileSize={15000000}
                                        // acceptedFiles={['.msg', '.jpg', '.jpeg', '.png', '.docx', '.zip']}
                                        showFileNames={true}
                                        useChipsForPreview={true}
                                        filesLimit={10}
                                        dropzoneText="To add a file, drag and drop or click in this box.
                                        Click the 'X' on a file to delete.
                                        Click the filename to replace it.
                                        View a file using the links above.
                                        These links will become available once the enquiry is saved."
                                        dropzoneClass={classes.dropZoneFont}
                                        // tslint:disable-next-line: no-shadowed-variable
                                        onChange={(files: File[]) => {
                                            let existingFiles: DocumentFile[] = [...input.value];
                                            const newFiles = files.filter((file: File) => {
                                                return !existingFiles.some((item: DocumentFile) => {
                                                    return item.name === file.name && item.status !== 2;
                                                });
                                            });
                                            if (newFiles && newFiles.length > 0) {
                                                newFiles.forEach((file: File) => {
                                                    const documentFile: DocumentFile = {
                                                        guidID: null,
                                                        file: file,
                                                        status: 1,
                                                        name: file.name,
                                                        url: ''
                                                    };
                                                    existingFiles.push(documentFile);
                                                });
                                                input.onChange(existingFiles);
                                            }
                                        }}
                                        onDelete={(file: File) => {
                                            let existingFiles: DocumentFile[] = [...input.value];
                                            // eslint-disable-next-line array-callback-return
                                            existingFiles.map((item: DocumentFile) => {
                                                if (file.name === item.name) {
                                                    item.file = file;
                                                    item.status = 2; // deleted
                                                }
                                            });
                                            // tslint:disable-next-line: max-line-length
                                            const filteredFiles = existingFiles.filter((item: DocumentFile) => {
                                                return item.status !== 2 || item.guidID !== null;
                                            });
                                            input.onChange(filteredFiles);
                                        }}
                                        // tslint:disable-next-line
                                        onDrop={(files: File[], event: any) => {
                                            // let existingFiles: DocumentFile[] = [...input.value];
                                            // tslint:disable-next-line: max-line-length
                                            // const existingFile = existingFiles.find((item: DocumentFile) => item.name === file)
                                            // tslint:disable-next-line:no-console
                                            console.log(event);
                                        }}
                                        onDropRejected={() => {
                                            // return input.errorNotice('Maximum file upload size is 15MB');
                                        }}
                                        initialFiles={input.value ? input.value.map((file: { url: string; }) => file.url) : []}
                                    />
                                )}
                            </Field>

                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    };

    const otherSidePanel = (form: FormApi<Enquiry>, values: AnyObject) => {

        const panelTitle = addEllipsis(arrayToString([
            arrayToStringWithSeparator([
                values.otherPartyTitle && values.otherPartyTitle.trim().length > 0 && values.otherPartyTitle !== 'None' ? `${values.otherPartyTitle}` : '',
                values.otherPartyFirstName,
                values.otherPartyLastName,
            // tslint:disable-next-line: align
            ], ' '),
            arrayToStringWithSeparator([
                arrayToStringWithSeparator([
                    values.otherPartyFloor,
                    values.otherPartyNumber,
                // tslint:disable-next-line: align
                ], '/'),
                values.otherPartyStreet,
            // tslint:disable-next-line: align
            ], ' '),
            values.otherPartyStreet2,
            values.otherPartySuburb,
            values.otherPartyState,
            values.otherPartyPostcode,
            values.otherPartyCountry
            // tslint:disable-next-line: align
        ]), 70);
        
        return (
            <ExpansionPanel
                className={classes.expansionPanel}
                expanded={state.isOtherSidePanelExpand}
                onChange={onExpandPanel('isOtherSidePanelExpand')}
            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.expansionPanelSummary}
                >
                    <Typography className={classes.heading}>
                        Other Side
                    </Typography>
                    <div className={classes.infoContainer}>
                        <div className="col">
                            {panelTitle}
                        </div>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    className={classes.expandablePanelDetails}
                >
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <Field 
                                name="otherPartyTitle" 
                                // validate={required} 
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <>
                                        <TitleAutocompleteSelector
                                            {...input}
                                            label="Title"
                                            className={classes.textField}
                                            error={meta.error && meta.touched}
                                            value={input.value ? { label: input.value, value: input.value } : undefined}
                                            // tslint:disable-next-line: max-line-length
                                            onSelection={(selection: IAutoCompleteItem, name: string) => {
                                                input.onChange(selection ? selection.value : '');
                                            }}
                                            // required={true}
                                            helperText={meta.error && meta.touched ? 'Title is required' : ''}
                                        />
                                    </>
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <Field
                                name="otherPartyFirstName"
                                // validate={required}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Given Name(s)"
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        disabled={values.otherPartyNameGuid}
                                        autoComplete="abcd"
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? 'Given Name is required'
                                                : ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <div className={classes.toggleFieldWrapepr}>
                                <div className="field">
                                    {
                                        values.isOtherPartyNameSwitched ? (
                                            <Field
                                                name="otherPartyIndividualSelector"
                                                subscription={{ touched: true, error: true, value: true }}
                                            >
                                                {({ input, meta }) => (
                                                    <IndividualSelector
                                                        {...input}
                                                        key="otherPartyIndividualSelector"
                                                        label="Surname"
                                                        value={values.otherPartyIndividualSelector}
                                                        error={meta.error && meta.touched}
                                                        // helperText={
                                                        //     meta.error &&
                                                        //         meta.touched
                                                        //         ? 'Surname is required'
                                                        //         : ''
                                                        // }
                                                        onSelection={(selection: IndividualNameSummary, name: string) => {
                                                            input.onChange(selection);
                                                            onDropDownSelect(form, values, input, selection, 'otherPartyIndividualSelector');
                                                            onGetPartyAddresses(form, values, selection, 'otherPartyIndividualSelector');
                                                        }}
                                                        enableSecondary={true}
                                                    />
                                                )}
                                            </Field>
                                        ) :
                                            (
                                                <Field
                                                    name="otherPartyLastName"
                                                    // validate={required}
                                                    subscription={{ touched: true, error: true, value: true }}
                                                >
                                                    {({ input, meta }) => (
                                                        <TextField
                                                            {...input}
                                                            label="Surname"
                                                            className={classes.textField}
                                                            error={meta.error && meta.touched}
                                                            disabled={values.otherPartyNameGuid}
                                                            autoComplete="abcd"
                                                            helperText={
                                                                meta.error &&
                                                                    meta.touched
                                                                    ? 'Surname is required'
                                                                    : ''
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                            )
                                    }
                                </div>
                                <div className="switcher">
                                    <Tooltip
                                        // placement="left-start"
                                        title={
                                            values.isOtherPartyNameSwitched ? (
                                                <Typography color="inherit">Switch to select an existing client & contact</Typography>
                                            ) :
                                                (
                                                    <Typography color="inherit">Switch to enter the last name</Typography>
                                                )
                                        }
                                    >
                                        <IconButton
                                            aria-label="delete"
                                            // className={classes.margin}
                                            onClick={() => {
                                                // Another approach.
                                                onSwitch(form, values, 'otherPartyLastName');
                                            }}
                                        >
                                            <SyncAltIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>                            
                        </Grid>
                        <Grid item={true} xs={6} className={classes.customFieldGrid}>
                            <Field
                                name="otherPartyEmail"
                                type="email"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <div>
                                        <TextField
                                            {...input}
                                            label="Email"
                                            type="email"
                                            className={classes.textField}
                                            disabled={values.otherPartyNameGuid}
                                            // tslint:disable-next-line: no-any
                                            onChange={(event: any) => {
                                                input.onChange(event.target.value);
                                            }}
                                            error={
                                                meta.error &&
                                                meta.touched
                                            }
                                            autoComplete="abcd"
                                            helperText={
                                                meta.error &&
                                                    meta.touched
                                                    ? meta.error
                                                    : ''
                                            }
                                        />
                                    </div>
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={6} className={classes.customFieldGrid}>
                            <Field
                                name="otherPartyPhone"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Telephone"
                                        type="text"
                                        // tslint:disable-next-line: no-any
                                        onChange={(event: any) => {
                                            input.onChange(event.target.value);
                                        }}
                                        // required={true}
                                        className={classes.textField}
                                        disabled={values.otherPartyNameGuid}
                                        error={meta.error && meta.touched}
                                        autoComplete="abcd"
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? meta.error // 'Phone is required'
                                                : ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={6} className={classes.customFieldGrid}>
                            <Field
                                name="otherPartyMobile"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Mobile"
                                        type="text"
                                        // tslint:disable-next-line: no-any
                                        onChange={(event: any) => {
                                            input.onChange(event.target.value);
                                        }}
                                        // required={true}
                                        className={classes.textField}
                                        disabled={values.otherPartyNameGuid}
                                        error={meta.error && meta.touched}
                                        autoComplete="abcd"
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? meta.error // 'Mobile is required'
                                                : ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <Field
                                name="otherPartyDOB"
                                validate={birthDateValidator}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <KeyboardDatePicker
                                        {...input}
                                        value={input && input.value ? input.value : null}
                                        className={classes.textField}
                                        label="Birthdate"
                                        format="DD/MM/YYYY"
                                        placeholder="dd/mm/yyyy"
                                        animateYearScrolling={true}
                                        disabled={values.otherPartyNameGuid}
                                        // tslint:disable-next-line: max-line-length
                                        autoOk={true}
                                        allowKeyboardControl={false}
                                        variant={'inline'}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error}
                                        inputProps={{ autocomplete: 'off' }}
                                        InputLabelProps={{ shrink: true }}
                                        maxDate={new Date()}
                                        onChange={(date: MaterialUiPickersDate | null, value?: string | null) => input.onChange(date)}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={6} className={classes.customFieldGrid}>
                            <Field
                                name="otherPartyOrganisation"
                                // validate={required}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        multiline={true}
                                        label={t('Organisation')}
                                        className={classes.textField}
                                        disabled={values.otherPartyNameGuid}
                                        error={meta.error && meta.touched}
                                        autoComplete="abcd"
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? 'Field is required'
                                                : ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={12} className={classes.customFieldGrid}>
                            <Field
                                name="otherPartyNotes"
                                // validate={required}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        multiline={true}
                                        label="Notes"
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        autoComplete="abcd"
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? 'Notes is required'
                                                : ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={12}>
                            {otherPartyAddressPanel(true, form)}
                        </Grid>
                        <Grid item={true} xs={12} md={12}>
                            {otherPartyPostalAddressPanel(false, form)}
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    };

    const detailedEnquiryNotesPanel = (form: FormApi<Enquiry>, values: AnyObject) => {
        return (
            <ExpansionPanel
                className={classes.expansionPanel}
                expanded={state.isDetailedEnquiryNotesPanelExpand}
                onChange={onExpandPanel('isDetailedEnquiryNotesPanelExpand')}
            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.expansionPanelSummary}
                >
                    <Typography className={classes.heading}>
                        Detailed Enquiry Notes
                    </Typography>
                    <div className={classes.infoContainer}>
                        <div className="col">
                            {addEllipsis(values.responseText ?? '', 70)}
                        </div>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    className={classes.expandablePanelDetails}
                >
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} md={12} className={classes.customFieldGrid}>
                            <Field
                                name="responseText"
                                // validate={required}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        multiline={true}
                                        label="Notes"
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        autoComplete="abcd"
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? 'Notes is required'
                                                : ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    };

    const otherContactDetailsPanel = (form: FormApi<Enquiry>, values: AnyObject) => {
        return (
            <ExpansionPanel
                className={classes.expansionPanel}
                expanded={state.isOtherContactDetailsPanelExpand}
                onChange={onExpandPanel('isOtherContactDetailsPanelExpand')}
            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.expansionPanelSummary}
                >
                    <Typography className={classes.heading}>
                        Client Contact Details - where different to the Enquirer
                    </Typography>
                    {/* <div className={classes.infoContainer}>
                        <div className="col">
                            {panelTitle}
                        </div>
                    </div> */}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    className={classes.expandablePanelDetails}
                >
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <Field 
                                name="clientTitle" 
                                // validate={required} 
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <>
                                        <TitleAutocompleteSelector
                                            {...input}
                                            label="Title"
                                            className={classes.textField}
                                            error={meta.error && meta.touched}
                                            value={input.value ? { label: input.value, value: input.value } : undefined}
                                            // tslint:disable-next-line: max-line-length
                                            onSelection={(selection: IAutoCompleteItem, name: string) => {
                                                input.onChange(selection ? selection.value : '');
                                            }}
                                            // required={true}
                                            helperText={meta.error && meta.touched ? 'Title is required' : ''}
                                        />
                                    </>
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <Field
                                name="clientFirstName"
                                // validate={required}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Client Given Name(s)"
                                        multiline={true}
                                        // required={true}
                                        disabled={values.clientGuid}
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        autoComplete="abcd"
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? 'Client Given Name(s) is required'
                                                : ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>

                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <div className={classes.toggleFieldWrapepr}>
                                <div className="field">
                                    {
                                        values.isClientNameSwitched ? (
                                            <Field
                                                name="clientIndividualSelector"
                                                subscription={{ touched: true, error: true, value: true }}
                                            >
                                                {({ input, meta }) => (
                                                    <IndividualSelector
                                                        {...input}
                                                        key="clientIndividualSelector"
                                                        label="Individual Name"
                                                        value={values.clientIndividualSelector}
                                                        error={meta.error && meta.touched}
                                                        helperText={
                                                            meta.error &&
                                                                meta.touched
                                                                ? 'Individual Name is required'
                                                                : ''
                                                        }
                                                        onSelection={(selection: IndividualNameSummary, name: string) => {
                                                            input.onChange(selection);
                                                            onDropDownSelect(form, values, input, selection, 'clientIndividualSelector');
                                                            onGetPartyAddresses(form, values, selection, 'clientIndividualSelector');
                                                        }}
                                                        enableSecondary={true}
                                                        includeOrganisation={false}
                                                    // value={input.value}
                                                    />
                                                )}
                                            </Field>
                                        ) :
                                            (
                                                <Field
                                                    name="clientLastName"
                                                    // validate={!isTabActivity() ? required : undefined}
                                                    subscription={{ touched: true, error: true, value: true }}
                                                >
                                                    {({ input, meta }) => (
                                                        <TextField
                                                            {...input}
                                                            label="Client Surname"
                                                            type="text"
                                                            // required={!isTabActivity()}
                                                            className={classes.textField}
                                                            error={
                                                                meta.error &&
                                                                meta.touched
                                                            }
                                                            disabled={values.clientGuid}
                                                            autoComplete="abcd"
                                                            helperText={
                                                                meta.error &&
                                                                    meta.touched
                                                                    ? 'Client Surname is required'
                                                                    : ''
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                            )
                                    }
                                </div>
                                <div className="switcher">
                                    <Tooltip
                                        // placement="left-start"
                                        title={
                                            values.isClientNameSwitched ? (
                                                <Typography color="inherit">Switch to select an existing client & contact</Typography>
                                            ) :
                                                (
                                                    <Typography color="inherit">Switch to enter the last name</Typography>
                                                )
                                        }
                                    >
                                        <IconButton
                                            aria-label="delete"
                                            // className={classes.margin}
                                            onClick={() => {
                                                // Another approach.
                                                onSwitch(form, values, 'clientLastName');
                                            }}
                                        >
                                            <SyncAltIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </Grid>

                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <Field
                                name="clientRelationshipToEnquirer"
                                // validate={required}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label={t('Relationship to the enquirer')}
                                        multiline={true}
                                        // required={true}
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        autoComplete="abcd"
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? `${t('Relationship to the enquirer')} is required`
                                                : ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <Field
                                name="clientEmail"
                                type="email"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <div>
                                        <TextField
                                            {...input}
                                            label="Email"
                                            type="email"
                                            // required={!isTabActivity() ? getCorrespondenceRequired(values) : undefined}
                                            className={classes.textField}
                                            // tslint:disable-next-line: no-any
                                            onChange={(event: any) => {
                                                input.onChange(event.target.value);
                                                onContactDetailsChanged(form, values);
                                            }}
                                            error={
                                                meta.error &&
                                                meta.touched
                                            }
                                            autoComplete="abcd"
                                            helperText={
                                                meta.error &&
                                                    meta.touched
                                                    ? meta.error
                                                    : ''
                                            }
                                        />
                                    </div>
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <Field
                                name="clientPhone"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Telephone"
                                        type="text"
                                        // tslint:disable-next-line: no-any
                                        onChange={(event: any) => {
                                            input.onChange(event.target.value);
                                            onContactDetailsChanged(form, values);
                                        }}
                                        // required={true}
                                        // required={!isTabActivity() ? getCorrespondenceRequired(values) : undefined}
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        autoComplete="abcd"
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? meta.error // 'Phone is required'
                                                : ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <Field
                                name="clientMobile"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Mobile"
                                        type="text"
                                        // tslint:disable-next-line: no-any
                                        onChange={(event: any) => {
                                            input.onChange(event.target.value);
                                            onContactDetailsChanged(form, values);
                                        }}
                                        // required={true}
                                        // required={!isTabActivity() ? getCorrespondenceRequired(values) : undefined}
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        autoComplete="abcd"
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? meta.error // 'Mobile is required'
                                                : ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    };

    const otherDetailsPanel = (form: FormApi<Enquiry>, values: AnyObject) => {
        const panelTitle = addEllipsis(arrayToString([
            arrayToStringWithSeparator([
                values.clientTitle && values.clientTitle.trim().length > 0 && values.clientTitle !== 'None' ? `${values.clientTitle}` : '',
                values.clientFirstName,
                values.clientLastName,
            ],                         ' '),
            values.clientRelationshipToEnquirer,
            values.addressName,
            arrayToStringWithSeparator([
                arrayToStringWithSeparator([
                    values.floor,
                    values.number,
                ],                         '/'),
                values.street,
            ],                         ' '),
            values.street2,
            values.suburb,
            values.state,
            values.postcode,
            values.country
        // tslint:disable-next-line: align
        ]), 70);

        return (
            <ExpansionPanel
                className={classes.expansionPanel}
                expanded={state.isOtherDetailsPanelExpand}
                onChange={onExpandPanel('isOtherDetailsPanelExpand')}
            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.expansionPanelSummary}
                >
                    <Typography className={classes.heading}>
                        Client Details
                    </Typography>
                    <div className={classes.infoContainer}>
                        <div className="col">
                            {panelTitle}
                        </div>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    className={classes.expandablePanelDetails}
                >
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} md={12}>
                            {otherContactDetailsPanel(form, values)}
                        </Grid>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <Field
                                name="dOB"
                                validate={birthDateValidator}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <KeyboardDatePicker
                                        {...input}
                                        value={input && input.value ? input.value : null}
                                        className={classes.textField}
                                        label="Birthdate (of Client)"
                                        format="DD/MM/YYYY"
                                        placeholder="dd/mm/yyyy"
                                        animateYearScrolling={true}
                                        // tslint:disable-next-line: max-line-length
                                        autoOk={true}
                                        allowKeyboardControl={false}
                                        variant={'inline'}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error}
                                        inputProps={{ autocomplete: 'off' }}
                                        InputLabelProps={{ shrink: true }}
                                        maxDate={new Date()}
                                        onChange={(date: MaterialUiPickersDate | null, value?: string | null) => input.onChange(date)}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <Field
                                // tslint:disable-next-line: max-line-length
                                name="gender"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <div>
                                        <GenderSelector
                                            {...input}
                                            label="Gender (of Client)"
                                            name="gender"
                                            disabled={values.nameGuid}
                                            className={classes.textField}
                                            error={meta.error && meta.touched}
                                            // tslint:disable-next-line: max-line-length
                                            onSelection={(selection: IAutoCompleteItem, name: string) =>
                                                input.onChange(selection.value)
                                            }
                                            helperText={
                                                meta.error &&
                                                    meta.touched
                                                    ? 'Gender is required'
                                                    : ''
                                            }
                                        />
                                    </div>
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={12} className={classes.customFieldGrid}>
                            <Grid container={true} spacing={1}>
                                <Grid item={true} xs={12} md={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="isMarketingConsent"
                                        type="checkbox"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <FormControlLabel
                                                className={classes.formControlLabel}
                                                control={
                                                    <Checkbox
                                                        {...input}
                                                        checked={values.isMarketingConsent}
                                                        onChange={input.onChange}
                                                        checkedIcon={<CheckBoxIcon color="primary" />}
                                                    />
                                                }
                                                disabled={values.individualSelector}
                                                label="Marketing Consent"
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="isTermsAndConditionsApproved"
                                        type="checkbox"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <FormControlLabel
                                                className={classes.formControlLabel}
                                                control={
                                                    <Checkbox
                                                        {...input}
                                                        checked={values.isTermsAndConditionsApproved}
                                                        onChange={input.onChange}
                                                        checkedIcon={<CheckBoxIcon color="primary" />}
                                                    />
                                                }
                                                label="T&C's Approved"
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="isPrivacyStatementProvided"
                                        type="checkbox"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <FormControlLabel
                                                className={classes.formControlLabel}
                                                control={
                                                    <Checkbox
                                                        {...input}
                                                        checked={values.isPrivacyStatementProvided}
                                                        onChange={input.onChange}
                                                        checkedIcon={<CheckBoxIcon color="primary" />}
                                                    />
                                                }
                                                label="Privacy Statement Provided"
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="isConflictCheckPerformed"
                                        type="checkbox"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <FormControlLabel
                                                className={classes.formControlLabel}
                                                control={
                                                    <Checkbox
                                                        {...input}
                                                        checked={values.isConflictCheckPerformed}
                                                        onChange={input.onChange}
                                                        checkedIcon={<CheckBoxIcon color="primary" />}
                                                    />
                                                }
                                                label="Conflict Check Performed"
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="isNoConflict"
                                        type="checkbox"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <FormControlLabel
                                                className={classes.formControlLabel}
                                                control={
                                                    <Checkbox
                                                        {...input}
                                                        checked={values.isNoConflict}
                                                        onChange={input.onChange}
                                                        checkedIcon={<CheckBoxIcon color="primary" />}
                                                    />
                                                }
                                                label="No Conflict"
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="isIdentityCheckPerformed"
                                        type="checkbox"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <FormControlLabel
                                                className={classes.formControlLabel}
                                                control={
                                                    <Checkbox
                                                        {...input}
                                                        checked={values.isIdentityCheckPerformed}
                                                        onChange={input.onChange}
                                                        checkedIcon={<CheckBoxIcon color="primary" />}
                                                    />
                                                }
                                                label="Identity Check Peformed"
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item={true} xs={12}>
                            {clientAddressPanel(true, form)}
                        </Grid>
                        <Grid item={true} xs={12}>
                            {clientPostalAddressPanel(false, form)}
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    };

    const client2AddressPanel = (defaultExpanded: boolean, form: FormApi<Enquiry>) => {
        return (
            <FormSpy subscription={{ values: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Accordion
                        defaultExpanded={defaultExpanded}
                    // disabled={disabledAddress(values)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className={classes.AccordionSummary}
                        >
                            <Typography className={classes.heading}>
                                <ContactMailIcon className={classes.headingIcon} />
                                Main Address
                            </Typography>

                            <div className={classes.infoContainer}>
                                <div className="col">
                                    <div className="value">
                                        {getAddressLabelForPanel(
                                            values.secondClientAddressName,
                                            values.secondClientFloor,
                                            values.secondClientNumber,
                                            values.secondClientStreet1,
                                            values.secondClientStreet2,
                                            values.secondClientSuburb,
                                            values.secondClientState,
                                            values.secondClientPostCode,
                                            values.secondClientCountry
                                        )}
                                    </div>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails
                            className={classes.expandablePanelDetails}
                        >
                            <Grid container={true} spacing={3}>
                                <Grid item={true} md={6} xs={12} className={classes.customFieldGrid}>
                                    <Field
                                        name="secondClientAddressName"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Building name"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Building name is required'
                                                        : ''
                                                }
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={3} className={classes.customFieldGrid}>
                                    <Field
                                        name="secondClientFloor"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Unit/Level"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Unit/Level is required'
                                                        : ''
                                                }
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={3} className={classes.customFieldGrid}>
                                    <Field
                                        name="secondClientNumber"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Number"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Number is required'
                                                        : ''
                                                }
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={6} className={classes.customFieldGrid}>
                                    <Field
                                        name="secondClientStreet1"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <AddressSelector
                                                {...input}
                                                freeSolo={true}
                                                label="Street 1"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                homeCountry={props.homeCountry}
                                                // tslint:disable-next-line: max-line-length
                                                // placeTypes={['geocode', 'establishment']} // ['geocode', 'address', 'establishment', '(regions)', '(cities)'];
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Street 1 is required'
                                                        : ''
                                                }
                                                value={values.secondClientStreet1}
                                                onSelection={onSelection(form, values)}
                                                onInputChanged={onInputChanged(form, 'secondClientStreet1')}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={6} className={classes.customFieldGrid}>
                                    <Field
                                        name="secondClientStreet2"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <AddressSelector
                                                {...input}
                                                freeSolo={true}
                                                label="Street 2"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                homeCountry={props.homeCountry}
                                                // tslint:disable-next-line: max-line-length
                                                // placeTypes={['geocode', 'establishment']} // ['geocode', 'address', 'establishment', '(regions)', '(cities)'];
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Street 2 is required'
                                                        : ''
                                                }
                                                onSelection={onSelection(form, values)}
                                                onInputChanged={onInputChanged(form, 'secondClientStreet2')}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="secondClientSuburb"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="City/Suburb"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'City/Suburb is required'
                                                        : ''
                                                }
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="secondClientState"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="State"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'State is required'
                                                        : ''
                                                }
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="secondClientPostCode"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Postal Code"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Postal Code is required'
                                                        : ''
                                                }
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} className={classes.customFieldGrid}>
                                    <Field
                                        name="secondClientCountry"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Country"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Country is required'
                                                        : ''
                                                }
                                                inputProps={{
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )}
            </FormSpy>
        );
    };

    // tslint:disable-next-line: max-line-length
    const client2PostalAddressPanel = (defaultExpanded: boolean, form: FormApi<Enquiry>) => {
        return (
            <FormSpy subscription={{ values: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Accordion
                        // defaultExpanded={defaultExpanded}
                        expanded={state.isClient2PostalAddressPanelExpand}
                        onChange={onExpandPanel('isClient2PostalAddressPanelExpand')}
                    // disabled={disabledAddress(values)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className={classes.AccordionSummary}
                        >
                            <Typography className={classes.heading}>
                                <LocalPostOfficeIcon className={classes.headingIcon} />
                                Postal Address
                            </Typography>

                            <div className={classes.infoContainer}>
                                <div className="col">
                                    <div className="value">
                                        {/* {getAddressLabelForPanel} */}
                                        {values.secondClientPOBoxType ? `${values.secondClientPOBoxType} ` : ''}
                                        {values.secondClientPOBox ? `${values.secondClientPOBox}, ` : ''}
                                        {values.secondClientPostalSuburb ? `${values.secondClientPostalSuburb}, ` : ''}
                                        {values.secondClientPostalState ? `${values.secondClientPostalState}, ` : ''}
                                        {values.secondClientPostalPostCode ? `${values.secondClientPostalPostCode}, ` : ''}
                                        {values.secondClientPostalCountry ? `${values.secondClientPostalCountry}` : ''}
                                    </div>
                                </div>
                            </div>

                        </AccordionSummary>
                        <AccordionDetails
                            className={classes.expandablePanelDetails}
                        >
                            <Grid container={true} spacing={3}>
                                <Grid
                                    item={true}
                                    xs={4}
                                    className={classes.customFieldGrid}
                                >
                                    <Field
                                        name="secondClientPOBoxType"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <POBoxTypeSelector
                                                // {...input}
                                                value={input.value ? { label: input.value, value: input.value } : null}
                                                label="PO Box Type"
                                                // required={true}
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                // tslint:disable-next-line: max-line-length
                                                onSelection={(selection: IAutoCompleteItem, name: string) => {
                                                    input.onChange(selection ? selection.value : null);
                                                }}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Field is required'
                                                        : ''
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={8} className={classes.customFieldGrid}>
                                    <Field
                                        name="secondClientPOBox"
                                        subscription={{ touched: true, error: true, value: true }}
                                        validate={isSecondClientPoBoxRequired(values) ? required : undefined}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Number"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                required={isSecondClientPoBoxRequired(values) ? true : false}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Number is required'
                                                        : ''
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="secondClientPostalSuburb"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <AddressSelector
                                                {...input}
                                                freeSolo={true}
                                                label="Suburb"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                homeCountry={props.homeCountry}
                                                // tslint:disable-next-line: max-line-length
                                                // placeTypes={['geocode', 'establishment']} // ['geocode', 'address', 'establishment', '(regions)', '(cities)'];
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Suburb is required'
                                                        : ''
                                                }
                                                placeTypes={['(cities)']}
                                                onSelection={onSelection(form, values)}
                                                onInputChanged={onInputChanged(form, 'secondClientPostalSuburb')}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="secondClientPostalState"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="State"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'State is required'
                                                        : ''
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="secondClientPostalPostCode"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Postal Code"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Postal Code is required'
                                                        : ''
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} className={classes.customFieldGrid}>
                                    <Field
                                        name="secondClientPostalCountry"
                                        subscription={{ touched: true, error: true, value: true }}
                                    // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Country"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                // disabled={props.sourceSystem?.toLowerCase() === 'leap'}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'Country is required'
                                                        : ''
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )}
            </FormSpy>
        );
    };
    
    const riskAssessedPanel = (form: FormApi<Enquiry>, values: AnyObject) => {
        return (
            <ExpansionPanel
                className={classes.expansionPanel}
                // expanded={state.isOtherDetailsPanelExpand}
                // onChange={onExpandPanel('isOtherDetailsPanelExpand')}
            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.expansionPanelSummary}
                >
                    <Typography className={classes.heading}>
                        Risk Assessment
                    </Typography>
                    <div className={classes.infoContainer}>
                        <div className="col">
                            <div className="value">
                                {addEllipsis(
                                    arrayToStringWithSeparator(
                                        [
                                            values.isRiskAssessed ? 'Yes' : 'No',
                                            values.isRiskAssessed ? values.riskLevelSelector ? values.riskLevelSelector.description : null : null,
                                            values.isRiskAssessed ? values.riskNotes : null
                                        ],
                                        ', '
                                    ),
                                    70
                                )}
                            </div>
                        </div>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    className={classes.expandablePanelDetails}
                >
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} md={12} className={classes.customFieldGrid}>
                            <Field
                                name="isRiskAssessed"
                                type="checkbox"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <FormControlLabel
                                        className={classes.formControlLabel}
                                        control={
                                            <Checkbox
                                                {...input}
                                                checked={values.isRiskAssessed}
                                                onChange={input.onChange}
                                                checkedIcon={<CheckBoxIcon color="primary" />}
                                            />
                                        }
                                        label="Risk Assessed"
                                    />
                                )}
                            </Field>
                        </Grid>
                        {values.isRiskAssessed ? (
                            <>
                                <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                                    <Field
                                        // tslint:disable-next-line: max-line-length
                                        name="riskLevelSelector"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <div>
                                                <RiskLevelSelector
                                                    {...input}
                                                    label="Risk Level"
                                                    name="riskLevelSelector"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    // tslint:disable-next-line: max-line-length
                                                    onSelection={(selection: IAutoCompleteItem, name: string) =>
                                                        input.onChange(selection)
                                                    }
                                                    helperText={
                                                        meta.error &&
                                                            meta.touched
                                                            ? 'required'
                                                            : ''
                                                    }
                                                />
                                            </div>
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                                    <Field
                                        name="riskNotes"
                                        // validate={required}
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Risk Notes"
                                                multiline={true}
                                                // required={true}
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                autoComplete="abcd"
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? 'required'
                                                        : ''
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </>
                        ) : null}
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    };

    const otherDetails2Panel = (form: FormApi<Enquiry>, values: AnyObject) => {
        
        const panelTitle = addEllipsis(arrayToString([
            arrayToStringWithSeparator([
                values.secondClientTitle && values.secondClientTitle.trim().length > 0 && values.secondClientTitle !== 'None' ? `${values.secondClientTitle}` : '',
                values.secondClientFirstName,
                values.secondClientLastName,
            ],                         ' '),
            values.secondClientRelationshipToEnquirer,
            values.secondClientAddressName,
            arrayToStringWithSeparator([
                arrayToStringWithSeparator([
                    values.secondClientFloor,
                    values.secondClientNumber,
                ],                         '/'),
                values.secondClientStreet1,
            ],                         ' '),
            values.secondClientStreet2,
            values.secondClientSuburb,
            values.secondClientState,
            values.secondClientPostCode,
            values.secondClientCountry
        // tslint:disable-next-line: align
        ]), 70);

        return (
            <ExpansionPanel
                className={classes.expansionPanel}
                expanded={state.isOther2DetailsPanelExpand}
                onChange={onExpandPanel('isOther2DetailsPanelExpand')}
            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.expansionPanelSummary}
                >
                    <Typography className={classes.heading}>
                        Client 2 Details
                    </Typography>
                    <div className={classes.infoContainer}>
                        <div className="col">
                            {panelTitle}
                        </div>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    className={classes.expandablePanelDetails}
                >
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <Field 
                                name="secondClientTitle" 
                                // validate={required} 
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <>
                                        <TitleAutocompleteSelector
                                            {...input}
                                            label="Title"
                                            className={classes.textField}
                                            error={meta.error && meta.touched}
                                            value={input.value ? { label: input.value, value: input.value } : undefined}
                                            // tslint:disable-next-line: max-line-length
                                            onSelection={(selection: IAutoCompleteItem, name: string) => {
                                                input.onChange(selection ? selection.value : '');
                                            }}
                                            // required={true}
                                            helperText={meta.error && meta.touched ? 'Title is required' : ''}
                                        />
                                    </>
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <Field
                                name="secondClientFirstName"
                                // validate={required}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Client 2 Given Name(s)"
                                        multiline={true}
                                        disabled={values.secondClientGuid}
                                        // required={true}
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        autoComplete="abcd"
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? 'Client 2 Given Name(s) is required'
                                                : ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <div className={classes.toggleFieldWrapepr}>
                                <div className="field">
                                    {
                                        values.isSecondClientNameSwitched ? (
                                            <Field
                                                name="secondClientIndividualSelector"
                                                subscription={{ touched: true, error: true, value: true }}
                                            >
                                                {({ input, meta }) => (
                                                    <IndividualSelector
                                                        {...input}
                                                        key="clientIndividualSelector"
                                                        label="Individual Name"
                                                        value={values.secondClientIndividualSelector}
                                                        error={meta.error && meta.touched}
                                                        helperText={
                                                            meta.error &&
                                                                meta.touched
                                                                ? 'Individual Name is required'
                                                                : ''
                                                        }
                                                        onSelection={(selection: IndividualNameSummary, name: string) => {
                                                            input.onChange(selection);
                                                            onDropDownSelect(form, values, input, selection, 'secondClientIndividualSelector');
                                                            onGetPartyAddresses(form, values, selection, 'secondClientIndividualSelector');
                                                        }}
                                                        enableSecondary={true}
                                                        includeOrganisation={false}
                                                    // value={input.value}
                                                    />
                                                )}
                                            </Field>
                                        ) :
                                            (
                                                <Field
                                                    name="secondClientLastName"
                                                    // validate={!isTabActivity() ? required : undefined}
                                                    subscription={{ touched: true, error: true, value: true }}
                                                >
                                                    {({ input, meta }) => (
                                                        <TextField
                                                            {...input}
                                                            label="Client 2 Surname"
                                                            type="text"
                                                            // required={!isTabActivity()}
                                                            className={classes.textField}
                                                            error={
                                                                meta.error &&
                                                                meta.touched
                                                            }
                                                            disabled={values.secondClientGuid}
                                                            autoComplete="abcd"
                                                            helperText={
                                                                meta.error &&
                                                                    meta.touched
                                                                    ? 'Client 2 Surname is required'
                                                                    : ''
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                            )
                                    }
                                </div>
                                <div className="switcher">
                                    <Tooltip
                                        // placement="left-start"
                                        title={
                                            values.isSecondClientNameSwitched ? (
                                                <Typography color="inherit">Switch to select an existing client & contact</Typography>
                                            ) :
                                                (
                                                    <Typography color="inherit">Switch to enter the last name</Typography>
                                                )
                                        }
                                    >
                                        <IconButton
                                            aria-label="delete"
                                            // className={classes.margin}
                                            onClick={() => {
                                                // Another approach.
                                                onSwitch(form, values, 'secondClientLastName');
                                            }}
                                        >
                                            <SyncAltIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </Grid>

                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <Field
                                name="secondClientRelationshipToEnquirer"
                                // validate={required}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Relationship to the Client"
                                        multiline={true}
                                        // required={true}
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        autoComplete="abcd"
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? 'Relationship to Client is required'
                                                : ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <Field
                                name="secondClientEmail"
                                type="email"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <div>
                                        <TextField
                                            {...input}
                                            label="Email"
                                            type="email"
                                            // required={!isTabActivity() ? getCorrespondenceRequired(values) : undefined}
                                            className={classes.textField}
                                            // tslint:disable-next-line: no-any
                                            onChange={(event: any) => {
                                                input.onChange(event.target.value);
                                                onContactDetailsChanged(form, values);
                                            }}
                                            error={
                                                meta.error &&
                                                meta.touched
                                            }
                                            autoComplete="abcd"
                                            helperText={
                                                meta.error &&
                                                    meta.touched
                                                    ? meta.error
                                                    : ''
                                            }
                                        />
                                    </div>
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <Field
                                name="secondClientPhone"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Telephone"
                                        type="text"
                                        // tslint:disable-next-line: no-any
                                        onChange={(event: any) => {
                                            input.onChange(event.target.value);
                                            onContactDetailsChanged(form, values);
                                        }}
                                        // required={true}
                                        // required={!isTabActivity() ? getCorrespondenceRequired(values) : undefined}
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        autoComplete="abcd"
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? meta.error // 'Phone is required'
                                                : ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <Field
                                name="secondClientMobile"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Mobile"
                                        type="text"
                                        // tslint:disable-next-line: no-any
                                        onChange={(event: any) => {
                                            input.onChange(event.target.value);
                                            onContactDetailsChanged(form, values);
                                        }}
                                        // required={true}
                                        // required={!isTabActivity() ? getCorrespondenceRequired(values) : undefined}
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        autoComplete="abcd"
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? meta.error // 'Mobile is required'
                                                : ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <Field
                                name="secondClientDOB"
                                validate={birthDateValidator}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <KeyboardDatePicker
                                        {...input}
                                        value={input && input.value ? input.value : null}
                                        className={classes.textField}
                                        label="Birthdate (of Client)"
                                        format="DD/MM/YYYY"
                                        placeholder="dd/mm/yyyy"
                                        animateYearScrolling={true}
                                        // tslint:disable-next-line: max-line-length
                                        autoOk={true}
                                        allowKeyboardControl={false}
                                        variant={'inline'}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error}
                                        inputProps={{ autocomplete: 'off' }}
                                        InputLabelProps={{ shrink: true }}
                                        maxDate={new Date()}
                                        onChange={(date: MaterialUiPickersDate | null, value?: string | null) => input.onChange(date)}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <Field
                                // tslint:disable-next-line: max-line-length
                                name="secondClientGender"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <div>
                                        <GenderSelector
                                            {...input}
                                            label="Gender (of Client)"
                                            name="secondClientGender"
                                            disabled={values.secondClientGuid}
                                            className={classes.textField}
                                            error={meta.error && meta.touched}
                                            // tslint:disable-next-line: max-line-length
                                            onSelection={(selection: IAutoCompleteItem, name: string) =>
                                                input.onChange(selection.value)
                                            }
                                            helperText={
                                                meta.error &&
                                                    meta.touched
                                                    ? 'Gender is required'
                                                    : ''
                                            }
                                        />
                                    </div>
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={12} className={classes.customFieldGrid}>
                            <Grid container={true} spacing={1}>
                                <Grid item={true} xs={12} md={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="secondClientIsMarketingConsent"
                                        type="checkbox"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <FormControlLabel
                                                className={classes.formControlLabel}
                                                control={
                                                    <Checkbox
                                                        {...input}
                                                        checked={values.secondClientIsMarketingConsent}
                                                        onChange={input.onChange}
                                                        checkedIcon={<CheckBoxIcon color="primary" />}
                                                    />
                                                }
                                                disabled={values.secondClientIndividualSelector}
                                                label="Marketing Consent"
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="secondClientIsTermsAndConditionsApproved"
                                        type="checkbox"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <FormControlLabel
                                                className={classes.formControlLabel}
                                                control={
                                                    <Checkbox
                                                        {...input}
                                                        checked={values.secondClientIsTermsAndConditionsApproved}
                                                        onChange={input.onChange}
                                                        checkedIcon={<CheckBoxIcon color="primary" />}
                                                    />
                                                }
                                                label="T&C's Approved"
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="secondClientIsPrivacyStatementProvided"
                                        type="checkbox"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <FormControlLabel
                                                className={classes.formControlLabel}
                                                control={
                                                    <Checkbox
                                                        {...input}
                                                        checked={values.secondClientIsPrivacyStatementProvided}
                                                        onChange={input.onChange}
                                                        checkedIcon={<CheckBoxIcon color="primary" />}
                                                    />
                                                }
                                                label="Privacy Statement Provided"
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="secondClientIsConflictCheckPerformed"
                                        type="checkbox"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <FormControlLabel
                                                className={classes.formControlLabel}
                                                control={
                                                    <Checkbox
                                                        {...input}
                                                        checked={values.secondClientIsConflictCheckPerformed}
                                                        onChange={input.onChange}
                                                        checkedIcon={<CheckBoxIcon color="primary" />}
                                                    />
                                                }
                                                label="Conflict Check Performed"
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="secondClientIsNoConflict"
                                        type="checkbox"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <FormControlLabel
                                                className={classes.formControlLabel}
                                                control={
                                                    <Checkbox
                                                        {...input}
                                                        checked={values.secondClientIsNoConflict}
                                                        onChange={input.onChange}
                                                        checkedIcon={<CheckBoxIcon color="primary" />}
                                                    />
                                                }
                                                label="No Conflict"
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4} className={classes.customFieldGrid}>
                                    <Field
                                        name="secondClientIsIdentityCheckPerformed"
                                        type="checkbox"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <FormControlLabel
                                                className={classes.formControlLabel}
                                                control={
                                                    <Checkbox
                                                        {...input}
                                                        checked={values.secondClientIsIdentityCheckPerformed}
                                                        onChange={input.onChange}
                                                        checkedIcon={<CheckBoxIcon color="primary" />}
                                                    />
                                                }
                                                label="Identity Check Peformed"
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item={true} xs={12}>
                            {client2AddressPanel(true, form)}
                        </Grid>
                        <Grid item={true} xs={12}>
                            {client2PostalAddressPanel(false, form)}
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    };

    const showAllContactInfo = (duplicateCheck: DuplicateCheck | null) => {

        if (!duplicateCheck) {
            return (
                <div />
            );
        }

        return (
            <div className={classes.showContactWrapper}>
                {duplicateCheck.partyDuplicateCount > 0 && (
                    <>
                        {duplicateCheck.partyDuplicateCount > 1 ? (
                            <Typography style={{ fontStyle: 'italic' }}>Multiple contacts found, first below</Typography>
                        ) : null}
                        {duplicateCheck.contactName ? (
                            <div>
                                <Typography>Contact Name: {duplicateCheck.contactName}</Typography>
                            </div>
                        ) : null}
                        {duplicateCheck.contactEmailAddress ? (
                            <div>
                                <Typography>Contact Email: {duplicateCheck.contactEmailAddress}</Typography>
                            </div>
                        ) : null}
                        <div>
                            <Typography color="primary" style={{ fontStyle: 'italic' }}>Use the switch button to select this contact</Typography>
                        </div>
                    </>
                )}
                {duplicateCheck.enquiryContactDuplicateCount > 0 && (
                    <div style={{ paddingTop: '10px' }}>
                        {duplicateCheck.enquiryContactDuplicateCount > 1 ? (
                            <Typography style={{ fontStyle: 'italic' }}>Multiple enquiries found, first below</Typography>
                        ) : null}
                        {duplicateCheck.enquiryName ? (
                            <div>
                                <Typography>Enquiry Name: {duplicateCheck.enquiryName}</Typography>
                            </div>
                        ) : null}
                        {duplicateCheck.enquiryEmailAddress ? (
                            <div>
                                <Typography>Enquiry Email: {duplicateCheck.enquiryEmailAddress}</Typography>
                            </div>
                        ) : null}
                    </div>
                )}
                <div style={{ paddingTop: '10px' }}>
                    {duplicateCheck.otherSideName ? (
                        <div>
                            <Typography>Enquiry Other Side Name: {duplicateCheck.otherSideName}</Typography>
                        </div>
                    ) : null}
                    {duplicateCheck.otherSideEmailAddress ? (
                        <div>
                            <Typography>Enquiry Other Side Email: {duplicateCheck.otherSideEmailAddress}</Typography>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    };

    const contactDetailsPanel = (form: FormApi<Enquiry>, values: AnyObject) => {

        const tempReferrer = values.referrer ? values.referrer.label : values.referrerName ? values.referrerName : null;
        const tempSource = values.source ? values.source.label : null;

        // add all text in array and convert it to string to put comma for separation
        let panelTitle = [tempSource, tempReferrer, values.sourceNotes].filter(n => n).join(', ');

        const isEmailExist = (values.duplicateCheck && values.duplicateCheck.partyDuplicateCount > 0) 
                || (values.duplicateCheck && values.duplicateCheck.enquiryContactDuplicateCount > 0) 
                || (values.duplicateCheck && values.duplicateCheck.otherSideEmailAddress);

        return (
            <ExpansionPanel
                className={classes.expansionPanel}
                // defaultExpanded={isCreateForm}
                expanded={state.isContactDetailsPanelExpand}
                onChange={onExpandPanel('isContactDetailsPanelExpand')}

            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.expansionPanelSummary}
                >
                    <Typography className={classes.heading}>
                        Contact Details
                    </Typography>

                    <div className={classes.infoContainer}>
                        <div className={`${classes.truncateText} label`}>
                            {panelTitle}
                        </div>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    className={classes.expandablePanelDetails}
                >
                    <Grid container={true} spacing={3}>
                        {isEmailExist && (
                            <Grid item={true} xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <div className={classes.associatedWarning}>
                                    <Typography className={classes.associatedColor}>A contact with this email already exists</Typography>
                                    <LightTooltip title={showAllContactInfo(values.duplicateCheck)}>
                                        <InfoIcon className={classes.associatedColor} />
                                    </LightTooltip>
                                </div>
                            </Grid>
                        )}
                        <Grid item={true} xs={6} className={classes.customFieldGrid}>
                            <Field 
                                name="title" 
                                // validate={required} 
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <>
                                        <TitleAutocompleteSelector
                                            {...input}
                                            label="Title"
                                            className={classes.textField}
                                            error={meta.error && meta.touched}
                                            value={input.value ? { label: input.value, value: input.value } : undefined}
                                            // tslint:disable-next-line: max-line-length
                                            onSelection={(selection: IAutoCompleteItem, name: string) => {
                                                input.onChange(selection ? selection.value : '');
                                            }}
                                            // required={true}
                                            helperText={meta.error && meta.touched ? 'Title is required' : ''}
                                        />
                                    </>
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={6} className={classes.customFieldGrid}>
                            <Field
                                name="firstName"
                                validate={!isTabActivity() ? required : undefined}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <div>
                                        {/* tslint:disable-next-line */}
                                        {/* {console.log('meta ' + meta)} */}
                                        <TextField
                                            {...input}
                                            label="Given Name(s)"
                                            type="text"
                                            required={!isTabActivity()}
                                            disabled={values.nameGuid}
                                            className={classes.textField}
                                            error={meta.error && meta.touched}
                                            autoComplete="abcd"
                                            helperText={
                                                meta.error &&
                                                    meta.touched
                                                    ? 'Given Name(s) is required'
                                                    : ''
                                            }
                                        />
                                    </div>
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={6} className={classes.customFieldGrid}>
                            <div className={classes.toggleFieldWrapepr}>
                                <div className="field">
                                    {
                                        values.isNameSwitched ? (
                                            <Field
                                                name="individualSelector"
                                                subscription={{ touched: true, error: true, value: true }}
                                            >
                                                {({ input, meta }) => (
                                                    <IndividualSelector
                                                        {...input}
                                                        key="individualSelector"
                                                        label="Individual Name"
                                                        value={values.individualSelector}
                                                        error={meta.error && meta.touched}
                                                        helperText={
                                                            meta.error &&
                                                                meta.touched
                                                                ? 'Individual Name is required'
                                                                : ''
                                                        }
                                                        onSelection={(selection: IndividualNameSummary, name: string) => {
                                                            input.onChange(selection);
                                                            onDropDownSelect(form, values, input, selection, 'individualSelector');
                                                            onGetPartyAddresses(form, values, selection, 'individualSelector');
                                                        }}
                                                        enableSecondary={true}
                                                        includeOrganisation={false}
                                                    // value={input.value}
                                                    />
                                                )}
                                            </Field>
                                        ) :
                                            (
                                                <Field
                                                    name="lastName"
                                                    validate={!isTabActivity() ? required : undefined}
                                                    subscription={{ touched: true, error: true, value: true }}
                                                >
                                                    {({ input, meta }) => (
                                                        <TextField
                                                            {...input}
                                                            label="Last Name"
                                                            type="text"
                                                            required={!isTabActivity()}
                                                            className={classes.textField}
                                                            error={
                                                                meta.error &&
                                                                meta.touched
                                                            }
                                                            disabled={values.nameGuid}
                                                            autoComplete="abcd"
                                                            helperText={
                                                                meta.error &&
                                                                    meta.touched
                                                                    ? 'Last Name is required'
                                                                    : ''
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                            )
                                    }
                                </div>
                                <div className="switcher">
                                    <Tooltip
                                        // placement="left-start"
                                        title={
                                            values.isNameSwitched ? (
                                                <Typography color="inherit">Switch to select an existing client & contact</Typography>
                                            ) :
                                                (
                                                    <Typography color="inherit">Switch to enter the last name</Typography>
                                                )
                                        }
                                    >
                                        <IconButton
                                            aria-label="delete"
                                            // className={classes.margin}
                                            onClick={() => {
                                                // Another approach.
                                                onSwitch(form, values, 'lastName');
                                            }}
                                        >
                                            <SyncAltIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </Grid>
                        <Grid item={true} xs={6} className={classes.customFieldGrid}>
                            <Field
                                name="initialEnquiryDate"
                                validate={!isTabActivity() ? requiredDateValidator : dateValidator}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <KeyboardDatePicker
                                        {...input}
                                        className={classes.textField}
                                        id="initialEnquiryDate"
                                        name="dateOpened"
                                        label={t('Initial Enquiry')}
                                        format="DD/MM/YYYY"
                                        placeholder="dd/mm/yyyy"
                                        required={!isTabActivity()}
                                        animateYearScrolling={true}
                                        // tslint:disable-next-line: max-line-length
                                        autoOk={true}
                                        allowKeyboardControl={true}
                                        variant={'inline'}
                                        error={meta.error && meta.touched}
                                        inputProps={{ autocomplete: 'off' }}
                                        helperText={meta.error}
                                        onChange={(date: MaterialUiPickersDate | null, value?: string | null) => input.onChange(date)}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={6} className={classes.customFieldGrid}>
                            <Field
                                name="email"
                                type="email"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <div>
                                        <TextField
                                            {...input}
                                            label="Email"
                                            type="email"
                                            required={!isTabActivity() ? getCorrespondenceRequired(values) : undefined}
                                            className={classes.textField}
                                            // tslint:disable-next-line: no-any
                                            // onChange={(event: any) => {
                                            //     input.onChange(event.target.value);
                                            //     onContactDetailsChanged(form, values);
                                            //     onCheckAssociateContact(form, event.target.value);
                                            // }}
                                            onChange={(event) => {
                                                input.onChange(event.target.value);
                                                checkDuplicateEmail(form, values, event.target.value);
                                            }}
                                            error={
                                                meta.error &&
                                                meta.touched
                                            }
                                            autoComplete="abcd"
                                            helperText={
                                                meta.error &&
                                                    meta.touched
                                                    ? meta.error
                                                    : ''
                                            }
                                        />
                                    </div>
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={6} className={classes.customFieldGrid}>
                            <Field
                                name="phone"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Telephone"
                                        type="text"
                                        // tslint:disable-next-line: no-any
                                        onChange={(event: any) => {
                                            input.onChange(event.target.value);
                                            onContactDetailsChanged(form, values);
                                        }}
                                        // required={true}
                                        required={!isTabActivity() ? getCorrespondenceRequired(values) : undefined}
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        autoComplete="abcd"
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? meta.error // 'Phone is required'
                                                : ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={6} className={classes.customFieldGrid}>
                            <Field
                                name="mobile"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Mobile"
                                        type="text"
                                        // tslint:disable-next-line: no-any
                                        onChange={(event: any) => {
                                            input.onChange(event.target.value);
                                            onContactDetailsChanged(form, values);
                                        }}
                                        // required={true}
                                        required={!isTabActivity() ? getCorrespondenceRequired(values) : undefined}
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        autoComplete="abcd"
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? meta.error // 'Mobile is required'
                                                : ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={6} className={classes.customFieldGrid}>
                            <Field
                                name="source"
                                validate={!isTabActivity() ? required : undefined}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <SourceSelector
                                        label="Source"
                                        name="source"
                                        value={input.value ? input.value : null}
                                        required={!isTabActivity()}
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        onSelection={(selection: IAutoCompleteItem, name: string) => {
                                            input.onChange(selection);

                                            onSourceChanged(form, values, selection);
                                        }}
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? 'Source is required'
                                                : ''
                                        }
                                        onBlur={input.onBlur}
                                    />
                                )}
                            </Field>
                        </Grid>

                        <Grid item={true} xs={6} className={classes.customFieldGrid}>
                            <Field
                                name="office"
                                validate={props.enquiryDefaultConfig && props.enquiryDefaultConfig.isLocationFieldMandatory ? required : undefined}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <div>
                                        <OfficeSelector
                                            {...input}
                                            label="Location"
                                            name="office"
                                            required={props.enquiryDefaultConfig && props.enquiryDefaultConfig.isLocationFieldMandatory 
                                                        ? props.enquiryDefaultConfig.isLocationFieldMandatory 
                                                        : false
                                                    }
                                            value={input.value ? input.value : null}
                                            className={classes.textField}
                                            error={meta.error && meta.touched}
                                            onSelection={(selection: IAutoCompleteItem, name: string) =>
                                                input.onChange(selection)
                                            }
                                            helperText={meta.error && meta.touched
                                                ? 'Location is required'
                                                : ''
                                            }
                                            onBlur={input.onBlur}
                                        />
                                    </div>
                                )}
                            </Field>
                        </Grid>
                        {!!values.isShowReferrer && (
                            <Grid item={true} xs={6} className={classes.customFieldGrid}>
                                <Field name="referrer">
                                    {({ input, meta }) => (
                                        <ClientAndContactSelector
                                            label="Contact"
                                            name="referrer"
                                            required={false}
                                            onSelection={(selection: IAutoCompleteItem, name: string) => {
                                                input.onChange(selection);

                                                onReferrerChanged(form, values, selection);
                                            }}
                                            value={values.referrerGuid
                                                ? { value: values.referrerGuid, label: values.referrerName }
                                                : null
                                            }
                                            includeNonPmsContacts={false}
                                            includePmsContacts={true}
                                        />
                                    )}
                                </Field>
                            </Grid>
                        )}
                        <Grid item={true} xs={values.isShowReferrer ? 12 : 12} className={classes.customFieldGrid}>
                            <Field
                                name="sourceNotes"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Source Notes"
                                        type="text"
                                        multiline={true}
                                        autoComplete="abcd"
                                        className={classes.textField}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid
                            item={true}
                            xs={12}
                            className={classes.customFieldGrid}
                        >
                            <Field
                                name="internalNotes"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Internal Notes"
                                        type="text"
                                        multiline={true}
                                        autoComplete="abcd"
                                        className={classes.textField}
                                    />
                                )}
                            </Field>
                        </Grid>
                        {UserFeature.HasAccess(UserFeatureEnum.hasEventsAndCampaignsSummary) ? (
                            <>
                                <Grid item={true} xs={6} className={classes.customFieldGrid}>
                                    <Field name="eventAndCampaignSelector">
                                        {({ input, meta }) => (
                                            <EventsAndCampaignSelector
                                                {...input}
                                                // name="eventAndCampaignSelector"
                                                label={t('Event/Campaign')}
                                                error={meta.error && meta.touched}
                                                helperText={
                                                    meta.error &&
                                                        meta.touched
                                                        ? `${t('eventsAndCampaign')} is required`
                                                        : ''
                                                }
                                                onSelection={(selection: EventsAndCampaignIAutoCompleteItem, name: string) => {
                                                    input.onChange(selection);
                                                }}
                                                className={classes.textField}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={6}
                                    className={classes.customFieldGrid}
                                >
                                    <Field
                                        name="eventsAndCampaignNotes"
                                        subscription={{ touched: true, error: true, value: true }}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Event/Campaign Notes"
                                                type="text"
                                                // multiline={true}
                                                className={classes.textField}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </>
                        ) : null}
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    };

    const organisationPanel = (form: FormApi<Enquiry>, values: AnyObject) => {

        const organisationPanelContent = `${values.organisation ? values.organisation : ''}${values.organisation && values.positionAtOrganisation ? `, ${values.positionAtOrganisation}` : values.positionAtOrganisation ? values.positionAtOrganisation : ''}`;

        return (

            <ExpansionPanel
                className={classes.expansionPanel}
                defaultExpanded={false}
            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.expansionPanelSummary}
                >
                    <Typography className={classes.heading}>
                        {t('Organisation')}
                    </Typography>
                    <div className={classes.infoContainer}>
                        <div className="col">
                            <div className="value">
                                {organisationPanelContent}
                            </div>
                        </div>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    className={classes.expandablePanelDetails}
                >
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} className={classes.customFieldGrid}>
                            <div className={classes.toggleFieldWrapepr}>
                                <div className="field">
                                    {
                                        values.isOrganisationSwitched ? (
                                            <Field
                                                name="organisationSelector"
                                                subscription={{ touched: true, error: true, value: true }}
                                            >
                                                {({ input, meta }) => (
                                                    <OrganisationSelector
                                                        {...input}
                                                        label={t('Organisation Name')}
                                                        error={meta.error && meta.touched}
                                                        helperText={
                                                            meta.error &&
                                                                meta.touched
                                                                ? `${t('Organisation Name')} is required`
                                                                : ''
                                                        }
                                                        onSelection={(selection: OrganisationIAutoCompleteItem, name: string) => {
                                                            input.onChange(selection);
                                                            onDropDownSelect(form, values, input, selection, name);
                                                        }}
                                                    // value={input.value}
                                                    />
                                                )}
                                            </Field>
                                        ) :
                                            (
                                                <Field
                                                    name="organisation"
                                                    // validate={required}
                                                    subscription={{ touched: true, error: true, value: true }}
                                                >
                                                    {({ input, meta }) => (
                                                        <TextField
                                                            {...input}
                                                            label="Organisation Name"
                                                            type="text"
                                                            // required={true}
                                                            className={classes.textField}
                                                            disabled={values.organisationGuid}
                                                            error={meta.error && meta.touched}
                                                            autoComplete="abcd"
                                                            helperText={
                                                                meta.error &&
                                                                    meta.touched
                                                                    ? 'Name is required'
                                                                    : ''
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                            )
                                    }
                                </div>
                                <div className="switcher">
                                    <Tooltip
                                        // placement="left-start"
                                        title={
                                            values.isOrganisationSwitched ? (
                                                <Typography color="inherit">Switch to select an existing organisation</Typography>
                                            ) :
                                                (
                                                    <Typography color="inherit">Switch to enter a new organisation name</Typography>
                                                )
                                        }
                                    >
                                        <IconButton
                                            aria-label="delete"
                                            className={classes.margin}
                                            onClick={() => {
                                                // Another approach.
                                                onSwitch(form, values, 'organisation');
                                            }}
                                        >
                                            <SyncAltIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item={true}
                            xs={12}
                            className={classes.customFieldGrid}
                        >
                            <Field
                                name="positionAtOrganisation"
                                // validate={required}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Position/Title"
                                        type="text"
                                        // required={true}
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        autoComplete="abcd"
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? 'Position is required'
                                                : ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    };

    const enquiryDetailsPanel = (form: FormApi<Enquiry>, values: AnyObject) => {

        let panelTitle = [values.areaOfLaw, values.natureOfEnquiry].filter(n => n).join(', ');

        return (
            <ExpansionPanel
                className={classes.expansionPanel}
                expanded={state.isEnquiryDetailsPanelExpand}
                onChange={onExpandPanel('isEnquiryDetailsPanelExpand')}
            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.expansionPanelSummary}
                >
                    <Typography className={classes.heading}>
                        {`${props.isKeyOpportunity
                            ? t('KeyOpportunity')
                            : t('Enquiry')}
                            Details`}
                    </Typography>
                    <div className={classes.infoContainer}>
                        <div className="col">
                            <div className="value">
                                {panelTitle}
                            </div>
                        </div>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    className={classes.expandablePanelDetails}
                >
                    <Grid container={true} spacing={3}>
                        <Grid
                            item={true}
                            xs={12}
                            className={classes.customFieldGrid}
                        >
                            <Field
                                name="natureOfEnquiry"
                                // validate={required}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label={t('Nature Of Enquiry')}
                                        type="text"
                                        multiline={true}
                                        // required={true}
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        autoComplete="abcd"
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? `${t('Nature Of Enquiry')} is required`
                                                : ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid
                            item={true}
                            xs={12}
                            className={classes.customFieldGrid}
                        >
                            <Field
                                name="areaOfLaw"
                                // tslint:disable-next-line: max-line-length
                                validate={!isTabActivity() ? required : undefined}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <div>
                                        <AreaOfLawSelector
                                            {...input}
                                            label="Area of Law"
                                            name="areaOfLaw"
                                            required={!isTabActivity()}
                                            className={classes.textField}
                                            error={meta.error && meta.touched}
                                            // tslint:disable-next-line: max-line-length
                                            value={{ label: values.areaOfLaw, value: values.practiceAreaGuidID }}
                                            // tslint:disable-next-line: max-line-length
                                            onSelection={(selection: IAreaOfLawAutoCompleteItem, name: string) => onSelectionChange(form, selection, name)}
                                            onBlur={input.onBlur}
                                            helperText={
                                                meta.error &&
                                                    meta.touched
                                                    ? 'Area of law is required'
                                                    : undefined
                                            }
                                        />
                                    </div>
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    };

    const enquiryValuePanels = (form: FormApi<Enquiry>, values: AnyObject) => {
        return (
            <ExpansionPanel
                className={classes.expansionPanel}
                defaultExpanded={false}
            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.expansionPanelSummary}
                >
                    <Typography className={classes.heading}>
                        {t('Enquiry Value')}
                    </Typography>
                    <div className={classes.infoContainer}>
                        <div className="col">
                            <Field name="enquiryQuality">
                                {(
                                    { input } /* FieldRenderProps */
                                ) => {
                                    // tslint:disable-next-line: radix
                                    return parseInt(input.value) > 0 && (
                                        <div className="value">
                                            <Rating
                                                // tslint:disable-next-line: radix
                                                value={parseInt(input.value)}
                                                readOnly={true}
                                            />
                                        </div>
                                    );
                                }}
                            </Field>
                        </div>
                        <div className="col">
                            <Field name="estimatedFees">
                                {(
                                    { input } /* FieldRenderProps */
                                ) => {
                                    return parseFloat(input.value) > 0 && (
                                        <div className="value">
                                            {/* tslint:disable-next-line: radix */}
                                            {`${state.currency}${formatNumber(parseFloat(input.value), 2)}`}
                                        </div>
                                    );
                                }}
                            </Field>
                        </div>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    className={classes.expandablePanelDetails}
                >
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <div className={classes.fieldAndCheckbox}>
                                <Field
                                    name="estimatedFees"
                                    label="Estimated Fees"
                                    // helperText={'Debtors amount to write off'}
                                    subscription={{ touched: true, error: true, value: true }}
                                    prefix={state.currency}
                                    // className={classes.textField}
                                    component={CurrencyInput}
                                // disabled={props.formAction === 'edit'}
                                />
                                <Field
                                    name="isEstimatedFeesIncludesTax"
                                    type="checkbox"
                                    subscription={{ touched: true, error: true, value: true }}
                                >
                                    {({ input, meta }) => (
                                        <FormControlLabel
                                            className={classes.formControlLabel}
                                            control={
                                                <Checkbox
                                                    {...input}
                                                    checked={values.isEstimatedFeesIncludesTax}
                                                    onChange={input.onChange}
                                                    checkedIcon={<CheckBoxIcon color="primary" />}
                                                />
                                            }
                                            label="Includes Tax"
                                        />
                                    )}
                                </Field>
                            </div>
                        </Grid>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <div className={classes.fieldAndCheckbox}>
                                <Field
                                    name="estimatedDisbursements"
                                    label="Estimated Disbs"
                                    // helperText={'Debtors amount to write off'}
                                    subscription={{ touched: true, error: true, value: true }}
                                    prefix={state.currency}
                                    // className={classes.textField}
                                    component={CurrencyInput}
                                // disabled={props.formAction === 'edit'}
                                />
                                <Field
                                    name="isEstimatedDisbursementsIncludesTax"
                                    type="checkbox"
                                    subscription={{ touched: true, error: true, value: true }}
                                >
                                    {({ input, meta }) => (
                                        <FormControlLabel
                                            className={classes.formControlLabel}
                                            control={
                                                <Checkbox
                                                    {...input}
                                                    checked={values.isEstimatedDisbursementsIncludesTax}
                                                    onChange={input.onChange}
                                                    checkedIcon={<CheckBoxIcon color="primary" />}
                                                />
                                            }
                                            label="Includes Tax"
                                        />
                                    )}
                                </Field>
                            </div>
                        </Grid>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <Field
                                name="enquiryQuality"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <div className={classes.ratingWrapper}>
                                        <Typography component="legend">{t('Enquiry Quality')}</Typography>
                                        <Rating
                                            {...input}
                                            value={input.value}
                                            onChange={onRatingChange('enquiryQuality', false, input, form, values)}
                                        />
                                    </div>
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6} className={classes.customFieldGrid}>
                            <Field
                                name="likelihoodToProceed"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <div className={classes.ratingWrapper}>
                                        <Typography component="legend">Likelihood to Proceed</Typography>
                                        <Rating
                                            {...input}
                                            value={input.value}
                                            onChange={onRatingChange('likelihoodToProceed', false, input, form, values)}
                                        />
                                    </div>
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} className={classes.customFieldGrid}>
                            <Field
                                name="enquiryNotes"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label={t('Enquiry Notes')}
                                        type="text"
                                        multiline={true}
                                        autoComplete="abcd"
                                        className={classes.textField}
                                    />
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    };

    // tslint:disable-next-line: no-any
    const detailsTab = (form: FormApi<any>, pristine: boolean, values: Enquiry) => {
        // let files: DocumentFile[] = form.getFieldState('files')?.value;

        return (
            <FormSpy subscription={{ values: true, validating: true, errors: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values, errors }) => {

                    if (state.isFormSubmit) {
                        openErrorPanel(errors);
                    }

                    return (
                        <div style={{ display: state.tab === EnquiryFormTab.details ? 'block' : 'none' }}>
                            {/* Contact Detail */}
                            {contactDetailsPanel(form, values)}

                            {/* Organisation */}
                            {organisationPanel(form, values)}

                            {enquiryDetailsPanel(form, values)}

                            {enquiryValuePanels(form, values)}

                            {otherDetailsPanel(form, values)}

                            {otherDetails2Panel(form, values)}

                            {riskAssessedPanel(form, values)}

                            {otherSidePanel(form, values)}

                            {detailedEnquiryNotesPanel(form, values)}

                            {documentsPanel(form, values)}

                            {/* Assign */}
                            {getAssignment(form, values, pristine)}

                            {/* Proceeding */}
                            {getProceeding(form, values, pristine)}
                        </div>
                    );
                }}
            </FormSpy>
        );
    };
    // end of contact details

    // tslint:disable-next-line: no-any
    const onSendEmailDialogClose = (form: FormApi<any>) => {
        setState((prevState) => {
            return {
                ...prevState,
                isSendEmailDialogOpen: false
            };
        });

        // Change back to default value
        form.batch(() => {
            form.change('emailTemplate', null);
            form.change('followUpAction', 'Emailed');
            form.change('followUpActionSystemName', 'Emailed');
            form.change('followUpSystemAction', null);
        });

        setIsSubmitting(false);
    };

    const isMarkClientMatterWidthLong = () => {

        let i = 0;
        // create contact
        if (enquiryActionData?.canCreateContactForLostEnquiry && UserFeature.HasAccess(UserFeatureEnum.hasCreateClient)) {
            i += 1;
        }
        // create client
        if (state.isCreateClient && UserFeature.HasAccess(UserFeatureEnum.hasCreateClient)) {
            i += 1;
        }
        // re-open
        if (enquiryActionData && enquiryActionData.canReOpen && enquiryActionData.canReOpen === true) {
            i += 1;
        }

        if (i >= 2) {
            return true;
        }

        return false;

    };

    // tslint:disable-next-line: no-any
    const onHistoryAcitivtyAssign = (form: FormApi<any>) => (task: TaskDetails) => {

        const staff = task.staff && task.staffGuid ?
            { label: task.staff, value: task.staffGuid, entityType: task.isAssignedToTeam ? EntityTypeEnum.TEAM : EntityTypeEnum.STAFF } : null;
        const officeLocation = task.firmOffice && task.firmOfficeGuid ? { label: task.firmOffice, value: task.firmOfficeGuid } : null;

        const tempTimezone = state.timezone.map((tzome: TimezoneOption) => {
            return {
                value: tzome.timezoneValue,
                label: tzome.displayName,
                secondaryValue: tzome.baseUtcOffset
            };
        }).find((tzome: { value: string; label: string; secondaryValue: number; }) => tzome.value === task.timezone && tzome.secondaryValue === task.timezoneOffset);

        form.batch(() => {
            form.change('followUpAction', task.followUpAction);
            form.change('followUpActionSystemName', task.followUpActionSystemName);
            form.change('followUpSystemAction', task.systemAction);
            form.change('staff', staff);
            form.change('officeLocation', officeLocation);
            form.change('timezone', tempTimezone ? tempTimezone : null);
            // set false if assign task is a team
            form.change('isSendConfirmation', !task.isAssignedToTeam);
            form.change('startTime', task.bookMeetingStartDate);
            form.change('endTime', task.bookMeetingEndDate);
            form.change('meetingNotes', task.meetingNotes);
            form.change('feeApplicable', task.feeApplicable);
        });

        setState((prevState) => {
            return {
                ...prevState,
                isBookMeetingFromHistoryActivity: true
            };
        });
    };

    // tslint:disable-next-line: no-any
    const activityTab = (form: FormApi<any>, pristine: boolean, values: AnyObject) => {
        let isCanReOpen = (enquiryActionData && enquiryActionData.canReOpen && enquiryActionData.canReOpen === true)
            ? true
            : false;

        const canCreateMatter = enquiryActionData ? enquiryActionData.canCreateMatter : false;

        return (
            <>
                <div className={classes.activityTab} style={{ display: isTabActivity() ? 'flex' : 'none' }}>
                    <div className={classes.historyActivityContainer}>
                        <HistoryActivity
                            className={`${classes.historyActivityWrapper} historyActivityWrapper`}
                            guid={props.guid}
                            enquiryDetail={enquiryDetail}
                            // sortColumn="DueDate"
                            sortDirection="ASC"
                            // isRefreshData={props.isRefreshData}
                            width={HistoryActivityWidth.FULL_WIDTH}
                            onCreateClient={onHistoryCreateClient('isCreateClient')}
                            autoScroll={true}
                            currency={state.currency}
                            timezone={state.timezone}
                            leadDetailsHistory={leadDetailsHistory}
                            onAssign={onHistoryAcitivtyAssign(form)}
                            canCreateMatter={canCreateMatter}
                        />
                    </div>
                    <div className={classes.customGroupButton}>
                        {state.isCreateClient && UserFeature.HasAccess(UserFeatureEnum.hasCreateClient) && (
                            <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                className={classes.createClientButton}
                                onClick={onCreateClientDialog(true)}
                            >
                                {`Create ${canCreateMatter ? 'Matter' : 'Client'}`}
                            </Button>
                        )}
                        {enquiryActionData?.canCreateContactForLostEnquiry && UserFeature.HasAccess(UserFeatureEnum.hasCreateClient) && (
                            <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                className={classes.createClientButton}
                                onClick={onCreateClientDialog(true)}
                            >
                                {`Create ${canCreateMatter ? 'Matter' : 'Contact'}`}
                            </Button>
                        )}
                        {state.isCreateClient && UserFeature.HasAccess(UserFeatureEnum.hasMarkAsClientCreated) && (
                            <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                className={`${classes.createClientButton} ${isMarkClientMatterWidthLong() ? classes.flex2 : undefined}`}
                                onClick={() => onCompleteCreateClient()}
                            >
                                {`Mark as ${canCreateMatter ? 'Matter' : 'Client'} Created`}
                            </Button>
                        )}
                        {/* <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                className={classes.flex2}
                                onClick={() => downloadEnquirySummary(enquiryDetail)}
                            >
                                {`Download Enquiry Summary`}
                            </Button> */}
                        {isCanReOpen && (
                            <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                className={classes.createClientButton}
                                onClick={() => onReOpen(form, values)}
                            >
                                Re-Open
                            </Button>
                        )}
                    </div>
                    {/* Follow Up */}
                    <ExpansionPanel
                        className={`${classes.expansionPanel} ${classes.expansionPanel2}`}
                        // defaultExpanded={!isCreateForm}
                        expanded={enquiryActionData?.canFollowUp ? true : false}
                        defaultExpanded={enquiryActionData?.canFollowUp ? true : false}
                        disabled={!enquiryActionData?.canFollowUp}
                    >
                        <ExpansionPanelSummary
                            // expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className={classes.expansionPanelSummary}
                        >
                            <Typography className={classes.heading}>
                                Follow Up
                            </Typography>
                            <FormSpy subscription={{ values: true }}>
                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                {({ values }) => (
                                    values ? (
                                        <div className={'col'}>
                                            {/* tslint:disable-next-line: max-line-length */}
                                            {(values.assignedToName && values.assignedToName !== null) // || !values.isClosed
                                                ? (
                                                    'Assigned to ' + values.assignedToName +
                                                    (
                                                        values.followUpDueDate && values.followUpDueDate !== null ? (
                                                            ', due by ' + (values.followUpDueDate
                                                                ? moment(values.followUpDueDate).format('DD MMM YYYY')
                                                                : 'None')
                                                        ) : ''
                                                    )
                                                ) : ''
                                            }
                                        </div>
                                    ) : null
                                )}
                            </FormSpy>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                            className={classes.expandablePanelDetails}
                        >
                            <Grid container={true} spacing={2}>
                                <FormSpy subscription={{ values: true }}>
                                    {/* tslint:disable-next-line: no-shadowed-variable */}
                                    {({ values }) => !state.isBookMeetingFromHistoryActivity && (
                                        <Grid item={true} xs={12}>
                                            <ActionTypeRadio
                                                name="followUpAction"
                                                label="Follow Up Action"
                                                value={values.followUpAction}
                                                // tslint:disable-next-line: max-line-length
                                                onRadioButtonChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean, value: string, systemAction: string, url: string | null) => {
                                                    onFollowUpChange(form, values, event, checked, value, systemAction, url);
                                                }}
                                            />
                                        </Grid>
                                    )}
                                </FormSpy>
                                <FormSpy subscription={{ values: true }}>
                                    {/* tslint:disable-next-line: no-shadowed-variable */}
                                    {({ values }) => {
                                        if (values.followUpSystemAction === 'BookMeeting') {
                                            return (
                                                <>
                                                    <Divider className={classes.divider} />
                                                    <Grid item={true} xs={12}>
                                                        <Grid container={true} spacing={1}>
                                                            {!!(values.firstName && values.lastName && values.email) && (
                                                                <Grid item={true} xs={12} md={12}>
                                                                    <div className={classes.staffWrapper}>
                                                                        {`Meeting booking for ${values.firstName} ${values.lastName} (${values.email})`}
                                                                    </div>
                                                                </Grid>
                                                            )}
                                                            {!!(values.firstName && values.lastName && !values.email) && (
                                                                <Grid item={true} xs={12} md={12}>
                                                                    <div className={classes.noEmailMeetingWrapper}>
                                                                        There is no email address saved against the enquiry. Email confirmations will
                                                                        not be sent without saving an email address against the enquiry record first.
                                                                    </div>
                                                                </Grid>
                                                            )}
                                                            <Grid item={true} xs={12} md={6}>
                                                                <Field
                                                                    name="staff"
                                                                    // tslint:disable-next-line: max-line-length
                                                                    validate={required}
                                                                    subscription={{ touched: true, error: true, value: true }}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <UserStaffAndTeamAutoCompleteSelector
                                                                                {...input}
                                                                                label="Staff"
                                                                                // tslint:disable-next-line: max-line-length
                                                                                onSelection={(selection: IAutoCompleteItem, name: string) => onChangeSelection(selection, input, form, name)}
                                                                                onBlur={input.onBlur}
                                                                                error={meta.error && meta.touched}
                                                                                disablePortal={false}
                                                                                className={classes.textField}
                                                                                helperText={
                                                                                    meta.error &&
                                                                                        meta.touched
                                                                                        ? 'Staff or Team is required'
                                                                                        : 'The person or team in the firm who the meeting is with'
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </Grid>
                                                            <Grid item={true} xs={12} md={6}>
                                                                {!!(values.staff && values.staff.entityType === EntityTypeEnum.STAFF) && (
                                                                    <Field
                                                                        name="isSendConfirmation"
                                                                        subscription={{ touched: true, error: true, value: true }}
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <FormControlLabel
                                                                                className={classes.formControlLabel}
                                                                                control={
                                                                                    <Checkbox
                                                                                        {...input}
                                                                                        checked={input.value}
                                                                                        onChange={input.onChange}
                                                                                        checkedIcon={<CheckBoxIcon color="primary" />}
                                                                                    />
                                                                                }
                                                                                label="Send Confirmation"
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                )}
                                                            </Grid>
                                                            <Grid item={true} xs={12} md={7}>
                                                                <Field
                                                                    name="officeLocation"
                                                                    validate={required}
                                                                    subscription={{ touched: true, error: true, value: true }}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <OfficeLocationSelector
                                                                                {...input}
                                                                                label="Location"
                                                                                required={true}
                                                                                value={input.value ? input.value : null}
                                                                                className={classes.textField}
                                                                                error={meta.error && meta.touched}
                                                                                // tslint:disable-next-line: max-line-length
                                                                                onSelection={(selection: IAutoCompleteItem, name: string) => onChangeSelection(selection, input, form, name)}
                                                                                // tslint:disable-next-line: max-line-length
                                                                                // onSelection={(selection: IAutoCompleteItem, name: string) =>
                                                                                //     input.onChange(selection)
                                                                                // }
                                                                                onBlur={input.onBlur}
                                                                                helperText={meta.error && meta.touched
                                                                                    ? 'Location is required'
                                                                                    : undefined
                                                                                }
                                                                                showAllOffice={true}
                                                                                PopperComponent={CustomPopper}
                                                                                disablePortal={false}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </Grid>
                                                            <Grid item={true} xs={12} md={5}>
                                                                <Field
                                                                    name="timezone"
                                                                    validate={values.officeLocation ? required : undefined}
                                                                    subscription={{ touched: true, error: true, value: true }}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <TimezoneSelector
                                                                            {...input}
                                                                            label="Invitee's Timezone"
                                                                            onSelection={(selection: IAutoCompleteItem, name: string) =>
                                                                                input.onChange(selection)
                                                                            }
                                                                            className={classes.textField}
                                                                            disablePortal={false}
                                                                            onBlur={input.onBlur}
                                                                            error={meta.error && meta.touched}
                                                                            helperText={meta.error && meta.touched
                                                                                ? 'Timezone is required'
                                                                                : undefined
                                                                            }
                                                                            required={values.officeLocation ? true : false}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </Grid>
                                                            <Grid item={true} xs={12} md={6}>
                                                                <Field
                                                                    name="startTime"
                                                                    // We should not have required for hidden fields.
                                                                    validate={required}
                                                                    subscription={{ touched: true, error: true, value: true }}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <KeyboardDateTimePicker
                                                                                {...input}
                                                                                className={classes.textField}
                                                                                // tslint:disable-next-line: max-line-length
                                                                                onChange={onDateTimePickerChange(input, form)}
                                                                                id="startTime"
                                                                                name="startTime"
                                                                                label="Invitee's Start *"
                                                                                format="DD/MM/YYYY hh:mm a"
                                                                                placeholder="dd/mm/yyyy hh:mm a"
                                                                                animateYearScrolling={true}
                                                                                autoOk={true}
                                                                                variant={'inline'}
                                                                                error={meta.error && meta.touched}
                                                                                // tslint:disable-next-line: max-line-length
                                                                                helperText={meta.error && meta.touched ? 'Start time is required' : ''}
                                                                                inputProps={{ autocomplete: 'off' }}
                                                                                InputLabelProps={{ shrink: true }}
                                                                                minDate={moment().toDate()}
                                                                                initialFocusedDate={new Date().setHours(12, 0, 0, 0)}
                                                                                // required={true}
                                                                            // tslint:disable-next-line: max-line-length
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </Grid>
                                                            <Grid item={true} xs={12} md={6}>
                                                                <div className={classes.startTimeWrapper}>
                                                                    <Field
                                                                        name="endTime"
                                                                        validate={required}
                                                                        subscription={{ touched: true, error: true, value: true }}
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <div className={classes.textFieldWrapper}>
                                                                                <KeyboardDateTimePicker
                                                                                    {...input}
                                                                                    className={classes.textField}
                                                                                    id="endTime"
                                                                                    name="endTime"
                                                                                    label="Invitee's End *"
                                                                                    format="DD/MM/YYYY hh:mm a"
                                                                                    placeholder="dd/mm/yyyy hh:mm a"
                                                                                    animateYearScrolling={true}
                                                                                    autoOk={true}
                                                                                    variant={'inline'}
                                                                                    error={meta.error && meta.touched}
                                                                                    // tslint:disable-next-line: max-line-length
                                                                                    helperText={meta.error && meta.touched ? 'End time is required' : ''}
                                                                                    inputProps={{ autocomplete: 'off' }}
                                                                                    InputLabelProps={{ shrink: true }}
                                                                                    // tslint:disable-next-line: max-line-length
                                                                                    minDate={moment(values.startTime).toDate()}
                                                                                    // required={true}
                                                                                // tslint:disable-next-line: max-line-length
                                                                                // initialFocusedDate={moment(values.startTime).add(1, 'hour').toDate()}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                    <Field
                                                                        name="isAllDay"
                                                                        subscription={{ touched: true, error: true, value: true }}
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <FormControlLabel
                                                                                className={classes.formControlLabel}
                                                                                control={
                                                                                    <Checkbox
                                                                                        {...input}
                                                                                        checked={input.value}
                                                                                        onChange={onChangeAllDay(input, form, values)}
                                                                                        checkedIcon={<CheckBoxIcon color="primary" />}
                                                                                    />
                                                                                }
                                                                                label="All day"
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </Grid>
                                                            <Grid item={true} xs={12} md={10}>
                                                                <Field
                                                                    name="meetingNotes"
                                                                    // validate={required}
                                                                    subscription={{ touched: true, error: true, value: true }}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <TextField
                                                                            {...input}
                                                                            label="Meeting Notes"
                                                                            type="text"
                                                                            multiline={true}
                                                                            // required={true}
                                                                            className={classes.textField}
                                                                            error={meta.error && meta.touched}
                                                                            autoComplete="abcd"
                                                                            helperText={
                                                                                meta.error &&
                                                                                    meta.touched
                                                                                    ? 'Meeting notes is required'
                                                                                    : ''
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </Grid>
                                                            <Grid item={true} xs={12} md={2}>
                                                                <Field
                                                                    name="feeApplicable"
                                                                    label="Fee Applicable"
                                                                    // helperText={'Debtors amount to write off'}
                                                                    subscription={{ touched: true, error: true, value: true }}
                                                                    prefix={state.currency}
                                                                    className={classes.textField}
                                                                    component={CurrencyInput}
                                                                // disabled={props.formAction === 'edit'}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            );
                                        } else if (values.followUpSystemAction === 'CreateEmail') {
                                            return (
                                                <>
                                                    <Divider className={classes.divider} />
                                                    <Grid item={true} xs={12}>
                                                        <Grid container={true} spacing={3}>
                                                            <Grid item={true} xs={12} md={6}>
                                                                <Field
                                                                    name="emailTemplate"
                                                                    validate={required}
                                                                    subscription={{ touched: true, error: true, value: true }}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <EmailTemplateSelector
                                                                                {...input}
                                                                                label="Email Template"
                                                                                // required={true}
                                                                                value={input.value ? input.value : null}
                                                                                className={classes.textField}
                                                                                error={meta.error && meta.touched}
                                                                                // tslint:disable-next-line: max-line-length
                                                                                onSelection={(selection: IAutoCompleteItem, name: string) =>
                                                                                    onEmailTemplateChange(form, selection)
                                                                                }
                                                                                onBlur={input.onBlur}
                                                                                helperText={meta.error && meta.touched
                                                                                    ? 'Email Template is required'
                                                                                    : undefined
                                                                                }
                                                                                PopperComponent={CustomPopper}
                                                                                areaOfLaw={values.areaOfLaw}
                                                                                emailType={EmailTemplateTypeEnum.EMAIL_RESPONSE}
                                                                                disablePortal={false}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </Grid>
                                                            <>
                                                                {values.followUpSystemAction === 'CreateEmail'
                                                                    && values.emailTemplate
                                                                    && (
                                                                        <SendEmailDialog
                                                                            enquiryGuid={values.guid}
                                                                            emailTemplateGuid={values.emailTemplate.value}
                                                                            emailTemplateName={values.emailTemplate.label}
                                                                            emailType={EmailTemplateTypeEnum.EMAIL_RESPONSE}
                                                                            followUpAction={values.followUpAction}
                                                                            followUpActionSystemName={values.followUpActionSystemName}
                                                                            followUpDate={values.followUpDate}
                                                                            fromEmailAddress={appUser ? appUser.userName : ''}
                                                                            toEmailAddress={values.email}
                                                                            onDialogClose={() => onSendEmailDialogClose(form)}
                                                                            isSendEmailDialogOpen={state.isSendEmailDialogOpen}
                                                                            enquiryDefaultConfig={props.enquiryDefaultConfig}
                                                                            canCreateMatter={enquiryActionData?.canCreateMatter}
                                                                        />
                                                                    )}
                                                            </>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            );
                                        } else {
                                            return (
                                                <>
                                                    <Grid item={true} xs={6}>
                                                        <div>
                                                            <Field
                                                                name="followUpDate"
                                                                // tslint:disable-next-line: max-line-length
                                                                validate={isTabActivity() && enquiryActionData?.canFollowUp === true
                                                                    ? values.followUpAction !== null
                                                                        ? requiredDateValidator
                                                                        : undefined
                                                                    : undefined}
                                                                subscription={{ touched: true, error: true, value: true }}
                                                            >
                                                                {({ input, meta }) => (
                                                                    <div>
                                                                        <KeyboardDateTimePicker
                                                                            {...input}
                                                                            className={classes.textField}
                                                                            id="followUpDate"
                                                                            name="followUpDate"
                                                                            label="Date"
                                                                            format="DD/MM/YYYY hh:mm a"
                                                                            placeholder="dd/mm/yyyy hh:mm a"
                                                                            animateYearScrolling={true}
                                                                            autoOk={true}
                                                                            variant={'inline'}
                                                                            error={meta.error && meta.touched}
                                                                            // tslint:disable-next-line: max-line-length
                                                                            helperText={
                                                                                meta.error && meta.touched
                                                                                    ? meta.error
                                                                                    : ''}
                                                                            inputProps={{ autocomplete: 'off' }}
                                                                            InputLabelProps={{ shrink: true }}
                                                                            // minDate={new Date()}
                                                                            initialFocusedDate={new Date().setHours(17, 0, 0, 0)}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Field>
                                                        </div>
                                                    </Grid>
                                                    <Grid item={true} xs={12}>
                                                        <Field
                                                            name="followUpNotes"
                                                            subscription={{ touched: true, error: true, value: true }}
                                                        >
                                                            {({ input, meta }) => (
                                                                <TextFieldWithMention
                                                                    {...input}
                                                                    className={classes.textField}
                                                                    label="Follow Up Notes"
                                                                    markup="@[__display__](user:__id__)"
                                                                    // tslint:disable-next-line: max-line-length
                                                                    onChange={(event: { target: { value: string } }, newValue: string, newPlainTextValue: string, mentions: MentionItem[]) => {
                                                                        input.onChange(newValue);
                                                                        if (mentions && mentions.length > 0) {
                                                                            // If we have mentions, change the Follow Up Action to - Internal Note
                                                                            // And mark Follow Up Due as not mandatory
                                                                            form.change('followUpAction', 'Internal Note');
                                                                            form.change('followUpActionSystemName', 'InternalNote');

                                                                            form.change('hasFollowUpNotesMentions', true);
                                                                            form.change('updateFollowUpDueForInternalNote', false);
                                                                        } else {
                                                                            form.change('hasFollowUpNotesMentions', false);
                                                                        }
                                                                    }}
                                                                    isLoadData={isTabActivity()}
                                                                    onlyUsedByEnquiries={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Grid>
                                                    <Grid item={true} xs={12}>
                                                        <Grid container={true} spacing={2}>
                                                            <Grid item={true} xs={12}>
                                                                <OutcomeRadio
                                                                    name="outcome"
                                                                    label="Outcome"
                                                                    hasNameGuid={values.nameGuid ? true : false}
                                                                    canCreateMatter={enquiryActionData?.canCreateMatter}
                                                                    // tslint:disable-next-line: max-line-length
                                                                    onRadioButtonChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                                                                        onOutcomeChange(form, values, event, checked)
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid item={true} xs={12}>
                                                                <Grid container={true} spacing={2}>
                                                                    {/* Without FormSpy, rules will not update in Realtime */}
                                                                    <Grid
                                                                        item={true}
                                                                        xs={4}
                                                                        className={values.outcome === 'More Follow Up' ? '' : classes.hideDate}
                                                                    >
                                                                        <Field
                                                                            name="outcomeDate"
                                                                            // validate={values.outcome === 'More Follow Up'
                                                                            validate={isTabActivity() && enquiryActionData?.canFollowUp === true
                                                                                ? values.hasFollowUpNotesMentions
                                                                                    // tslint:disable-next-line: max-line-length
                                                                                    ? undefined : values.followUpDate !== null && values.outcome === 'More Follow Up'
                                                                                        ? requiredDateValidator
                                                                                        : undefined
                                                                                : undefined
                                                                            }
                                                                            subscription={{ touched: true, error: true, value: true }}
                                                                        >
                                                                            {({ input, meta }) => (
                                                                                <div>
                                                                                    <KeyboardDateTimePicker
                                                                                        {...input}
                                                                                        className={classes.textField}
                                                                                        label="Follow Up Due"
                                                                                        format="DD/MM/YYYY hh:mm a"
                                                                                        placeholder="dd/mm/yyyy hh:mm a"
                                                                                        // tslint:disable-next-line: max-line-length
                                                                                        required={isTabActivity() && enquiryActionData?.canFollowUp === true
                                                                                            ? values.hasFollowUpNotesMentions
                                                                                                // tslint:disable-next-line: max-line-length
                                                                                                ? false : values.followUpDate !== null && values.outcome === 'More Follow Up'
                                                                                                    ? true
                                                                                                    : false
                                                                                            : false
                                                                                        }
                                                                                        // tslint:disable-next-line: no-any
                                                                                        onChange={onOutcomeDateChange(input, form)}
                                                                                        animateYearScrolling={true}
                                                                                        // tslint:disable-next-line: max-line-length
                                                                                        autoOk={true}
                                                                                        allowKeyboardControl={true}
                                                                                        variant={'inline'}
                                                                                        error={meta.error && meta.touched}
                                                                                        helperText={
                                                                                            meta.error && meta.touched
                                                                                                ? 'Follow Up Due is required'
                                                                                                : ''
                                                                                        }
                                                                                        inputProps={{ autocomplete: 'off' }}
                                                                                        InputLabelProps={{ shrink: true }}
                                                                                        // minDate={new Date()}
                                                                                        // tslint:disable-next-line: max-line-length
                                                                                        initialFocusedDate={getOutcomeDefaultTime()}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Field>
                                                                    </Grid>
                                                                    <Grid
                                                                        item={true}
                                                                        xs={4}
                                                                        alignItems="flex-end"
                                                                        className={values.outcome === 'More Follow Up' ? '' : classes.hideDate}
                                                                    >
                                                                        <FormSpy subscription={{ values: true, pristine: true }}>
                                                                            {/* tslint:disable-next-line: no-shadowed-variable */}
                                                                            {({ values }) => (
                                                                                <Field
                                                                                    name="followUpDueReminder"
                                                                                    subscription={{ touched: true, error: true, value: true }}
                                                                                >
                                                                                    {({ input, meta }) => (
                                                                                        <div className={classes.selector}>
                                                                                            <ReminderSelector
                                                                                                {...input}
                                                                                                label="Reminder"
                                                                                                className={classes.textField}
                                                                                                onSelection={(selection: IAutoCompleteItem) => {
                                                                                                    input.onChange(selection);
                                                                                                }}
                                                                                                error={meta.error && meta.touched}
                                                                                                helperText={
                                                                                                    meta.error &&
                                                                                                        meta.touched
                                                                                                        ? meta.error
                                                                                                        : ''
                                                                                                }
                                                                                                showIcon={true}
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                </Field>
                                                                            )}
                                                                        </FormSpy>
                                                                    </Grid>
                                                                    <Grid
                                                                        item={true}
                                                                        xs={4}
                                                                        className={values.outcome === 'More Follow Up' ? '' : classes.hideDate}
                                                                    >
                                                                        <Field
                                                                            name="outcomeNotes"
                                                                            subscription={{ touched: true, error: true, value: true }}
                                                                        >
                                                                            {({ input, meta }) => (
                                                                                <div>
                                                                                    <TextField
                                                                                        {...input}
                                                                                        label="Notes"
                                                                                        type="text"
                                                                                        // required={true}
                                                                                        className={classes.textField}
                                                                                        error={meta.error && meta.touched}
                                                                                        autoComplete="abcd"
                                                                                    // helperText={
                                                                                    //     meta.error &&
                                                                                    //     meta.touched
                                                                                    //         ? 'Notes is required'
                                                                                    //         : ''
                                                                                    // }
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Field>
                                                                    </Grid>
                                                                    {/* Without FormSpy, rules will not update in Realtime */}
                                                                    <Grid
                                                                        item={true}
                                                                        xs={6}
                                                                        className={values.outcome === 'Not Proceeding'
                                                                            ? `${classes.customFieldGrid} ${classes.displayFlex}`
                                                                            : classes.hideDate}
                                                                        alignItems="flex-end"
                                                                    >
                                                                        <Field
                                                                            name="reason"
                                                                            // tslint:disable-next-line: max-line-length
                                                                            validate={isTabActivity()
                                                                                ? form.getFieldState('outcome')?.value === 'Not Proceeding' ? required : undefined
                                                                                : undefined}
                                                                            subscription={{ touched: true, error: true, value: true }}
                                                                        >
                                                                            {({ input, meta }) => (
                                                                                <div style={{ flex: 1 }}>
                                                                                    <ReasonSelector
                                                                                        {...input}
                                                                                        label="Not Proceeding Reason"
                                                                                        name="reason"
                                                                                        className={classes.textField}
                                                                                        error={meta.error && meta.touched}
                                                                                        disablePortal={false}
                                                                                        // tslint:disable-next-line: max-line-length
                                                                                        onSelection={(selection: IAutoCompleteItem, name: string, reasonGuid: string) => {
                                                                                            // tslint:disable-next-line: max-line-length
                                                                                            onDropdownChange(selection, name, input, form, reasonGuid);
                                                                                        }}
                                                                                        // tslint:disable-next-line: max-line-length
                                                                                        required={isTabActivity()
                                                                                            ? form.getFieldState('outcome')?.value === 'Not Proceeding'
                                                                                                ? true : false
                                                                                            : false}
                                                                                        disabled={form.getFieldState('outcome')?.value !== 'Not Proceeding'
                                                                                            ? true : false}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Field>
                                                                    </Grid>
                                                                    {values.isShowNotProceedingReferrer && (
                                                                        <Grid item={true} xs={6} className={classes.customFieldGrid}>
                                                                            <div className={classes.toggleFieldWrapepr}>
                                                                                <div className="field">
                                                                                    {
                                                                                        (values.isNotProceedingReferrerSwitched) ? (
                                                                                            <Field
                                                                                                name="referredToFirmName"
                                                                                                // validate={required}
                                                                                                subscription={{ touched: true, error: true, value: true }}
                                                                                            >
                                                                                                {({ input, meta }) => (
                                                                                                    <TextField
                                                                                                        {...input}
                                                                                                        label="Referred To"
                                                                                                        type="text"
                                                                                                        // required={!isTabActivity()}
                                                                                                        className={classes.textField}
                                                                                                        error={
                                                                                                            meta.error &&
                                                                                                            meta.touched
                                                                                                        }
                                                                                                        autoComplete="abcd"
                                                                                                        helperText={
                                                                                                            meta.error &&
                                                                                                                meta.touched
                                                                                                                ? 'Referred To is required'
                                                                                                                : ''
                                                                                                        }
                                                                                                    />
                                                                                                )}
                                                                                            </Field>
                                                                                        ) :
                                                                                            (
                                                                                                <Field
                                                                                                    name="referredToFirmSelector"
                                                                                                    subscription={{ touched: true, error: true, value: true }}
                                                                                                >
                                                                                                    {({ input, meta }) => (
                                                                                                        <ReferredToSelector
                                                                                                            {...input}
                                                                                                            label="Referred To"
                                                                                                            value={values.referredToFirmSelector}
                                                                                                            error={meta.error && meta.touched}
                                                                                                            helperText={
                                                                                                                meta.error &&
                                                                                                                    meta.touched
                                                                                                                    ? 'Referred To is required'
                                                                                                                    : ''
                                                                                                            }
                                                                                                            onSelection={(selection: IndividualNameSummary, name: string) => {
                                                                                                                input.onChange(selection);
                                                                                                                onDropDownSelect(form, values, input, selection, name);
                                                                                                            }}
                                                                                                        />
                                                                                                    )}
                                                                                                </Field>
                                                                                            )
                                                                                    }
                                                                                </div>
                                                                                <div className="switcher">
                                                                                    <Tooltip
                                                                                        // placement="left-start"
                                                                                        title={
                                                                                            values.isNotProceedingReferrerSwitched ? (
                                                                                                <Typography color="inherit">
                                                                                                    Switch to select an existing client & contact
                                                                                                </Typography>
                                                                                            ) :
                                                                                                (
                                                                                                    <Typography color="inherit">Switch to enter the referred to name</Typography>
                                                                                                )
                                                                                        }
                                                                                    >
                                                                                        <IconButton
                                                                                            aria-label="delete"
                                                                                            // className={classes.margin}
                                                                                            onClick={() => {
                                                                                                // Another approach.
                                                                                                onSwitch(form, values, 'referredToFirm');
                                                                                            }}
                                                                                        >
                                                                                            <SyncAltIcon fontSize="small" />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                    )}
                                                                    <Grid
                                                                        item={true}
                                                                        xs={12}
                                                                        className={values.outcome === 'Not Proceeding' ? '' : classes.hideDate}
                                                                    >
                                                                        <Field
                                                                            name="notProceedingOutcomeNotes"
                                                                            subscription={{ touched: true, error: true, value: true }}
                                                                        >
                                                                            {({ input, meta }) => (
                                                                                <div>
                                                                                    <TextField
                                                                                        {...input}
                                                                                        label="Not Proceeding Notes"
                                                                                        type="text"
                                                                                        className={classes.textField}
                                                                                        error={meta.error && meta.touched}
                                                                                        autoComplete="abcd"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Field>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {/* <Divider className={classes.divider} /> */}
                                                    </Grid>
                                                </>
                                            );
                                        }
                                    }}
                                </FormSpy>

                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
            </>
        );
    };

    // tslint:disable-next-line: no-any
    const matterDetailsTab = (form: FormApi<any>, pristine: boolean) => {
        return (
            <div style={{ display: state.tab === EnquiryFormTab.matterDetails ? 'block' : 'none' }}>
                <Grid container={true} spacing={3}>
                    <Grid item={true} md={12} xs={12} sm={12}>
                        <FormSpy subscription={{ values: true }}>
                            {/* tslint:disable-next-line: no-shadowed-variable */}
                            {({ values }) => (
                                <div>
                                    {!enquiryDetail.matterFileNumber && enquiryActionData?.canChangeLinkedMatter === false && (
                                        <p className={classes.notes}>
                                            Matter synching is in progress. Please check again after a short while.
                                        </p>
                                    )}

                                    {/* Buttons should be encapsulated in FormSpy - While close, we are refering to values and form */}
                                    <div className={classes.matterSelectorWrapper}>
                                        <Field
                                            name="matterSelector"
                                            // tslint:disable-next-line: max-line-length
                                            subscription={{ touched: true, error: true, value: true }}
                                        >
                                            {({ input, meta }) => (
                                                <MatterAutoCompleteSelector
                                                    {...input}
                                                    label="Matter"
                                                    // tslint:disable-next-line: no-any
                                                    onSelection={(selection: IAutoCompleteItem | any, name: string) => {
                                                        input.onChange(selection);
                                                        if (selection) {
                                                            fetchMatterDetails(selection.id);
                                                        }
                                                    }}
                                                    disabled={!enquiryActionData?.canChangeLinkedMatter}
                                                    value={input.value ? input.value : null}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                            meta.touched
                                                            ? meta.error
                                                            : ''
                                                    }
                                                    className={classes.textField}
                                                />
                                            )}
                                        </Field>
                                        <IconButton
                                            size="small"
                                            onClick={onShowMatterDetails(values)}
                                            disabled={!values.matterSelector}
                                        >
                                            <OpenInNewIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            )}
                        </FormSpy>
                    </Grid>
                    <Grid item={true} md={12} xs={12} sm={12}>
                        <CustomTable
                            header={state.matterClientName ? ['Client', state.matterClientName] : undefined}
                            content={state.generalInfoData}
                            options={['client', 'company']}
                        />
                    </Grid>
                    <Grid item={true} md={12} xs={12} sm={12}>
                        <div className={classes.cardWrapper}>
                            <Grid container={true} spacing={1}>
                                <Grid item={true} md={6} xs={12} sm={12}>
                                    <Grid container={true} spacing={1}>
                                        <Grid item={true} md={12} xs={12} sm={12}>
                                            <Card className={classes.card}>
                                                <div className="label">
                                                    {MatterTerminology.EstimatedCosts}
                                                </div>
                                                <div
                                                    className="value"
                                                    style={{ color: estimatedFeesColour(state.financials) }}
                                                >
                                                    {`${state.currency}${state.financials ? formatNumber(state.financials.estimatedCosts) : 0}`}
                                                </div>
                                            </Card>
                                        </Grid>
                                        <Grid item={true} md={12} xs={12} sm={12}>
                                            <Card className={classes.card}>
                                                <div className="label">
                                                    {MatterTerminology.Billed + ' ' + MatterTerminology.BilledTimeAndFees}
                                                </div>
                                                <div className="value">
                                                    {`${state.currency}${state.financials ? formatNumber(state.financials.billedTimeAndFees) : 0}`}
                                                </div>
                                            </Card>
                                        </Grid>
                                        <Grid item={true} md={12} xs={12} sm={12}>
                                            <Card className={classes.card}>
                                                <div className="label">{MatterTerminology.TotalOwing}</div>
                                                <div className="value">
                                                    {`${state.currency}${state.financials ? formatNumber(state.financials.balanceDue) : 0}`}
                                                </div>
                                            </Card>
                                        </Grid>
                                        <Grid item={true} md={12} xs={12} sm={12}>
                                            <Card className={classes.card}>
                                                <div className="label">
                                                    {
                                                        // tslint:disable-next-line:max-line-length
                                                        MatterTerminology.Unbilled + ' ' + MatterTerminology.UnbilledTimeAndFees
                                                    }
                                                </div>
                                                <div className="value">
                                                    {`${state.currency}${state.financials ? formatNumber(state.financials.unbilledTimeAndFees) : 0}`}
                                                </div>
                                            </Card>
                                        </Grid>
                                        <Grid item={true} md={12} xs={12} sm={12}>
                                            <Card className={classes.card}>
                                                <div className="label">
                                                    {MatterTerminology.BillingMethod}
                                                </div>
                                                <div className="value-string">
                                                    {state.financials ? state.financials.billingMethod : ''}
                                                </div>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item={true} md={6} xs={12} sm={12}>
                                    <Grid container={true} spacing={1}>
                                        <Grid item={true} md={12} xs={12} sm={12}>
                                            <Card className={classes.card}>
                                                <div className="label">
                                                    {MatterTerminology.CostAgreementReceived}
                                                </div>
                                                <div
                                                    className="value-string"
                                                // style={{ color: costAgreementReceivedColor}}
                                                >

                                                    {
                                                        // tslint:disable-next-line:max-line-length
                                                        state.financials && state.financials.costAgreementReceived ? moment(state.financials.costAgreementReceived).format('d MMM YYYY') : null
                                                    }
                                                </div>
                                            </Card>
                                        </Grid>
                                        <Grid item={true} md={12} xs={12} sm={12}>
                                            <Card className={classes.card}>
                                                <div className="label">
                                                    {MatterTerminology.Billed + ' ' + MatterTerminology.Disbursements}
                                                </div>
                                                <div className="value">
                                                    {`${state.currency}${state.financials ? formatNumber(state.financials.billedDisbursements) : 0}`}
                                                </div>
                                            </Card>
                                        </Grid>
                                        <Grid item={true} md={12} xs={12} sm={12}>
                                            <Card className={classes.card}>
                                                <div className="label">
                                                    {MatterTerminology.TrustBalance}
                                                </div>
                                                <div className="value">
                                                    {`${state.currency}${state.financials ? formatNumber(state.financials.trustGeneralBalance) : 0}`}
                                                </div>
                                            </Card>
                                        </Grid>
                                        <Grid item={true} md={12} xs={12} sm={12}>
                                            <Card className={classes.card}>
                                                <div className="label">
                                                    {MatterTerminology.Unbilled + ' ' + MatterTerminology.Disbursements}
                                                </div>
                                                <div className="value">
                                                    {`${state.currency}${state.financials ? formatNumber(state.financials.unbilledDisbursements) : 0}`}
                                                </div>
                                            </Card>
                                        </Grid>
                                        <Grid item={true} md={12} xs={12} sm={12}>
                                            <Card className={classes.card}>
                                                <div className="label">{MatterTerminology.TrustControlledMoneyBalance}</div>
                                                <div className="value">
                                                    {`${state.currency}${state.financials ? formatNumber(state.financials.trustControlledMoneyBalance) : 0}`}
                                                </div>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    };

    return (
        <>
            <React.Fragment>
                <DialogBox
                    title="Enquiries"
                    // tslint:disable-next-line:max-line-length
                    content={`Are you sure you want to close the form?`}
                    show={state.showDiscard ? state.showDiscard : false}
                    isAgree={onDiscardChanges}
                    disAgreeLabel={'No'}
                    agreeLabel={'Yes'}
                />

                {state.isCreateClientOpen && (
                    <CreateClientDialog
                        anchor="right"
                        guid={props.guid}
                        homeCountry={state.homeCountry}
                        sourceSystem={state.sourceSystem}
                        onDialogClose={onCreateClientDialog(false)}
                        title={enquiryDetail.title}
                        firstName={enquiryDetail.firstName}
                        lastName={enquiryDetail.lastName}
                        organisation={enquiryDetail.organisation}
                        email={enquiryDetail.email}
                        mobile={enquiryDetail.mobile}
                        phone={enquiryDetail.phone}
                        sourceNotes={enquiryDetail.sourceNotes}
                        source={enquiryDetail.source}
                        enquiryActions={enquiryActionData}
                        enquiryDetail={enquiryDetail}
                        poBoxType={enquiryDetail.pOBoxType ? enquiryDetail.pOBoxType : props.pOBoxType}
                        timezone={state.timezone}
                    />
                )}
                {state.isMarkClientMatterCreatedOpen && (
                    <MarkClientAndMatterCreatedDialog
                        guid={props.guid}
                        onDialogClose={onMarkClientMatterCreateDialog(false)}
                        canCreateMatter={enquiryActionData?.canCreateMatter}
                    />
                )}
                
                <Drawer
                    anchor={'right'}
                    open={props.isEnquiryFormOpen}
                    onClose={onClose}
                >
                    <div
                        className={classes.root}
                        style={{ maxWidth: state.isExpandDrawer ? '100%' : '700px' }}
                    >
                        {/* <LightTooltip title={state.isExpandDrawer ? 'Collapse' : 'Expand'}>
                            <IconButton
                                className={classes.expandDrawer}
                                size="small"
                                onClick={onExpandDrawer}
                            >
                                {state.isExpandDrawer ? <FullscreenIcon fontSize="inherit" /> : <FullscreenExitIcon fontSize="inherit" /> }

                            </IconButton>
                        </LightTooltip> */}
                        {
                            isLoading || partyAddressesQuery.loading
                                ? displayLoader()
                                : (
                                    <Form
                                        // async and await use for fixing error on submit and reset the field
                                        onSubmit={async (values, form: FormApi<Enquiry>, callback) => await onSubmit(form, values, callback)}
                                        initialValues={props.guid ? getEnquiryDetails(true) : initialValues}
                                        initialValuesEqual={() => true}
                                        keepDirtyOnReinitialize={true}
                                        subscription={{ submitting: true, pristine: true }}
                                        // debug={(values) => {
                                        //     // tslint:disable-next-line: no-console
                                        //     console.log('Debug Values', values);
                                        // }}
                                        validate={(values) => {
                                            let errors = {
                                                email: '',
                                                mobile: '',
                                                phone: ''
                                            };

                                            if ((!values.email)
                                                && (!values.mobile)
                                                && (!values.phone)) {
                                                errors.email = 'One of Email/Telephone/Mobile are required';
                                                errors.mobile = 'One of Email/Telephone/Mobile are required';
                                                errors.phone = 'One of Email/Telephone/Mobile are required';
                                            } else if (values
                                                && (values.email && values.email.length === 0)
                                                && (values.mobile && values.mobile.length === 0)
                                                && (values.phone && values.phone.length === 0)) {
                                                errors.email = 'One of Email/Telephone/Mobile are required';
                                                errors.mobile = 'One of Email/Telephone/Mobile are required';
                                                errors.phone = 'One of Email/Telephone/Mobile are required';
                                            } else if (values.email !== undefined && values.email !== null && values.email.length > 0) {
                                                errors.email = EmailValidateWithRequiredNew(values.email);
                                            }

                                            if (errors.email === '' && errors.mobile === '' && errors.phone === '') {
                                                // tslint:disable-next-line: no-console
                                                // console.log('Errors', 'None');
                                                return undefined;
                                            }

                                            // tslint:disable-next-line: no-console
                                            // console.log('Errors', errors);
                                            return errors;
                                        }}
                                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                                            <form onSubmit={handleSubmit} id="enquiryForm" autoComplete="off">
                                                {/* <h3>{isCreateForm ? 'Create Enquiry' : 'Edit Enquiry'}</h3> */}
                                                {displayLoader()}

                                                <FormSpy
                                                    subscription={{ pristine: true, values: true }}
                                                    // tslint:disable-next-line: no-shadowed-variable
                                                    onChange={props => {
                                                        onFormValueChanged(form, props);
                                                    }}
                                                />

                                                <FormSpy subscription={{ values: true }}>
                                                    {/* tslint:disable-next-line: no-shadowed-variable */}
                                                    {({ values }) => (
                                                        <Grid container={true} spacing={1}>
                                                            {values.isBookMeetingEmailDialogOpen && (
                                                                <BookMeetingEmailDialog
                                                                    enquiryGuid={values.guid}
                                                                    followUpAction={values.followUpAction}
                                                                    followUpActionSystemName={values.followUpActionSystemName}
                                                                    isSendConfirmation={values.isSendConfirmation}
                                                                    fromAddress={appUser ? appUser.userName : null}
                                                                    toAddresses={values.email}
                                                                    onClose={() => onToggleBookMeetingEmailDialog(form, false)}
                                                                    open={values.isBookMeetingEmailDialogOpen}
                                                                    staff={values.staff}
                                                                    officeLocation={values.officeLocation ? values.officeLocation.label : null}
                                                                    timezone={values.timezone ? values.timezone.value : null}
                                                                    startTime={values.startTime}
                                                                    endTime={values.endTime}
                                                                    isAllDay={values.isAllDay}
                                                                    meetingNotes={values.meetingNotes}
                                                                    feeApplicable={values.feeApplicable}
                                                                />
                                                            )}
                                                            <Grid item={true} xs={7}>
                                                                <div className={`${classes.headerDetails} ${classes.headerLeft}`}>
                                                                    {!!(values.firstName && values.lastName) && (
                                                                        <h4 className="user">
                                                                            {`${values.firstName} ${values.lastName}`}
                                                                        </h4>
                                                                    )}
                                                                    {!!values.organisation && (
                                                                        <p className="company">
                                                                            {values.organisation}
                                                                        </p>
                                                                    )}
                                                                    {!!values.email && (
                                                                        <p className={`${classes.labelWithIcon} email`}>
                                                                            <MailOutlineIcon fontSize="small" />
                                                                            <a href={`mailto:${values.email}`}>
                                                                                {values.email}
                                                                            </a>
                                                                        </p>
                                                                    )}
                                                                    <div className={classes.contactsContainer}>
                                                                        {!!values.phone && (
                                                                            <p className={`${classes.labelWithIcon} phone`}>
                                                                                <PhoneOutlinedIcon fontSize="small" />
                                                                                <a href={`tel:${values.phone}`}>
                                                                                    {values.phone}
                                                                                </a>
                                                                            </p>
                                                                        )}
                                                                        {!!values.mobile && (
                                                                            <p className={`${classes.labelWithIcon} phone`}>
                                                                                <PhoneIphoneIcon fontSize="small" />
                                                                                <a href={`tel:${values.mobile}`}>
                                                                                    {values.mobile}
                                                                                </a>
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            {!isCreateForm && (
                                                                <Grid item={true} xs={5}>
                                                                    <div className={`${classes.headerDetails} ${classes.headerRight}`}>
                                                                        {!!enquiryDetail.createdDate && (
                                                                            <LightTooltip
                                                                                arrow={true}
                                                                                title={formatDate(enquiryDetail.createdDate, 'DD MMM YYYY hh:mm a')}
                                                                                placement="left-start"
                                                                            >
                                                                                <p className="common">{`Created ${showDateEndOfFromNow(enquiryDetail.createdDate)}`}</p>
                                                                            </LightTooltip>
                                                                        )}
                                                                        {!!enquiryDetail.dateAssigned && (
                                                                            <LightTooltip
                                                                                arrow={true}
                                                                                title={formatDate(enquiryDetail.dateAssigned, 'DD MMM YYYY hh:mm a')}
                                                                                placement="left-start"
                                                                            >
                                                                                <p className="common">{`Assigned ${showDateEndOfFromNow(enquiryDetail.dateAssigned)}`}</p>
                                                                            </LightTooltip>
                                                                        )}
                                                                        {
                                                                            // tslint:disable-next-line: max-line-length
                                                                            !!(enquiryDetail.followUpDueDate && enquiryActionData && !enquiryActionData.canReOpen) && (
                                                                                <LightTooltip
                                                                                    arrow={true}
                                                                                    title={formatDate(enquiryDetail.followUpDueDate, 'DD MMM YYYY hh:mm a')}
                                                                                    placement="left-start"
                                                                                >
                                                                                    <p
                                                                                        style={{ color: isDateDue(enquiryDetail.followUpDueDate) ? 'red' : '#000' }}
                                                                                        className="common"
                                                                                    >
                                                                                        {`Follow Up due ${showDateEndOfFromNow(enquiryDetail.followUpDueDate)}`}
                                                                                    </p>
                                                                                </LightTooltip>
                                                                            )}
                                                                    </div>
                                                                </Grid>
                                                            )}
                                                            <Grid item={true} xs={12}>
                                                                {!!values.internalNotes && getInternalNotes(values.internalNotes, 110)}
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </FormSpy>

                                                <FormSpy subscription={{ values: true }}>
                                                    {/* tslint:disable-next-line: no-shadowed-variable */}
                                                    {({ values }) => (
                                                        // Buttons should be encapsulated in FormSpy - While close, we are refering to values and form
                                                        actionButton(form, submitting, pristine, values, true, true, true, true, false)
                                                    )}
                                                </FormSpy>

                                                <FormSpy subscription={{ values: true }}>
                                                    {/* tslint:disable-next-line: no-shadowed-variable */}
                                                    {({ values }) => (
                                                        values.isContactDetailsChanged && (
                                                            <div>
                                                                <Alert severity="warning">
                                                                    Please uncheck if you don't want to update the record in Clients & Contacts database
                                                                    <br />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={values.isUpdateContacts}
                                                                                // tslint:disable-next-line: max-line-length
                                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                                                                    // Another approach.
                                                                                    onUpdateContact(form, values, checked);
                                                                                }}
                                                                                name="checkedB"
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label="Update Clients & Contacts record"
                                                                    />
                                                                </Alert>
                                                            </div>
                                                        )
                                                    )}
                                                </FormSpy>
                                                <FormSpy subscription={{ values: true }}>
                                                    {/* tslint:disable-next-line: no-shadowed-variable */}
                                                    {({ values }) => (
                                                        // Buttons should be encapsulated in FormSpy - While close, we are refering to values and form
                                                        <Tabs
                                                            value={state.tab}
                                                            onChange={handleTabChange}
                                                            indicatorColor="primary"
                                                            textColor="primary"
                                                            className={classes.tabs}
                                                            centered={true}

                                                        >
                                                            <Tab label="Details" value={EnquiryFormTab.details} />
                                                            <Tab label="Activity" value={EnquiryFormTab.activity} />
                                                            {!!(enquiryActionData && enquiryActionData.canLinkMatter) && (
                                                                <Tab label="Matter" value={EnquiryFormTab.matterDetails} />
                                                            )}
                                                        </Tabs>
                                                    )}
                                                </FormSpy>
                                                {/* )} */}
                                                {/* details tab */}
                                                {detailsTab(form, pristine, values)}
                                                {/* activity tab */}
                                                {activityTab(form, pristine, values)}
                                                {matterDetailsTab(form, pristine)}
                                                <FormSpy subscription={{ values: true, pristine: true }}>
                                                    {/* tslint:disable-next-line: no-shadowed-variable */}
                                                    {({ values }) => (
                                                        actionButton(form, submitting, pristine, values, false, false, false, false, true)
                                                    )}
                                                </FormSpy>

                                                {/* {printJson(values)}  */}
                                                {/* {printJson(enquiryDetail)} */}
                                            </form>
                                        )}
                                    />
                                )
                        }
                    </div>
                </Drawer>
            </React.Fragment>
        </>
    );
};

export const addBusinessDays = (originalDate: Date, numDaysToAdd: number) => {
    const Sunday = 0;
    const Saturday = 6;
    let daysRemaining = numDaysToAdd;

    const newDate = moment(new Date(originalDate.valueOf()));

    while (daysRemaining > 0) {
        newDate.add(1, 'days');
        if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
            daysRemaining--;
        }
    }

    return newDate;
};

// tslint:disable-next-line: no-any
// export function printJson(values: any) {
//     if (values) {
//         return (
//             <>
//                 <pre>
//                     {JSON.stringify(values, undefined, 2)}
//                 </pre>
//             </>
//         );
//     } else {
//         return (
//             <>
//                 <FormSpy subscription={{ values: true }}>
//                     {/* tslint:disable-next-line: no-shadowed-variable */}
//                     {({ values }) => (
//                         <pre>
//                             {JSON.stringify(values, undefined, 2)}
//                             {/* <RenderCount /> */}
//                         </pre>
//                     )}
//                 </FormSpy>
//             </>
//         );
//     }
// }
